import { state, style, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigateAction } from '@fwk/navigation';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { mActionFactory } from '../../actions/action-factory-builder';
import { MSelector } from '../../selectors/selector';
import { MFichaHtmlComponent } from '../ficha-html.component';
import { MListaHtmlComponent } from '../lista-html.component';
import { ParametrosModo } from './api';

@Component({
    selector: 'f-parametros',
    template: `
        <f-button-flotante class="boton-flotante-eraser" icono="fa fa-eraser" top="106px" right="0px"
            (click)="resetForm()"></f-button-flotante>
        <f-button-flotante class="boton-flotante-filter"  icono="fa fa-filter" top="159px" right="0px"
            (click)="alternarEstado()"></f-button-flotante>
        <div [@abrirCerrar]="parametrosModo$ | async" class="f-parametros" >
            <div class="f-parametros-content" fGrid [cols]="cols" [rows]="rows">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    animations: [
        trigger('abrirCerrar', [
            state('cerrado', style({
                display: 'none'
            })),
            // los valores de la animacion son respecto al css de su clase
            state('abierto', style({
                display: 'grid',
                maxHeight: '151px'
            })),
            state('maximizado', style({
                display: 'grid',
                maxHeight: '100%'
            }))
        ])
    ]
})
// tslint:disable: no-output-on-prefix
export class ParametrosComponent implements AfterViewInit {

    @Input() set parametrosModo(parametrosModo: ParametrosModo) {
        this.parametrosModo$ = of(parametrosModo);
    }

    @Output() onParametros = new EventEmitter<ParametrosModo>();

    parametrosModo$!: Observable<ParametrosModo>;

    @Input() cols = 8;

    @Input() rows = 1;

    get html() {
        return this.htmlLista || this.htmlFicha;
    }

    constructor(
        private store: Store,
        private router: Router,
        private formDirective: FormGroupDirective,
        @Optional() private htmlLista: MListaHtmlComponent,
        @Optional() private htmlFicha: MFichaHtmlComponent) { }

    alternarEstado() {
        this.store.dispatch(mActionFactory(this.html.mNucleoService.keyStore).alternarModoParametrosAccion());
    }

    ngAfterViewInit() {
        this.parametrosModo$ = this.store.select(MSelector.selectParametrosModo(this.html.mNucleoService.keyStore));
    }

    resetForm() {
        this.formDirective.reset();
        this.store.dispatch(mActionFactory(this.html.mNucleoService.keyStore).resetFormAccion({
            status: 'VALID'
        }));
        const rutaActual = this.router.url.split('?')[0];
        this.store.dispatch(new NavigateAction({ route: [rutaActual] }));
    }
}
