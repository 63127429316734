import { Injectable } from '@angular/core';
import { ParametrosModo } from './api';


@Injectable()
export class ParametrosService {

    alternarModo(modo: ParametrosModo) {
        let nuevoEstado: ParametrosModo;

        switch (modo) {

            case 'cerrado':
                nuevoEstado = 'abierto';
                break;

            case 'abierto':
                nuevoEstado = 'maximizado';
                break;

            case 'maximizado':
            default:
                nuevoEstado = 'cerrado';
                break;
        }

        return nuevoEstado;
    }
}
