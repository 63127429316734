import { Component, Input } from '@angular/core';

@Component({
    selector: 'f-titulo',
    template: `
        <div class="f-titulo">

            <f-titulo-form *ngIf="esForm && !prefijoDetras && !sinPrefijo" [textoPrefijoTitulo]="textoPrefijoTitulo"
                [separador]="separador"
                [traducirPrefijoTitulo]="traducirPrefijoTitulo">
            </f-titulo-form>

            <ng-container *ngIf="textoTitulo">
                {{ (traducirTitulo  ? (textoTitulo | translate) : textoTitulo )}}
            </ng-container>

            <f-titulo-form *ngIf="esForm && prefijoDetras && !sinPrefijo" [textoPrefijoTitulo]="textoPrefijoTitulo"
                [separador]="separador"
                [traducirPrefijoTitulo]="traducirPrefijoTitulo">
            </f-titulo-form>

            <span class="f-form-modificado" *ngIf="esForm">
                <i *ngIf="iconoRegistroEnUso && registroEnUso" class="{{iconoRegistroEnUso}}"></i>
                <i *ngIf="iconoFormModificado && formModificado" class="{{iconoFormModificado}}"></i>
            </span>

            <!-- <div class="f-titulo-cargando" *ngIf="cargando">
                {{cargando}}
            </div> -->
        </div>
    `
})
export class TituloComponent {

    @Input() textoTitulo?: string;

    @Input() traducirTitulo?: boolean;

    @Input() textoPrefijoTitulo?: string;

    @Input() traducirPrefijoTitulo?: boolean;

    @Input() esForm?: boolean;

    @Input() iconoFormModificado?: string;

    @Input() iconoRegistroEnUso?: string;

    @Input() registroEnUso?: boolean;

    @Input() formModificado?: boolean;

    @Input() separador?: string;

    @Input() prefijoDetras?: boolean;

    @Input() sinPrefijo?: boolean;

    // @Input() cargando?: string = '.';
    // @Input() cargando?: string = 'Cargando...';
}

@Component({
    selector: 'f-titulo-form',
    template: `
        <ng-container *ngIf="textoPrefijoTitulo">
            &nbsp;{{separador}}&nbsp;{{ traducirPrefijoTitulo ? (textoPrefijoTitulo | translate) : textoPrefijoTitulo }}&nbsp;
        </ng-container>
`
})
export class TituloFormComponent {
    @Input() textoPrefijoTitulo?: string;
    @Input() traducirPrefijoTitulo?: boolean;
    @Input() separador?: string;
}
