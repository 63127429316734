import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ButtonDirective, OnHostListenerClickEvent } from '@fwk/components/button';
import { CargarListaEventoMo, TablaComponent } from '@fwk/components/tabla';
import { TranslateService } from '@fwk/translate';
import { Store } from '@ngxs/store';
import { mActionFactory } from '../../actions/action-factory-builder';
import { MListaHtmlComponent } from '../lista-html.component';

@Directive({
    selector: '[fButtonBuscar]'
})
export class ButtonBuscarDirective extends ButtonDirective implements OnHostListenerClickEvent, AfterContentInit, OnDestroy {

    @Input() tablaComponentTemplate?: TablaComponent;

    constructor(
        private store: Store,
        private mHtmlComponent: MListaHtmlComponent,
        protected elementRef: ElementRef<any>,
        protected multilenguajeService: TranslateService,
        protected renderer: Renderer2) {
        super(elementRef, renderer, multilenguajeService);
        this.icon = 'pi pi-search-plus';
        this.label = 'buscar';
        this.traducirLabel = true;
    }

    ngOnInit(): void {
        if (!this.mHtmlComponent.mNucleoService.isGetPermissions()) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        }
    }

    fwkClick() {
        this.buscar();
    }

    private buscar(event?: CargarListaEventoMo): void {
        this.tablaComponentTemplate.entidadesSeleccionadas = [];
        this.tablaComponentTemplate.orden = this.mHtmlComponent.mNucleoService.estadoInicial.orden;
        this.tablaComponentTemplate.paginacion = this.mHtmlComponent.mNucleoService.estadoInicial.paginacion;
        this.tablaComponentTemplate.changeDetectorRef.detectChanges();

        if (!event) {
            const lista = this.getListaParams(event);
            lista.orden = this.mHtmlComponent.mNucleoService.estadoInicial.orden;
            this.store.dispatch(mActionFactory(this.mHtmlComponent.mNucleoService.keyStore).buscarAccion({ lista }));
        }
    }

    private getListaParams(evento: any) {
        const lista = { orden: null, desplazamiento: 0 };
        if (evento) {
            lista.orden = evento.orden;
            lista.desplazamiento = evento.paginacion.desplazamiento;
        } else if (this.mHtmlComponent.eventoTabla && this.mHtmlComponent.eventoTabla.orden) {
            lista.orden = this.mHtmlComponent.eventoTabla.orden;
        } else {
            lista.orden = this.tablaComponentTemplate.orden;
        }
        return lista;
    }
}
