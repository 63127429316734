import { MAcciones } from '@fwk/aplicacion';
import { MEntityServiceDefault, QP } from '@fwk/common';
import { LlenarDatosAccion } from '@fwk/data';
import { ParamOpt, __PARAMETROS__ } from '@fwk/forms';
import { NavigateAction } from '@fwk/navigation';
import { StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { MostrarProcesandoGlobalAccion, OcultarProcesandoGlobalAccion } from '../subestados/procesando-global.state';
import { inicialFormState, MantenimientoListaStoreModel } from './api';
import { MState } from './m-state';

export class MListaState<T extends MantenimientoListaStoreModel> extends MState {

    /**
     * Iniciar lista
     */
    iniciar(context: StateContext<T>, { payload }: MAcciones.iniciar.Action) {
        this.resetBuilder();
        this.initState(payload);
        this.nS.urlParams = payload.urlParams;

        const state = context.getState();

        const inicialEstadoQP: any = {};
        const decode: { l: any, q: any, v: any } = { l: null, q: null, v: null };

        let buscar = false;
        let entidadBusqueda = false;

        // miramos el payload, este payload se carga con los parametros de la ruta
        if (payload && payload.urlQueryParams && Object.keys(payload.urlQueryParams).length) {

            // lista orden
            if (payload.urlQueryParams.hasOwnProperty('l')) {
                decode.l = QP.fromQueryParamsUrlB64(payload.urlQueryParams.l);
                Object.assign(inicialEstadoQP, { orden: decode.l.orden });
                buscar = true;
            }

            // entidad busqueda
            if (payload.urlQueryParams.hasOwnProperty('q')) {
                decode.q = QP.fromQueryParamsUrlB64(payload.urlQueryParams.q);
                const model = { ...new this.entityParse(), ...decode.q };
                Object.assign(inicialEstadoQP, { forms: { ...state.forms, form: { ...inicialFormState(), model } } });
                entidadBusqueda = true;
            }

            // vista config
            if (payload.urlQueryParams.hasOwnProperty('v')) {
                decode.v = QP.fromQueryParamsUrlB64(payload.urlQueryParams.v);
                if (decode.v.titulo) {
                    Object.assign(inicialEstadoQP, { titulo: decode.v.titulo });
                }
                if (decode.v.parametrosModo) {
                    Object.assign(inicialEstadoQP, { parametrosModo: decode.v.parametrosModo });
                }
            }

        }

        // llenar datos devemos usar el servicio para poder mapear
        return context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: decode }))
            .pipe(
                switchMap(_globalstate => {
                    if (buscar || entidadBusqueda) {

                        const buscar$ = this.nS.getServicio<MEntityServiceDefault>().buscar({ l: payload.urlQueryParams.l, q: payload.urlQueryParams.q })
                            .pipe(
                                tap(({ respuesta, numeroRegistros }) => {
                                    context.patchState({
                                        entidades: respuesta.map(m => this.fepS.backToFrontEntity(this.entityParse, m)),
                                        entidadesSeleccionadas: [],
                                        permisos: this.nS.permission,
                                        busqueda: 'busquedaRealizada',
                                        paginacion: {
                                            desplazamiento: decode.l && decode.l.desplazamiento
                                                ? decode.l.desplazamiento
                                                : 0,
                                            numeroRegistros: decode.l && decode.l.numeroRegistros
                                                ? decode.l.numeroRegistros
                                                : respuesta.length,
                                            numeroRegistrosTotal: numeroRegistros
                                        },
                                        indicadores: {
                                            todasSeleccionadas: false,
                                            unaSeleccionada: false,
                                            algunaSeleccionada: false
                                        },
                                        // sobrescribimos el con el nuevo estado
                                        ...inicialEstadoQP
                                    } as T);
                                }),
                                finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion())));

                        if (!entidadBusqueda) {
                            context.dispatch(new MostrarProcesandoGlobalAccion());
                            return buscar$;

                        } else {

                            const model = inicialEstadoQP.forms.form.model;
                            const copiaModel = { ...model };
                            for (const key of Object.keys(model)) {
                                const parametrosMeta: ParamOpt = Reflect.getMetadata(__PARAMETROS__, new this.entityParse(), key);
                                if (parametrosMeta) {
                                    copiaModel[key] = model[parametrosMeta.mapToPropiedad];
                                    copiaModel[parametrosMeta.mapToPropiedad] = null;
                                }
                            }

                            const modelEntity = this.fepS.backToFrontEntity(this.entityParse, copiaModel);

                            return this.dps.mapDatosEnEntidad(modelEntity)
                                .pipe(
                                    tap(nuevoEstadoDatos => {
                                        inicialEstadoQP.forms.form.model = nuevoEstadoDatos;
                                        context.patchState({ ...inicialEstadoQP });
                                    }),
                                    switchMap(() => {
                                        context.dispatch(new MostrarProcesandoGlobalAccion());
                                        return buscar$;
                                    }));
                        }

                    } else {
                        // IMPORTANTE!! este setState esta para cuando clickamos al mismo punto de menu y queremos que se borren los datos
                        // sino se quedarian con en el estado anterior puesto que no se destruye el modulo
                        // no hay que buscar, si no hay que buscar no puede haver iniciaFormEstado
                        context.setState({ ...this.estadoInicial as T });
                    }

                    return of('');
                }));
    }


    buscar(context: StateContext<T>, { payload }: MAcciones.buscar.Action) {
        const state = context.getState();

        payload = this.mountListPayload(state, payload);
        context.patchState({
            ...state,
            entidadesSeleccionadas: [],
            orden: payload.lista.orden,
            busqueda: 'buscando',
            indicadores: {
                todasSeleccionadas: false,
                unaSeleccionada: false,
                algunaSeleccionada: false
            }
        });

        const pay = this.getParameters(state, payload.lista);

        const isValid = state.forms.form.status === 'VALID';
        if (isValid) {
            // cambiar esto por rescrivir url
            // this.nS.navigationService.remplazarRuta(`${this.currentUrl.split('?')[0]}?${pay}`);
            context.dispatch(new NavigateAction({
                route: [`${this.currentUrl.split('?')[0]}`],
                extras: { queryParams: pay }
            }));
        } else {
            context.dispatch(new OcultarProcesandoGlobalAccion());
            context.dispatch(this.noS.errorAlBuscarParametros());
            return throwError(state.forms.form.errors);
        }
    }

    private mountListPayload(state: any, payload: MAcciones.buscar.Payload) {
        if (!payload) {
            payload = { lista: { orden: null, numeroRegistros: null, desplazamiento: null } }
        }
        if (!payload.lista) {
            payload.lista = { orden: null, numeroRegistros: null, desplazamiento: null };
        }
        if (!payload.lista.orden) {
            payload.lista.orden = state.orden;
        }
        if (payload.lista.numeroRegistros === undefined || payload.lista.numeroRegistros === null) {
            payload.lista.numeroRegistros = this.estadoInicial.paginacion.numeroRegistros
                ? this.estadoInicial.paginacion.numeroRegistros : this.nS.numeroFilasTabla;
        }
        if (payload.lista.desplazamiento === undefined || payload.lista.desplazamiento === null) {
            payload.lista.desplazamiento = 0;
        }
        return payload;
    }

    protected getParameters(state: any, lista: any) {
        const form = state.forms.form;
        const pay: any = {};

        if (form) {
            const query: object = this.fepS.formControlToModeloParametrosBusqueda(this.entityParse, form.model);
            if (query) {
                const q = QP.toB64QueryParamUrl(query);
                if (q) {
                    Object.assign(pay, { q })
                }
            }
        }

        const l = QP.toB64QueryParamUrl(lista);
        if (l) {
            Object.assign(pay, { l })
        }

        const v = QP.toB64QueryParamUrl({
            titulo: state.titulo,
            parametrosModo: state.parametrosModo,
            vista: state.vista && Object.keys(state.vista).length ? state.vista : undefined
        });

        if (v) {
            Object.assign(pay, { v })
        }

        return pay;
    }


    /**
     * Alternar parametros
     */
    alternarModoParametros(context: StateContext<T>, _accion: MAcciones.alternarModoParametros.Action) {
        const state = context.getState();
        context.patchState({ ...state, parametrosModo: this.nS.parametroService.alternarModo(state.parametrosModo) });
    }

    /**
     * Actalizar atos de tabla
     */
    actualizarTabla(context: StateContext<T>, { payload }: MAcciones.actualizarTabla.Action) {
        const state = context.getState();
        context.patchState({ ...state, ...payload });
    }


    /**
     * Eliminar seleccionados
     */
    eliminarSeleccionados(context: StateContext<T>, { payload }: MAcciones.eliminarSeleccionados.Action) {
        const state = context.getState();

        return this.nS.getServicio<MEntityServiceDefault>().eliminar(payload.ids)
            .pipe(
                map(err => err instanceof Array ? (err || []) : [err]),
                map(err => {
                    const entidadesSinError: any[] = [];
                    const entidadesConError: any[] = [];
                    for (const e of state.entidades.filter(_e => payload.ids.includes(_e.id))) {
                        if (err.filter(ee => ee.id === e.id).length) {
                            entidadesConError.push(e);
                        } else {
                            entidadesSinError.push(e);
                        }
                    }
                    return { entidadesSinError, entidadesConError };
                }),
                tap(({ entidadesSinError, entidadesConError }) => {
                    context.patchState({
                        entidades: state.entidades.filter(e => !entidadesSinError.includes(e)),
                        indicadores: {
                            indicesSeleccionados: [],
                            todasSeleccionadas: false,
                            unaSeleccionada: entidadesConError.length === 1,
                            algunaSeleccionada: entidadesConError.length >= 1,
                        }
                    } as T);
                }),
                tap(() => context.dispatch(this.noS.eliminarRegistrosCorrectamente())));
    }
}
