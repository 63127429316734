import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
    ContentChild, Input, OnDestroy, OnInit, TemplateRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TablaComponent } from '@fwk/components/tabla';
import { DisableControlService } from '@fwk/forms';
import { Actions, ActionType, ofActionErrored, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { mActionFactory } from '../actions/action-factory-builder';
import { dirtyAndError } from '../common/errors';
import { MSelector } from '../selectors/selector';
import { EstadoBusqueda } from '../states/api';
import { MNucleoService } from '../states/m-nucleo.service';
import { MHtmlComponent } from './html.component';
import { ParametrosModo } from './parametros/api';
import { PermissionsAvoidService } from './permissions-avoid.directive';

@Component({
    selector: 'f-lista',
    template: `
        <div class="f-mantenimiento-html">
            <f-titulo
                    [esForm]="false"
                    [textoTitulo]="textoTitulo$|async"
                    [traducirTitulo]="true"
                    [textoPrefijoTitulo]="prefijoTituloTexto$|async"
                    [sinPrefijo]="sinPrefijoTitulo"
                    [traducirPrefijoTitulo]="true">
            </f-titulo>
            <div class="f-contenido">
                <form class="f-contenido-parametros"
                    [ngClass]="(parametrosModo$ | async) === 'cerrado' ? 'parametros-cerrado' : ''">
                    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
                </form>
                <div class="f-contenido-form-lista">
                    <ng-container *ngIf="(estadoBuscado$ | async)!=='sinbuscar'">
                        <ng-content select="f-table"></ng-content>
                    </ng-container>
                </div>
            </div>
            <div class="f-footer">
                <ng-content select="f-botonera"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </div>
        </div>
    `,
    providers: [
        DisableControlService,
        PermissionsAvoidService
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MListaHtmlComponent extends MHtmlComponent implements AfterViewInit, OnInit, OnDestroy {

    @ContentChild('htmlForm', { static: false }) formTemplate!: TemplateRef<any>;

    @ContentChild('htmlFooter', { static: false }) footerTemplate!: TemplateRef<any>;

    @ContentChild(TablaComponent) tabla!: TablaComponent;

    @Input() sinPrefijoTitulo?: boolean;

    parametrosModo$!: Observable<ParametrosModo>;

    estadoBuscado$!: Observable<EstadoBusqueda>;

    formGroup!: FormGroup;

    keyStore!: string;

    constructor(
        private cd: ChangeDetectorRef,
        private actions$: Actions,
        public disableControlService: DisableControlService,
        public permissionsAvoidService: PermissionsAvoidService,
        store: Store,
        mNucleoService: MNucleoService) {
        super(store, mNucleoService, disableControlService);
    }

    ngOnInit() {
        this.keyStore = this.mNucleoService.keyStore;
        this.formGroup = this.mNucleoService.getFormGroup();
        this.disableControlService.registerFormGroup(this.formGroup);
        this.createCommonSelectors(this.keyStore);
        this.estadoBuscado$ = this.store.select(MSelector.selectBusqueda(this.keyStore));
        this.parametrosModo$ = this.store.select(MSelector.selectParametrosModo(this.keyStore));
        this.initActionSubscriber();

        this.formGroup.valueChanges.subscribe(res => {
            if (!this.mNucleoService.isGetPermissions()) {
                this.formGroup.disable({ emitEvent: false });
            }
        });
    }

    private initActionSubscriber() {
        this.actions$
            .pipe(
                takeUntil(this.onDestroy$),
                ofActionErrored(mActionFactory(this.mNucleoService.keyStore).buscarAccion() as ActionType),
                map(() => this.formGroup),
                tap(res => dirtyAndError(res.controls)),

                tap(() => this.cd.markForCheck())
            ).subscribe()
    }

    ngAfterViewInit() {
        this.disableControlService.checkDisableControlHtmlInit();
        if (!this.mNucleoService.isGetPermissions()) {
            this.formGroup.disable({ emitEvent: false });
            this.permissionsAvoidService.listControls.forEach(control => {
                control.enable({ emitEvent: false });
            });
        }
    }

    ngOnDestroy() {
        super.fwkOnDestroy(this.keyStore, true);
    }
}
