import { FormStateModel } from '@fwk/forms';
import { TextM } from '@fwk/translate';
import { ParametrosModo } from '../components/parametros/api';
import {
    EstadoBusqueda, IndicadoresLista, MantenimientoFichaStoreModel, MantenimientoListaStoreModel, MStoreModel, Orden,
    Paginacion
} from '../states/api';

export class SelectPermissions {

    static selectPermissions(state: { permisos: string }): string {
        const res = state?.permisos;
        return res;
    }

    static selectIsGet(state: { permisos: string }): boolean {
        return SelectPermissions.selectPermissions(state)?.charAt(0) === '1';
    }

    static selectIsPost(state: { permisos: string }): boolean {
        return SelectPermissions.selectPermissions(state)?.charAt(1) === '1';
    }

    static selectIsPut(state: { permisos: string }): boolean {
        return SelectPermissions.selectPermissions(state)?.charAt(1) === '1';
    }

    static selectIsDelete(state: { permisos: string }): boolean {
        return SelectPermissions.selectPermissions(state)?.charAt(3) === '1';
    }

}

export class MSelector {

    // comun

    static selectState(clave: string): (state: { [key: string]: any }) => MStoreModel {
        const res = (state: any): MStoreModel => state[clave];
        return res;
    }

    static selectTitulo(clave: string): (state: MStoreModel) => TextM {
        const res = (state: MStoreModel) => MSelector.selectState(clave)(state).titulo || { text: '' };
        return res;
    }

    static selectPermisos(clave: string): (state: MStoreModel) => string {
        const res = (state: MStoreModel) => MSelector.selectState(clave)(state)?.permisos;
        return res;
    }

    static selectIsLectura(clave: string): (state: MStoreModel) => boolean {
        const res = (state: MStoreModel) => MSelector.selectState(clave)(state)?.permisos?.charAt(0) === '1';
        return res;
    }

    static selectIsEscritura(clave: string): (state: MStoreModel) => boolean {
        const res = (state: MStoreModel) => MSelector.selectState(clave)(state)?.permisos?.charAt(1) === '1';
        return res;
    }

    static selectIsEliminar(clave: string): (state: MStoreModel) => boolean {
        const res = (state: MStoreModel) => MSelector.selectState(clave)(state)?.permisos?.charAt(2) === '1';
        return res;
    }

    static selectTextoTitulo(clave: string): (state: MStoreModel) => string {
        const res = (state: MStoreModel) => MSelector.selectTitulo(clave)(state).text;
        return res;
    }

    static selectTraducirTitulo(clave: string): (state: MStoreModel) => boolean {
        const res = (state: MStoreModel) => MSelector.selectTitulo(clave)(state).translate || false;
        return res;
    }

    static selectArgumentosParametrosTitulo(clave: string): (state: MStoreModel) => string[] {
        const res = (state: MStoreModel) => MSelector.selectTitulo(clave)(state).argumentsParams || [];
        return res;
    }

    static select(clave: string, claveDato: string, defaultValue?: any): (state: MantenimientoFichaStoreModel) => string {
        const res = (state: MantenimientoFichaStoreModel) => {
            let model = MSelector.selectFichaState(clave)(state)
            const props = claveDato.split('.');
            let value = null
            for (const p of props) {
                if (model.hasOwnProperty(p)) {
                    model = model[p];
                    value = model;
                } else {
                    value = null;
                    break;
                }
            }
            return (value || defaultValue) as any;
        }
        return res;
    }

    static selectForm<T = any>(clave: string): (state: MStoreModel) => FormStateModel<T> {
        const res = (state: MStoreModel) => MSelector.selectState(clave)(state).forms.form;
        return res;
    }

    static selectParametros<T = any>(clave: string): (state: MStoreModel) => FormStateModel<T> {
        const res = (state: MStoreModel) => MSelector.selectState(clave)(state).forms.parametros;
        return res;
    }

    static selectFormIsValid(clave: string): (state: MStoreModel) => boolean {
        const res = (state: MStoreModel) => MSelector.selectForm(clave)(state).status === 'VALID';
        return res;
    }

    static selectFormIsInvalid(clave: string): (state: MStoreModel) => boolean {
        const res = (state: MStoreModel) => MSelector.selectForm(clave)(state).status !== 'VALID';
        return res;
    }

    static selectFormModel(clave: string, property: string, defaultValue?: any): (state: MStoreModel) => any {
        const res = (state: MStoreModel) => {
            let model = MSelector.selectForm(clave)(state).model;
            const props = property.split('.');
            let value = null
            for (const p of props) {
                if (model.hasOwnProperty(p)) {
                    model = model[p];
                    value = model;
                } else {
                    value = null;
                    break;
                }
            }
            return (value || defaultValue) as any;
        }

        return res;
    }

    static selectParametrosModel(clave: string, property: string, defaultValue?: any): (state: MStoreModel) => any {
        const res = (state: MStoreModel) => {
            let model = MSelector.selectParametros(clave)(state).model;
            const props = property.split('.');
            let value = null
            for (const p of props) {
                if (model.hasOwnProperty(p)) {
                    model = model[p];
                    value = model;
                } else {
                    value = null;
                    break;
                }
            }
            return (value || defaultValue) as any;
        }
        return res;
    }

    static selectIsFormModificado(clave: string): (state: MStoreModel) => boolean {
        const res = (state: MStoreModel) => MSelector.selectForm(clave)(state).dirty || false
        return res;
    }


    // ficha

    static selectFichaState(clave: string, defaultValue?: any): (state: any) => MantenimientoFichaStoreModel {
        const res = (state: any): MantenimientoFichaStoreModel => (state as any)[clave];
        return res;
    }


    static selectEntidadEnUsoEnBackendo(clave: string): (state: MantenimientoFichaStoreModel) => boolean {
        const res = (state: MantenimientoFichaStoreModel) => MSelector.selectFichaState(clave)(state).entidadEnUsoEnBackend
        return res;
    }

    static selectModo(clave: string): (state: MantenimientoFichaStoreModel) => string {
        const res = (state: MantenimientoFichaStoreModel) => MSelector.selectFichaState(clave)(state).modo
        return res;
    }

    static selectIsModoNuevo(clave: string): (state: MantenimientoFichaStoreModel) => boolean {
        const res = (state: MantenimientoFichaStoreModel) => MSelector.selectModo(clave)(state) === 'nuevo';
        return res;
    }

    static selectIsModoEditar(clave: string): (state: MantenimientoFichaStoreModel) => boolean {
        const res = (state: MantenimientoFichaStoreModel) => MSelector.selectModo(clave)(state) === 'editar';
        return res;
    }

    static selectId<E>(clave: string): (state: MantenimientoFichaStoreModel) => E {
        const res = (state: MantenimientoFichaStoreModel) => MSelector.selectFichaState(clave)(state).id;
        return res;
    }

    // TODO: quitar en el futuro
    static selectVisible(clave: string, claveVisible: string): (state: MStoreModel) => boolean {
        const res = (state: any) => {
            const vista = MSelector.selectFichaState(clave)(state).vista;
            if (vista.visible[claveVisible]) {
                return true;
            }
            return false;
        };
        return res;
    }

    static selectDisabled(clave: string, claveDisabled: string): (state: MStoreModel) => boolean {
        const res = (state: any) => {
            const vista = MSelector.selectFichaState(clave)(state).vista;
            if (vista.disabled[claveDisabled]) {
                return true;
            }
            return false;
        };
        return res;
    }


    // lista

    static selectListaState(clave: string): (state: { [key: string]: any }) => MantenimientoListaStoreModel {
        const res = (state: any): MantenimientoListaStoreModel => state[clave];
        return res;
    }

    static selectBusqueda(clave: string): (state: MantenimientoListaStoreModel) => EstadoBusqueda {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).busqueda;
        return res;
    }

    static selectBusquedaRealizada(clave: string): (state: MantenimientoListaStoreModel) => boolean {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).busqueda === 'busquedaRealizada';
        return res;
    }

    static selectBusquedaNoRealizada(clave: string): (state: MantenimientoListaStoreModel) => boolean {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).busqueda === 'sinbuscar';
        return res;
    }

    static selectEntidades<E>(clave: string): (state: MantenimientoListaStoreModel) => E[] {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).entidades;
        return res;
    }

    static selectIndicadores(clave: string): (state: MantenimientoListaStoreModel) => IndicadoresLista {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).indicadores;
        return res;
    }

    static selectIndicesEntidadesSeleccionadas(clave: string): (state: MantenimientoListaStoreModel) => number[] {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectIndicadores(clave)(state).indicesSeleccionados;
        return res;
    }

    static selectTodasSeleccionadas(clave: string): (state: MantenimientoListaStoreModel) => boolean {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectIndicadores(clave)(state).todasSeleccionadas;
        return res;
    }

    static unaSeleccionada(clave: string): (state: MantenimientoListaStoreModel) => boolean {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectIndicadores(clave)(state).unaSeleccionada;
        return res;
    }

    static selectAlgunaSeleccionada(clave: string): (state: MantenimientoListaStoreModel) => boolean {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectIndicadores(clave)(state).algunaSeleccionada;
        return res;
    }

    static selectOrden(clave: string): (state: MantenimientoListaStoreModel) => Orden[] {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).orden;
        return res;
    }

    static selectPaginacion(clave: string): (state: MantenimientoListaStoreModel) => Paginacion {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).paginacion;
        return res;
    }

    static selectDesplazamiento(clave: string): (state: MantenimientoListaStoreModel) => number {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectPaginacion(clave)(state).desplazamiento;
        return res;
    }

    static selectNumeroRegistros(clave: string): (state: MantenimientoListaStoreModel) => number {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectPaginacion(clave)(state).numeroRegistros;
        return res;
    }

    static selectNumeroRegistrosTotal(clave: string): (state: MantenimientoListaStoreModel) => number {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectPaginacion(clave)(state).numeroRegistrosTotal;
        return res;
    }

    static selectParametrosModo(clave: string): (state: MantenimientoListaStoreModel) => ParametrosModo {
        const res = (state: MantenimientoListaStoreModel) => MSelector.selectListaState(clave)(state).parametrosModo;
        return res;
    }
}
