import { Injector } from '@angular/core';
import { MNucleoService } from './m-nucleo.service';

// tslint:disable
export class _MInjector {

    private static _injector: Injector | null = null;

    private static _injectorsNucleoService: { nucleoService: MNucleoService, keyStore: string }[] = [];

    static setNucleoService(nucleoService: MNucleoService, keyStore: string) {
        if (_MInjector._injectorsNucleoService.filter(f => f.keyStore === keyStore).length === 0) {
            _MInjector._injectorsNucleoService.push({ nucleoService, keyStore });
        }
    }

    static delNucleoService(keyStore: string) {
        _MInjector._injectorsNucleoService = _MInjector._injectorsNucleoService.filter(f => f.keyStore !== keyStore);
    }

    static set(injector: Injector) {
        _MInjector._injector = injector;
    }

    static get<S>(provedor: any) {
        if (_MInjector._injector === null) {
            throw new Error('Injector nulo. verifique que esta importado el modulo ManteniemintoModule');
        }
        if (provedor === null || provedor === undefined) {
            throw new Error('El servicio es nulo');
        }
        return _MInjector._injector.get<S>(provedor);
    }

    static getNucleoService(keyStore: string) {
        if (!keyStore) {
            throw new Error('La clave no puede ser nula');
        }
        const nucleo = _MInjector._injectorsNucleoService.filter(f => f.keyStore === keyStore)[0];
        return nucleo ? nucleo.nucleoService : null;
    }
}
