import { Type } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { MAcciones } from '@fwk/aplicacion';
import { DatosParseService } from '@fwk/data';
import { BuildFormObject, MFormEntityParseService } from '@fwk/forms';
import { StateContext } from '@ngxs/store';
import { NotificacionStateService } from '../subestados/notificacion-state.service';
import { inicialFormState } from './api';
import { _MInjector } from './_m-injector';

export class MState /* implements NgxsOnChanges */ {

    // *** VARIABLES QUE SE PASAN A TRAVES DEL RESOLVER

    // entidad que se usa para los parsers
    private _entityParse?: Type<any>;

    get entityParse() {
        return this._entityParse;
    }

    private _url?: string;

    get url() {
        return this._url;
    }
    private _urlParams?: { [key: string]: any };

    get urlParams() {
        return this._urlParams;
    }

    private _urlQueryParams?: { [key: string]: any };

    get urlQueryParams() {
        return this._urlQueryParams;
    }

    // ngxsOnChanges(change: NgxsSimpleChange) {
    // console.debug('prev state', change.previousValue);
    // console.debug('next state', changefk.currentValue);
    // }

    constructor(public opc: { keyStore: string, sinPaginacion?: boolean, sinOrden?: boolean }) { }

    get nS() {
        return _MInjector.getNucleoService(this.opc.keyStore);
    }

    get currentUrl() {
        const router = _MInjector.get<Router>(Router);
        return router.url
    }

    get dps() {
        return _MInjector.get<DatosParseService>(DatosParseService);
    }

    get noS() {
        return _MInjector.get<NotificacionStateService>(NotificacionStateService);
    }

    get fepS() {
        return _MInjector.get<MFormEntityParseService>(MFormEntityParseService);
    }

    get bfo(): { [key: string]: BuildFormObject } {
        return this.nS?.build;
    }

    get estadoInicial() {
        return this.nS?.estadoInicial;
    }

    get keyStore() {
        return this.nS?.keyStore;
    }

    // comunes para ir quitando
    initState(payload: MAcciones.iniciar.Payload) {
        this._entityParse = payload.entity || this.nS.getBuildFormGroup().tipoEntidad;
        if (!this._entityParse) {
            throw new Error(`Entity is null`);
        }
        this._urlParams = payload.urlParams;
        this._urlQueryParams = payload.urlQueryParams;
        this._url = payload.url;
    }

    replaceQueryParamsRoute(queryParams: { [key: string]: string }) {
        const format = Object.entries(queryParams)
            .filter(([_, value]) => value != null && value !== '')
            .reduce((acc, curr) => (acc === '?' ? acc : `${acc}&`) + `${curr[0]}=${curr[1]}`, '?');
        const newRoute = `${this.url.split('?')[0]}${format !== '?' ? format : ''}`;
        this.nS.navigationService.remplazarRuta(newRoute);
        this._urlQueryParams = queryParams;
    }

    resetBuilder() {
        if (this.bfo) {
            for (const { build } of Object.values(this.bfo)) {
                build.formGroup.reset({ emitEvent: false });
                if (build.arrayEntities) {
                    for (const ae of Object.keys(build.arrayEntities)) {
                        const fa = build.formGroup.get(ae) as FormArray;
                        fa.clear();
                        fa.reset();
                    }
                }
            }
        }
    }

    setTitulo(context: StateContext<any>, { payload }: MAcciones.setTitulo.Action) {
        context.patchState({ titulo: payload.titulo });
    }

    visible(context: StateContext<any>, { payload }: { type: string, payload: { clave: { [key: string]: boolean } } }) {
        const estado = context.getState();
        context.patchState({
            ...estado,
            vista: {
                ...estado.vista,
                visible: {
                    ...estado.vista && estado.vista.visible ? estado.vista.visible : undefined,
                    ...payload.clave
                }
            }
        });
    }

    disabled(context: StateContext<any>, { payload }: { type: string, payload: { clave: { [key: string]: boolean } } }) {
        const estado = context.getState();
        context.patchState({
            ...estado,
            vista: {
                ...estado.vista,
                disabled: {
                    ...estado.vista && estado.vista.disabled ? estado.vista.disabled : undefined,
                    ...payload.clave
                }
            }
        });
    }

    eliminarEstado(context: StateContext<any>, _accion: MAcciones.eliminarEstado.Action) {
        // context.setState({});
    }

    resetEstado(context: StateContext<any>, _accion: MAcciones.resetEstado.Action) {
        // context.setState(this.estadoInicial);
    }

    resetForm(context: StateContext<any>, { payload }: MAcciones.resetForm.Action) {
        const state = context.getState();
        const inicialModel = this.estadoInicial.forms[payload.nombreForm || 'form'].model;
        const satelModel = state.forms[payload.nombreForm || 'form'].model;
        const resetModel = Object.entries(satelModel).map(([k, v]) => {
            if (inicialModel[k]) {
                return [k, inicialModel[k]];
            }
            return [k, null];
        }).reduce((a, c) => ({ ...a, [c[0]]: c[1] }), {});

        const initState = inicialFormState<any>();

        context.setState({
            ...state, forms: {
                ...state.forms,
                [payload.nombreForm || 'form']: {
                    ...initState,
                    model: resetModel,
                    status: payload.status ? payload.status : initState.status
                }
            }
        });
    }
}
