import {
    AfterViewInit, ChangeDetectionStrategy,
    ChangeDetectorRef, Component, ContentChild, Input, OnDestroy, OnInit, Optional, TemplateRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DisableControlService } from '@fwk/forms';
import { Actions, ActionType, ofActionCompleted, ofActionErrored, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { mActionFactory } from '../actions/action-factory-builder';
import { OnActionError, OnActionSuccess, Status } from '../actions/api';
import { dirtyAndError } from '../common/errors';
import { MSelector } from '../selectors/selector';
import { MNucleoService } from '../states/m-nucleo.service';
import { MHtmlComponent } from './html.component';
import { PermissionsAvoidService } from './permissions-avoid.directive';

@Component({
    selector: 'f-ficha',
    template: `
        <div *ngIf="showMenuBar" class="ficha-menubar">
        <a tabindex="-1">
            <img height="60px" id="layout-menu-logo" library="logo-layout" alt="logo aplicacion" src="assets/imagen/centro-1_logo_dark.png">
        </a>
        </div>
        <div class="f-mantenimiento-html">
            <f-titulo
                    [formModificado]="isFormModificado$|async"
                    [esForm]="true"
                    [iconoFormModificado]="iconoFormModificado"
                    [iconoRegistroEnUso]="iconoRegistroEnUso"
                    [registroEnUso]="resgitroEnUso$|async"
                    [textoTitulo]="textoTitulo$|async"
                    [traducirTitulo]="true"
                    [textoPrefijoTitulo]="prefijoTituloTexto$|async"
                    [sinPrefijo]="sinPrefijoTitulo"
                    [traducirPrefijoTitulo]="true">
            </f-titulo>
            <div class="f-contenido">
                <form class="f-contenido-form">
                    <ng-container *ngTemplateOutlet="formTemplate">
                    </ng-container>
                </form>
            </div>
            <div class="f-footer">
                <ng-content select="f-botonera"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate">
                </ng-container>
            </div>
        </div>
    `,
    providers: [
        DisableControlService,
        PermissionsAvoidService
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MFichaHtmlComponent extends MHtmlComponent implements OnInit, AfterViewInit, OnDestroy, OnActionSuccess, OnActionError {

    @ContentChild('htmlForm', { static: false }) formTemplate!: TemplateRef<any>;

    @ContentChild('htmlFooter', { static: false }) footerTemplate!: TemplateRef<any>;

    @Input() sinPrefijoTitulo?: boolean;

    @Input() saveAndUpdatePermissions?: boolean;

    @Input() showMenuBar?: boolean;

    iconoFormModificado?: string;

    iconoRegistroEnUso?: string;

    isFormModificado$?: Observable<boolean>;

    resgitroEnUso$?: Observable<boolean>;

    eliminarButton?: HTMLElement;

    hideEliminarButton?: boolean;

    formGroup!: FormGroup;

    keyStore!: string;

    constructor(
        store: Store,
        private cd: ChangeDetectorRef,
        private actions$: Actions,
        private permissionsAvoidService: PermissionsAvoidService,
        public disableControlService: DisableControlService,
        @Optional() public mNucleoService: MNucleoService) {
        super(store, mNucleoService, disableControlService);
    }

    ngOnInit() {
    }

    private initActionSubscriber() {
        this.actions$
            .pipe(
                takeUntil(this.onDestroy$),
                ofActionCompleted(mActionFactory(this.mNucleoService.keyStore).buscarAccion() as ActionType),
                tap(() => {
                    if (this.saveAndUpdatePermissions) {
                        if (!this.mNucleoService.isWritePermissions()) {
                            this.formGroup.disable({ emitEvent: false });
                        } else {
                            this.formGroup.enable({ emitEvent: false });
                        }
                    }
                })).subscribe();
        this.actions$
            .pipe(
                takeUntil(this.onDestroy$),
                ofActionErrored(mActionFactory(this.mNucleoService.keyStore).guardarAccion() as ActionType),
                map(() => this.formGroup),
                tap(res => dirtyAndError(res.controls)),
                tap(() => this.cd.markForCheck()),
            ).subscribe()

        this.actions$
            .pipe(
                takeUntil(this.onDestroy$),
                tap(({ action, status }: { action: ActionType, status: Status }) => {
                    switch (status) {
                        case 'SUCCESSFUL':
                            this.fwkOnSucces(action);
                            break;
                        case 'DISPATCHED':
                            break;
                        case 'ERRORED':
                            this.fwkOnSucces(action);
                            break;
                        case 'CANCELED':
                            break;
                    }
                })
            ).subscribe()
    }

    fwkOnSucces(_action: ActionType) { }

    fwkOnActionError(_action: ActionType) { }

    ngAfterViewInit() {
        this.keyStore = this.mNucleoService.keyStore;
        this.formGroup = this.mNucleoService.getFormGroup();
        this.iconoFormModificado = this.mNucleoService.iconoFormModificado;
        this.iconoRegistroEnUso = this.mNucleoService.iconoRegistroEnUso;
        this.disableControlService.registerFormGroup(this.formGroup);
        this.createCommonSelectors(this.keyStore);
        this.isFormModificado$ = this.store.select(MSelector.selectIsFormModificado(this.keyStore));
        this.resgitroEnUso$ = this.store.select(MSelector.selectEntidadEnUsoEnBackendo(this.keyStore));
        this.initActionSubscriber();
        this.disableControlService.checkDisableControlHtmlInit();
        if (!this.mNucleoService.isWritePermissions()) {
            this.formGroup.disable();
            this.permissionsAvoidService.listControls.forEach(control => {
                control.enable({ emitEvent: false });
            });
        }
    }

    ngOnDestroy() {
        super.fwkOnDestroy(this.keyStore);
    }
}
