export class ServidorError extends Error {
    constructor() {
        super(`Error de servidor`);
    }
}

export class ClienteError extends Error {
    constructor() {
        super(`Error de cliente`);
    }
}
