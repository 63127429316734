import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from '@fwk/components/radio-button';
import { ActionType } from '@ngxs/store';
import { ActivoInactivoExceptionIfControlDisabledDirective } from './activo-inactivo-excepcion-disabled.directive';
import { ActivoInactivoExceptionDirective } from './activo-inactivo-exception.directive';
import { ActivoInactivoComponent } from './activo-inactivo.component';
import { ERROR_ACTIONS_EVENTS_FEATURE, SUCCESS_ACTIONS_EVENTS_FEATURE } from './api';

@NgModule({
    declarations: [
        ActivoInactivoExceptionDirective,
        ActivoInactivoComponent,
        ActivoInactivoExceptionIfControlDisabledDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        RadioButtonModule
    ],
    exports: [
        ActivoInactivoExceptionDirective,
        ActivoInactivoComponent,
        ActivoInactivoExceptionIfControlDisabledDirective
    ]
})
export class ActivoInactivoModule {
    static forFeature(conf: { actions: ActionType[] }): ModuleWithProviders<ActivoInactivoModule> {
        return {
            ngModule: ActivoInactivoModule,
            providers: [
                {
                    provide: SUCCESS_ACTIONS_EVENTS_FEATURE,
                    useValue: conf.actions
                },
                {
                    provide: ERROR_ACTIONS_EVENTS_FEATURE,
                    useValue: conf.actions
                }
            ]
        }
    }
}
