import { InjectionToken, Type } from '@angular/core';
import { FcType } from '@fwk/forms';

export interface ControlType {
    key: string;
    type: FcType;
}

export const DYNAMIC_ENTITIES = new InjectionToken<DynamicTypes>('dynamic_entities');

export type DynamicTypes = DynamicEntityTypes | Type<any>;

export interface DynamicEntityTypes {
    entity: Type<any>;
    extras?: DynamicEntityExtras;
}
export interface DynamicEntityExtras {
    list?: {
        params?: FormExtras,
        columns?: {
            size?: { [key: string]: number };
        }
    };
    detail?: {
        form?: FormExtras
    };
}

export interface FormExtras {
    disableAllRquired?: boolean;
    permitRegex?: { [key: string]: string };
    capitalize?: string[];
    style?: { [key: string]: string };
    denyRegex?: { [key: string]: string };
    size?: { [key: string]: number };
}
