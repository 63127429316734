import { Directive, ElementRef, Inject, OnInit, Optional, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonDirective } from '@fwk/components/button';
import { NavigateAction } from '@fwk/navigation';
import { TranslateService } from '@fwk/translate';
import { Store } from '@ngxs/store';
import { MNucleoService } from '../../states/m-nucleo.service';

@Directive({
    selector: '[fButtonNuevo]'
})
export class ButtonNuevoDirective extends ButtonDirective implements OnInit {

    constructor(
        private router: Router,
        @Optional() @Inject('nuevo') private pathNuevo = 'nuevo',// para el futuro
        private store: Store,
        public elementRef: ElementRef<any>,
        public renderer: Renderer2,
        private ns: MNucleoService,
        public multilenguajeService: TranslateService) {
        super(elementRef, renderer, multilenguajeService);
        this.label = 'nuevo';
        this.icon = 'pi pi-plus';
        this.traducirLabel = true;
    }

    ngOnInit(): void {
        if (!this.ns.isWritePermissions()) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        }
    }

    fwkClick() {
        this.store.dispatch(new NavigateAction({ route: [`${this.router.url.split('?')[0]}/${'nuevo'}`] }));
    }
}
