import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    AplicacionModule, AplicacionService, AplicacionState, InformacionDialogoState, NGXS_OPTIONS_META, ProcesandoGlobalState
} from '@fwk/aplicacion';
import { AuthenticationModule, AuthenticationState } from '@fwk/authentication';
import { FwkHttpModule } from '@fwk/common';
import { MultiidiomaModule } from '@fwk/components/multi-idioma';
import { NotificacionModule, NotificacionState } from '@fwk/components/notification';
import { ProcesandoGlobalModule } from '@fwk/components/procesando-global';
import { TablaModule } from '@fwk/components/tabla';
import { DatosModule } from '@fwk/data';
import { LogModule } from '@fwk/log';
import { NavigationModule, NavigationState } from '@fwk/navigation';
import {
    FWKTranslateModule, MultilenguajeState,
    SinTraduccionHandler, TranslateLoader
} from '@fwk/translate';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import {
    AppClaveConverterHandler, ControlErroresResponseHanlder, MultilenguajeHttpLoader,
    SinTradHandler
} from './core/configuracion/handlers';
import { AppNgxsModule } from './core/configuracion/ngxs.module';



declare let require: any;

@NgModule({
    declarations: [AppComponent],
    imports: [
        // angular
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // fwk
        DatosModule.forRoot({
            storeKeyMeta: { name: NGXS_OPTIONS_META, prop: 'name' }
        }),
        AppNgxsModule,
        LogModule.forRoot({
            produccion: environment.produccion,
            desactivar: ['info', 'debug', 'trace']
        }),
        FWKTranslateModule.forRoot({
            defaultLanguage: 'ca',
            missingTranslationHandler: {
                provide: SinTraduccionHandler,
                useClass: SinTradHandler
            },
            keyConverterHandler: AppClaveConverterHandler,
            loader: {
                provide: TranslateLoader,
                useClass: MultilenguajeHttpLoader, deps: [HttpClient]
            }
        }),
        FwkHttpModule.forRoot({ httpUrl: environment.servidor.url }),
        NavigationModule.forRoot({
            routeNew: 'nuevo'
        }),
        NotificacionModule.forRoot({
            live: 10000,
            claveRoot: 'rootApp'
        }),
        AplicacionModule.forRoot({
            menuUrl: environment.menuUrl,
            nombreApp: environment.nombreApp,
            iconoFormModificado: 'pi pi-circle-on',
            archivoConfigUrl: environment.archivoConfigUrl,
            archivoPackageJson: require('package.json'),
            entorno: environment.entorno,
            produccion: environment.produccion,
            paginas: {
                paginaNoAutorizada: {
                    url: environment.rutasApp.paginaNoAutorizada,
                    label: 'volver',
                    text: 'zonaNoAutorizada'
                },
                paginaNoExiste: {
                    url: environment.rutasApp.paginaNoExiste,
                    label: 'volver',
                    text: 'paginaNoExiste'
                },
                sesionExpirada: {
                    url: environment.rutasApp.paginaSesionExpirada,
                    routerLink: [environment.rutasApp.autenticacion],
                    label: 'iniciarSesion',
                    text: 'sesionExpirada'
                },
                paginaEnConstruccion: {
                    url: environment.rutasApp.paginaEnConstruccion,
                    label: 'volver',
                    text: 'paginaEnConstruccion'
                }
            },
            errorResponseHandler: ControlErroresResponseHanlder,
        }),
        AuthenticationModule.forRoot({
            secrets: {
                clientId: environment.autenticacion.clientId,
                clientSecret: environment.autenticacion.clientSecret,
                granType: environment.autenticacion.granType,
            },
            publicRoutes: ['encuesta-token'],
            routes: {
                token: environment.autenticacion.tokenUrl,
                refreshToken: environment.autenticacion.refreshTokenUrl,
                autenticacion: environment.rutasApp.autenticacion,
                autenticacionCorrecta: environment.rutasApp.autenticacionCorrecta,
                sesionExpirada: environment.rutasApp.paginaSesionExpirada,
                appVersion: environment.servidor.appVersion
            },
            decodeFields: ['centro', 'idUsuario', 'encuesta'],
            inactivity: {
                inactivityLogoutTime: 5,
                preInactivityLogoutTime: 2
            }
        }),
        ProcesandoGlobalModule,
        NgxsModule.forRoot(([
            AplicacionState,
            InformacionDialogoState,
            NotificacionState,
            ProcesandoGlobalState,
            MultilenguajeState,
            NavigationState,
            AuthenticationState
        ]), {
            developmentMode: !environment.produccion,
            compatibility: {
                strictContentSecurityPolicy: true
            }
        }),
        MultiidiomaModule.forRoot({
            value: ['es', 'ca', 'in']
        }),
        TablaModule.forRoot({
            formatoFecha: {
                dep: AplicacionService,
                func: (dep: AplicacionService) => dep.getConfig('formatoFecha')
            }
        }),
        AppRoutingModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
