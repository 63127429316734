import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { ButtonDirective } from '@fwk/components/button';
import { TranslateService } from '@fwk/translate';

@Directive({
    selector: '[fButtonAceptar]',
})
export class ButtonAceptarDirective extends ButtonDirective {

    constructor(
        public elementRef: ElementRef<any>,
        public renderer: Renderer2,
        public multilenguajePiep: TranslateService) {
        super(elementRef, renderer, multilenguajePiep);
        this.icon = 'pi pi-check';
        this.label = 'aceptar';
        this.traducirLabel = true;
        this.personalizar = true;
    }
}
