import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export interface ProcesandoGlobalStateMo {
    show: boolean;
    height?: string;
    width?: string;
}

export const inicialProcesandoGlobalState = {
    show: false
};

export class MostrarProcesandoGlobalAccion {
    static readonly type = '[APLICACION] Mostrar procesando global';
    constructor(public payload?: {
        width: string,
        height: string
    }) { }
}

export class OcultarProcesandoGlobalAccion {
    static readonly type = '[APLICACION] Ocultar procesando global';
}

@State<ProcesandoGlobalStateMo>({
    name: 'proceandoGlobal',
    defaults: inicialProcesandoGlobalState
})
@Injectable()
export class ProcesandoGlobalState {

    @Selector()
    static selectState(state: ProcesandoGlobalStateMo) {
        return state;
    }

    @Selector()
    static selectShow(state: ProcesandoGlobalStateMo) {
        return state.show;
    }

    @Selector()
    static selectWidth(state: ProcesandoGlobalStateMo) {
        return state.width;
    }

    @Selector()
    static selectHeight(state: ProcesandoGlobalStateMo) {
        return state.height;
    }

    @Action(MostrarProcesandoGlobalAccion)
    mostrarProcesandoGlobal(context: StateContext<ProcesandoGlobalStateMo>, { payload }: MostrarProcesandoGlobalAccion) {
        const { width, height } = payload ? payload : { width: undefined, height: undefined };
        context.patchState({ show: true, width, height });
    }

    @Action(OcultarProcesandoGlobalAccion)
    ocultarProcesandoGlobal(context: StateContext<ProcesandoGlobalStateMo>, _accion: OcultarProcesandoGlobalAccion) {
        context.patchState({ show: false });
    }
}
