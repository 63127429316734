import { InjectionToken, Type } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { EntityServiceOpc, FormStateModel } from '@fwk/forms';
import { TextM } from '@fwk/translate';
import { StateClass } from '@ngxs/store/internals';
import { ParametrosModo } from '../components/parametros/api';
import { MService } from '../services/service-decorators';

// provedores injection tokens

export const M_CLAVE = new InjectionToken<string>('m_clave');

export const M_STATE = new InjectionToken<StateClass>('m_state');

export const M_STATE_OPC = new InjectionToken<StateClass>('m_state_opc');

export const M_TYPE_STATE = new InjectionToken<StateClass>('m_type_state');

export const M_SERVICE = new InjectionToken<MService>('m_service');

export const M_COMPONENT = new InjectionToken<MService>('m_component');

export const M_RESOLVER = new InjectionToken<MService>('m_resolver');

export const M_FORM_BUILD_ARGUMENTS = new InjectionToken<MService>('m_form_build_arguments');

export const M_ESTADO_INICIAL = new InjectionToken<boolean>('m_estado_inicial');


// metadata

export const NGXS_OPTIONS_META = 'NGXS_OPTIONS_META';

export const FWK_FORM_BUILD_ARGUMENTS_META = 'FWK_FORM_BUILD_ARGUMENTS_META';

export const FWK_SERVICE_META = 'FWK_SERVICE_META';

export const FWK_STORE_TYPE_META = 'FWK_STORE_TYPE_META'

export const FWK_STORE_OPC = 'FWK_STORE_OPC'

export const FWK_KEYSTORE_META = 'FWK_KEYSTORE_META'


// ordenar

export declare type MComponent<T = any> = new (...args: any[]) => T;

// para las acciones de estado que queremos hacer override
export const FWK_STATE_ACTION = 'FWK_STATE_ACTION'

export const SERVICE_URL_META = 'SERVICE_URL_META';

export const SERVICE_METHOD_META = 'SERVICE_METHOD_META';

export interface EntityServiceFormBuilder<T> {
    service: Type<any>;
    valoresDefecto?: { campo: (keyof T), valor: any[] }[];
    ocultarPropiedadesEntidad?: (keyof T)[];
    desactivarRequerido?: boolean;
    validaciones?: { campo: (keyof T), validacion: ValidatorFn[] }[];
}


// State

export type StateType = 'list' | 'detail' | 'newdetail' | 'listdetail' | 'empty' | 'emptyempty' | 'dynamic-list' | 'dynamic-detail';

export type StateArgs<T> = {
    type: StateType;
    store: Partial<T>,
    sinPaginacion?: boolean;
    sinOrden?: boolean;
    serviciosEntidad?: EntityServiceOpc<any>[];
    service?: Type<any>;
    estadosHijos?: any;
    clave?: string;
};



// Comun

export class FormBuildArguments {
    serviciosEntidad: EntityServiceOpc<any>[];
    initialState: any;
}

export interface MStoreModel<T = any> {
    titulo: TextM;
    permisos: string;
    forms: {
        form: FormStateModel<T>,
        parametros?: FormStateModel<T>
    }
}


//  Lista

export type EstadoBusqueda = 'buscando' | 'busquedaRealizada' | 'sinbuscar';

export interface Paginacion {
    numeroRegistros: number;
    desplazamiento?: number;
    numeroRegistrosTotal?: number;
}

export interface Orden {
    campo: string;
    tipo: 'asc' | 'desc';
}

export interface IndicadoresLista {
    indicesSeleccionados: number[];
    todasSeleccionadas: boolean;
    unaSeleccionada: boolean;
    algunaSeleccionada: boolean;
}

export interface MantenimientoListaStoreModel extends MStoreModel {
    parametrosModo: ParametrosModo;
    busqueda: EstadoBusqueda;
    orden: Orden[];
    paginacion: Paginacion;
    entidades: any[];
    indicadores: IndicadoresLista;
    numeroRegistrosTotal?: number;
}

// Ficha

export type MantenimientoModoFichaStore = 'nuevo' | 'editar' | 'empty';

export interface MantenimientoFichaStoreModel<T = any> extends MStoreModel<T> {
    modo: MantenimientoModoFichaStore;
    id: any;
    entidadEnUsoEnBackend: boolean;
    // TODO: quitar en el futuro
    vista?: {
        visible?: { [key: string]: boolean };
        disabled?: { [key: string]: boolean };
    },
}


//listaficha

export interface MantenimientoListaFichaStoreModel extends MStoreModel {
    modo: MantenimientoModoFichaStore;
    id: any;
    parametrosModo: ParametrosModo;
    busqueda: EstadoBusqueda;
    orden: Orden[];
    entidades: any[],
    paginacion: Paginacion;
    indicadores: IndicadoresLista;
    numeroRegistrosTotal?: number;
}


// Estados iniciales (TODO: mover a otro archivo)
// Estados iniciales (TODO: mover a otro archivo)
// Estados iniciales (TODO: mover a otro archivo)


export function inicialFormState<T>(): FormStateModel<T> {
    return {
        model: {} as T,
        dirty: false,
        status: 'VALID',
        errors: {}
    }
};

export const inicialMantenimientoState: MStoreModel = {
    titulo: { text: '' },
    permisos: null,
    forms: {
        form: inicialFormState()
    },
};

export function inicialMantenimientoStateFn<T>(): MStoreModel<T> {
    return {
        titulo: { text: '' },
        permisos: null,
        forms: {
            form: inicialFormState()
        }
    }
};

export const inicialMantenimientoListaState: MantenimientoListaStoreModel = {
    ...inicialMantenimientoState,
    busqueda: 'sinbuscar',
    entidades: null,
    indicadores: {
        indicesSeleccionados: [],
        todasSeleccionadas: false,
        unaSeleccionada: false,
        algunaSeleccionada: false
    },
    orden: [],
    paginacion: {
        desplazamiento: 0,
        numeroRegistros: 0,
        numeroRegistrosTotal: 0
    },
    parametrosModo: 'abierto',
};

export const inicialMantenimientoFichaState: MantenimientoFichaStoreModel = {
    ...inicialMantenimientoState,
    modo: 'nuevo',
    id: null,
    entidadEnUsoEnBackend: false,
    // TODO: quitar en el futuro
    // vista: {},
};

export const inicialMantenimientoListaFichaState: MantenimientoListaFichaStoreModel = {
    ...inicialMantenimientoState,
    modo: 'nuevo',
    id: null,
    entidades: null,
    busqueda: 'sinbuscar',
    indicadores: {
        indicesSeleccionados: [],
        todasSeleccionadas: false,
        unaSeleccionada: false,
        algunaSeleccionada: false
    },
    orden: [],
    paginacion: {
        desplazamiento: 0,
        numeroRegistros: 0,
        numeroRegistrosTotal: 0
    },
    parametrosModo: 'abierto',
};
