import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export function getAllErrors(controls: { [key: string]: AbstractControl }): { [key: string]: string } {
    const err: { [key: string]: string } = {};

    const fn = (key: string, e: ValidationErrors) => {
        if (e && Object.keys(e).length) {
            Object.assign(err, { [key]: e });
        }
    }

    for (const [key, ctrl] of Object.entries(controls)) {

        if (ctrl instanceof FormArray) {
            for (const ctrla of ctrl.controls) {
                fn(key, getAllErrors((ctrla as FormGroup).controls));
            }

        } else if (ctrl instanceof FormGroup) {
            fn(key, getAllErrors(ctrl.controls));

        } else if (ctrl instanceof FormControl) {
            fn(key, ctrl.errors);
        }
    }

    return err;
}



export function dirtyAndError(controls: { [key: string]: AbstractControl }) {
    for (const ctrl of Object.values(controls)) {

        if (ctrl instanceof FormArray) {
            for (const c of ctrl.controls) {
                dirtyAndError((c as FormGroup).controls);

            }

        } else if (ctrl instanceof FormGroup) {
            dirtyAndError((ctrl as FormGroup).controls);

        } else if (ctrl instanceof FormControl) {
            if (ctrl.errors) {
                ctrl.markAsDirty();
                ctrl.updateValueAndValidity();
            }
        }
    }
}
