import { Directive, Injectable, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Injectable()
export class PermissionsAvoidService {
    listControls: Array<AbstractControl> = [];
}

@Directive({
    selector: '[fPermissionsAvoid]'
})
export class PermissionsAvoidDirective implements OnInit {

    constructor(
        private permissionsAvoidService: PermissionsAvoidService,
        private ngControl: NgControl
    ) { }

    ngOnInit(): void {
        this.permissionsAvoidService.listControls.push(this.ngControl.control);
    }
}
