import { Type } from '@angular/core';
import { StringUtils } from '@fwk/common';
import { BuildFormObject, crearFormBuildObject } from '@fwk/forms';
import { Action, State as StateNGXS, StateContext } from '@ngxs/store';
import 'reflect-metadata';
import { MAcciones } from '../actions/actions.namespace';
import {
    FormBuildArguments, FWK_FORM_BUILD_ARGUMENTS_META, FWK_KEYSTORE_META, FWK_SERVICE_META, FWK_STATE_ACTION, FWK_STORE_OPC, FWK_STORE_TYPE_META, inicialFormState,
    inicialMantenimientoFichaState, inicialMantenimientoListaFichaState,
    inicialMantenimientoListaState,
    inicialMantenimientoState, MStoreModel, StateArgs
} from './api';
import { MFichaState } from './detail-state';
import { MDynamicListaState } from './dynamic-lista-state';
import { MListaFichaState } from './lista-ficha-state';
import { MListaState } from './lista-state';
import { MEmptyState } from './m-empty-state';
import { MNewDetailState } from './new-detail-state';
import { _MInjector } from './_m-injector';


function crearFuncionesEstado(clave: string, constructor: any, stateFunctions: any) {

    const tipoAccionesClase: {
        propiedad: string,
        type: string,
        descriptor: PropertyDescriptor,
        target: any
    }[] = Reflect.getMetadata(FWK_STATE_ACTION, constructor);

    for (const [nombreMetodo, act] of Object.entries(MAcciones)) {// cuidado!, estan todas juntas en el mismo namespace no estan separadas por estados

        const type = `[${StringUtils.cammelCaseToSnakeCaseUpper(clave)}] ${act.Action.type}`;

        if (!stateFunctions[nombreMetodo]) {
            continue;
        }

        const include = (tipoAccionesClase || []).find(f => f.type === act.Action.type);
        if (include) {
            Action({ type })(constructor.prototype, include.propiedad, include.descriptor)
        } else {
            const options = {
                configurable: true,
                enumerable: true,
                value: function (context: StateContext<any>, accion: any) // TODO: tipo de accion
                { return stateFunctions[nombreMetodo](context, accion) }
            };
            Action({ type })(constructor.prototype, nombreMetodo, options);
            Reflect.defineProperty(constructor.prototype, nombreMetodo, options);
        }
    }
}



/**
 * Decorador mantenimiento ficha state
 */
export function MStateD<S>(opc: StateArgs<S>) {
    return function _mState<T extends new (...args: any[]) => {}>(constructor: T) {

        Reflect.defineProperty(constructor, FWK_STORE_OPC, {
            configurable: false,
            enumerable: true,
            value: opc
        });

        const keyStore = opc.clave || StringUtils.pascalCaseToCammelCase(constructor.name.split('State')[0]);

        const storeType = opc.type;

        if (!keyStore) {
            throw new Error(`No existe la clave del estado ${constructor.name}`)
        }

        if (!storeType) {
            throw new Error(`No existe el tipo en el estado ${constructor.name}`)
        }

        // El tipo va de meta y sirve para la creacion de los componentes
        Reflect.defineProperty(constructor, FWK_STORE_TYPE_META, {
            configurable: false,
            enumerable: true,
            value: storeType
        });

        Reflect.defineProperty(constructor, FWK_KEYSTORE_META, {
            configurable: false,
            enumerable: true,
            value: keyStore
        });

        if (storeType !== 'emptyempty') {

            let servicio: any;
            if (storeType !== 'empty' && opc.service) {
                servicio = opc.service;
            } else if (opc.service) {
                servicio = opc.service;
            }

            Reflect.defineProperty(constructor, FWK_SERVICE_META, {
                configurable: false,
                enumerable: true,
                value: servicio
            });
        }

        let initialState: any;
        let stateClass: any;


        if (storeType === 'detail') {
            stateClass = new MFichaState({ keyStore });
            initialState = getEstadoInicial(opc, inicialMantenimientoFichaState);
        }
        else if (storeType === 'list') {
            // stateClass = new MDynamicListaState(keyStore, opc.sinPaginacion, opc.sinOrden);
            stateClass = new MListaState({ keyStore, sinPaginacion: opc.sinPaginacion, sinOrden: opc.sinOrden });
            initialState = getEstadoInicial(opc, inicialMantenimientoListaState);
        }
        else if (storeType === 'dynamic-list') {
            stateClass = new MDynamicListaState({ keyStore, sinPaginacion: opc.sinPaginacion, sinOrden: opc.sinOrden });
            initialState = getEstadoInicial(opc, inicialMantenimientoListaState);
        }
        else if (storeType === 'newdetail') {
            stateClass = new MNewDetailState({ keyStore });
            initialState = getEstadoInicial(opc, inicialMantenimientoFichaState);

        } else if (storeType === 'listdetail') {
            stateClass = new MListaFichaState({ keyStore });
            initialState = getEstadoInicial(opc, inicialMantenimientoListaFichaState);

        } else if (storeType === 'empty') {// no usa servicio
            stateClass = new MEmptyState({ keyStore });
            initialState = getEstadoInicial(opc, inicialMantenimientoState);
        } else if (storeType === 'emptyempty') {
            initialState = getEstadoInicial(opc);
        }

        // NGXS_OPTIONS_META => clave para obtener las opciones de estado
        StateNGXS<Partial<S>>({ name: keyStore, defaults: initialState, children: opc.estadosHijos })(constructor);

        if (storeType !== 'emptyempty') {


            // estados hijos o estados sencillos que no usan servicios ( ejemplo domicilio ) Falta ajustar
            if (opc.serviciosEntidad) {
                // generamos el formBuilderGroup solo para el valor inicial.
                const buildFormObject = crearFormBuildObject(opc.serviciosEntidad, initialState.forms);
                for (const [nombreForm, bfg] of Object.entries(buildFormObject)) {
                    initialState.forms[nombreForm].model = bfg.modelInicial;
                }

                // definimos el builder para luego poder recuperarlos e injectarlo en el nucleo service
                Reflect.defineProperty(constructor, FWK_FORM_BUILD_ARGUMENTS_META, {
                    configurable: false,
                    enumerable: true,
                    value: {
                        serviciosEntidad: opc.serviciosEntidad,
                        initialState: initialState
                    } as FormBuildArguments
                });
            } else {
                Reflect.defineProperty(constructor, FWK_FORM_BUILD_ARGUMENTS_META, {
                    configurable: false,
                    enumerable: true,
                    value: {
                        initialState: initialState
                    } as FormBuildArguments
                });
            }

            crearFuncionesEstado(keyStore, constructor, stateClass);

            Reflect.defineProperty(constructor, 'STATE', {
                configurable: false,
                enumerable: true,
                value: stateClass
            });
        }

        return constructor;
    };
}


export function getEstadoInicial<S extends MStoreModel>(args: StateArgs<S>, estadoInicial1?: any) {
    let estadoInicial: Partial<S> = estadoInicial1 != null ? { ...estadoInicial1, ...args.store } : { ...args.store };
    if (args.serviciosEntidad) {
        for (const svEn of args.serviciosEntidad) {
            const forms = args.store.forms;

            const nombreForm = svEn.nombreForm || 'form';

            const modeloIniciaState = forms && forms[nombreForm] && forms[nombreForm].model ? forms[nombreForm].model : {}

            estadoInicial = {
                ...estadoInicial,
                forms: {
                    ...estadoInicial.forms,
                    [nombreForm]: {
                        ...inicialFormState(),
                        model: {
                            ...new svEn.tipoEntidad(), // valores por defecto de la entidad
                            ...modeloIniciaState // valores por defecto a traves del estado
                        }
                    }
                }
            };
        }
    }
    return estadoInicial;
}


function getBuildFormObject(target: any, formName?: 'parametros'): BuildFormObject {
    const keyStore = target.constructor[FWK_KEYSTORE_META];
    if (!keyStore) {
        throw new Error(`No se encuentra la clave en ${target.constructor.name}`);
    }
    const mNucleoService = _MInjector.getNucleoService(keyStore);
    if (!mNucleoService) {
        throw new Error(`No se ecuentra el nucleoService para la clave ${keyStore}`);
    }

    const nameOpc = formName || 'form';
    const bfg: BuildFormObject = mNucleoService.getBuildFormGroup(nameOpc === 'form' ? null : 'parametros');
    if (!bfg) {
        throw new Error(`No se ecuentra el buildFormGroup para la clave ${keyStore} y nombre de form ${nameOpc}`);
    }
    return bfg;
}


/**
 * Metodo para injectar el form en el estado
 */
export function Form(formName?: 'parametros') {
    return function _form(target: any, property: string | symbol) {
        Reflect.defineProperty(target, property, {
            configurable: true,
            enumerable: true,
            get: () => getBuildFormObject(target, formName).build.formGroup
        });
    }
}


/**
 * Metodo para injectar el builder en el estado
 */
export function Build(formName?: 'parametros') {
    return function _form(target: any, property: string | symbol) {
        Reflect.defineProperty(target, property, {
            configurable: true,
            enumerable: true,
            get: () => getBuildFormObject(target, formName).build
        });
    }
}

/**
 * Metodo para injectar el builder en el estado
 */
export function BuildForm(formName?: 'parametros') {
    return function _form(target: any, property: string | symbol) {
        Reflect.defineProperty(target, property, {
            configurable: true,
            enumerable: true,
            get: () => getBuildFormObject(target, formName)
        });
    }
}


/**
 * Metodo para injectar el form en el estado
 */
export function RequiredProps(entity: Type<any>, formName?: 'parametros') {
    return function _requiredProps(target: any, property: string | symbol) {
        Reflect.defineProperty(target, property, {
            configurable: true,
            enumerable: true,
            get: () => {
                const reqField = getBuildFormObject(target, formName).requiredFields;
                return reqField.filter(m => m.entity === entity.name).map(m => m.properties)[0];
            }
        });
    }
}

/**
 * Decorador para injectar las funciones de estado
 */
export function Estado(target: any, property: string | symbol) {
    let state: string;
    Reflect.defineProperty(target, property, {
        configurable: false,
        enumerable: true,
        get: () => {
            if (!state) {
                state = target.constructor['STATE'];
            }
            return state;
        }
    });
}


export function KeyStore(target: any, property: string | symbol) {
    let keyStore: string;
    Reflect.defineProperty(target, property, {
        configurable: false,
        enumerable: true,
        get: () => {
            if (!keyStore) {
                keyStore = target.constructor[FWK_KEYSTORE_META];
            }
            return keyStore;
        }
    });
}
