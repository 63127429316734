import { Injectable } from '@angular/core';
import { ConfirmDialogoConfig, DynamicDialogoSeveridad, InfoDialogoConfig, MantDialogoService } from '@fwk/components/dialog';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export interface DialogoStateMo {
    id: string;
    titulo: string;
    mensaje: string;
    severidad?: DynamicDialogoSeveridad;
}

export const inicialDialogoState: DialogoStateMo = {
    id: null,
    titulo: null,
    mensaje: null,
};

export class AbrirDialogoInformacionAccion {
    static readonly type = '[APLICACION] Abrir dialogo informacion';
    constructor(public payload: InfoDialogoConfig) { }
}

export class AbrirDialogoConfirmacionAccion {
    static readonly type = '[APLICACION] Abrir dialogo confirmacion';
    constructor(public payload: ConfirmDialogoConfig) { }
}

export class CerrarDialogoAccion {
    static readonly type = '[APLICACION] Cerrar  dialogo';
    constructor(public payload?: { result: { action: boolean, res?: any } }) { }
}


@State<DialogoStateMo>({
    name: 'informacionDialogo',
    defaults: inicialDialogoState
})
@Injectable()
export class InformacionDialogoState {

    constructor(private dilogoService: MantDialogoService) { }

    @Selector()
    static selectState(state: DialogoStateMo) {
        return state;
    }

    @Selector()
    static selectTitulo(state: DialogoStateMo) {
        return state.titulo;
    }


    @Selector()
    static selectMensaje(state: DialogoStateMo) {
        return state.mensaje;
    }

    @Selector()
    static selectSeveridad(state: DialogoStateMo) {
        return state.severidad;
    }


    @Action(AbrirDialogoInformacionAccion)
    mostrarInformacionDialogo(_context: StateContext<DialogoStateMo>, { payload }: AbrirDialogoInformacionAccion) {
        this.dilogoService.abrirDialogoInformacion(payload);
    }

    @Action(AbrirDialogoConfirmacionAccion)
    mostrarConfirmacionDialogo(_context: StateContext<DialogoStateMo>, { payload }: AbrirDialogoConfirmacionAccion) {
        this.dilogoService.abrirDialogoConfirmacion(payload);
    }

    @Action(CerrarDialogoAccion)
    ocultarInformacionDialogo(_context: StateContext<DialogoStateMo>, { payload }: CerrarDialogoAccion) {
        this.dilogoService.cerrarDialogoAbierto(payload && payload.hasOwnProperty('result') ? payload.result : undefined);
    }
}
