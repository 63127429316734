import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QP } from '@fwk/common';
import { PaginaNoExisteComponent, PaginaSesionExpiradaComponent } from '@fwk/components/static-page';
import { environment } from 'environment';
import { InicioModule } from './vistas/inicio/inicio.module';

@NgModule({
    imports: [
        InicioModule,
        RouterModule.forRoot([
            {
                path: '',
                loadChildren: () => import('./core/componentes/layout/layout.routing.module')
                    .then(m => m.AppLayoutRoutingModule),
                pathMatch: 'prefix'
            },
            {
                path: QP.removeFistSlash(environment.rutasApp.paginaNoExiste),
                component: PaginaNoExisteComponent
            },
            {
                path: QP.removeFistSlash(environment.rutasApp.paginaSesionExpirada),
                component: PaginaSesionExpiradaComponent
            },
            {
                path: QP.removeFistSlash(environment.rutasApp.autenticacion),
                loadChildren: () => import('./core/componentes/autenticacion/autenticacion.module')
                    .then(m => m.AppAutenticacionModule)
            },
            {
                path: 'encuesta-token',
                loadChildren: () => import('./vistas/encuesta-token/encuesta-token.routing.module')
                    .then(m => m.EncuestaTokenModule)
            },
            {
                path: '**',
                redirectTo: environment.rutasApp.paginaNoExiste
            }
        ], {
            onSameUrlNavigation: 'reload',
            enableTracing: false
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
