import { Injectable } from '@angular/core';
import { MantDialogoService } from '@fwk/components/dialog';
import { MostrarNotificacionAccion } from '@fwk/components/notification';


@Injectable({ providedIn: 'root' })
export class NotificacionStateService {

    constructor(private mantDialogoService: MantDialogoService) { }

    error(mensaje: string) {
        return new MostrarNotificacionAccion({
            titulo: 'informacion',
            severidad: 'error',
            mensaje: { text: mensaje, traducirConValue: true }
        });
    }

    confirmacionAlGuardarRegistroEnUso() {
        return this.mantDialogoService.abrirDialogoConfirmacion({
            id: 'registroEnUso',
            titulo: 'confirmacion',
            mensaje: { text: 'confirmacionRegistroEnUso' }
        });
    }

    errorAlBuscarParametros(): MostrarNotificacionAccion {
        return new MostrarNotificacionAccion({
            severidad: 'error',
            mensaje: { text: 'buscarParametrosError' }
        });
    }

    confirmacionEliminar() {
        return this.mantDialogoService.abrirDialogoConfirmacion({
            id: 'eliminar',
            titulo: 'confirmacion',
            mensaje: { text: 'confirmacionEliminarSeleccionados' }
        });
    }

    errorAlGuardar(): MostrarNotificacionAccion {
        return new MostrarNotificacionAccion({
            severidad: 'error',
            mensaje: { text: 'guardadoError' }
        });
    }

    errorAlEliminar(errores?: any): MostrarNotificacionAccion {
        return new MostrarNotificacionAccion({
            severidad: 'error',
            mensaje: { text: errores.mensaje, traducirConValue: true }
        });
    }

    guardadoCorrectamente(): MostrarNotificacionAccion {
        return new MostrarNotificacionAccion({
            titulo: 'informacion',
            severidad: 'info',
            mensaje: { text: 'guardadoCorrectamente' }
        });
    }

    errorAlEliminarVariosRegistros(idsError: any): MostrarNotificacionAccion {
        return new MostrarNotificacionAccion({
            titulo: 'informacion',
            severidad: 'error',
            mensaje: { text: 'algunosRegistrosNoEliminados', params: idsError },

        });
    }

    eliminarRegistrosCorrectamente(): MostrarNotificacionAccion {
        return new MostrarNotificacionAccion({
            titulo: 'informacion',
            severidad: 'info',
            mensaje: { text: 'registroEliminadosCorrectamente' },
        });
    }
}
