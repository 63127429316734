
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorResponseHandler, StringUtils } from '@fwk/common';
import {
    KeyConverterHandler, SinTraduccionHandler, SinTraduccionHandlerParams, TranslateLoader
} from '@fwk/translate';
import { environment } from 'environment';
import { merge, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class AppClaveConverterHandler implements KeyConverterHandler {
    convert(clave: string) {
        return StringUtils.snakeCaseToCammelCase(clave);
    }
}

export class SinTradHandler implements SinTraduccionHandler {
    handle(params: SinTraduccionHandlerParams) {
        return environment.multilenguaje.sinTraduccionMarcas.delante
            + params.key
            + environment.multilenguaje.sinTraduccionMarcas.detras;
    }
}

export class MultilenguajeHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient) { }

    getTranslate(idioma: string): Observable<object> {
        return merge(this.getMlSource(idioma), this.getMlBackSource(idioma));
    }

    getMlSource(idioma: string) {
        return this.http.get(environment.multilenguaje.urlFront.replace(/^\/|\/$/g, '') + `/${idioma}.json`);
    }

    getMlBackSource(idioma: string) {
        return this.http.get(environment.multilenguaje.url.replace(/^\/|\/$/g, '') + `/${idioma}`)
            .pipe(catchError(() => this.getMlSource(idioma)))
    }
}

export class ControlErroresResponseHanlder implements ErrorResponseHandler {
    errorResponse(err: any): HttpErrorResponse {
        if (err instanceof HttpErrorResponse) {
            if (+err.status === 400) {
                if (err.error.hasOwnProperty('error') && err.error.hasOwnProperty('error_description')) {
                    const e = new HttpErrorResponse({
                        ...err,
                        error: {
                            causa: err.error.error,
                            codigo: err.error.error,
                            mensaje: err.error.error
                        }
                    });
                    return e;
                }
            }
        }
        return err;
    }
}
