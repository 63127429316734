import { AfterContentChecked, Directive, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Directive({
    selector: '[fReset]'
})
export class ReiniciarHtmlDirective implements AfterContentChecked {

    constructor(private el: ElementRef, private renderer: Renderer2, private router: Router, private store: Store) { }

    ngAfterContentChecked() {
        this.renderer.listen(this.el.nativeElement, 'click', _event => {
            this.store.dispatch(this.router.navigate([this.router.url.split('?')[0]], {}));
        });
    }
}
