import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AplicacionConfiguracionMo, ARCHIVO_CONFIG_URL, ARCHIVO_PACKAGE_JSON } from './api';

declare let require: any;


@Injectable({
    providedIn: 'root'
})
export class AplicacionService {

    private _configJson: AplicacionConfiguracionMo;

    getPackageJson() {
        const packageJson = this._packageJsonUrl ? this._packageJsonUrl : require('package.json');
        if (!packageJson) {
            throw new Error('packae.json not found')
        }
        return packageJson;
    }

    get nombre(): string {
        return this.getPackageJsonKey('name');
    }

    get version(): string {
        return this.getPackageJsonKey('version');
    }

    get fwkLibraries(): string {
        return this.getPackageJsonKey('dependencies');
    }

    private pending = true;

    constructor(
        @Inject(ARCHIVO_CONFIG_URL) private _configUrl: string,
        @Optional() @Inject(ARCHIVO_PACKAGE_JSON) private _packageJsonUrl: string,
        private http: HttpClient) { }


    getPackageJsonKey(clave: string) {
        return this.getPackageJson()[clave];
    }

    // checkDependencies(lib: string, version: string): boolean {
    // const path = `node_modules/@fwk/${lib}/fwk-${lib}-${version}`;
    // try {
    //     require(path);
    // } catch (err) {
    //     console.debug(err);
    //     return false;
    // }
    //     return true;
    // }

    cargarConfig() {
        return this.http.get<AplicacionConfiguracionMo>(this._configUrl)
            .pipe(
                tap(res => {
                    this._configJson = res
                    this.pending = false;
                }),
                catchError(err => {
                    this._configJson = null;
                    return throwError(err);
                }));
    }

    getArchivoConfig(clave?: keyof AplicacionConfiguracionMo): Observable<any> {
        if (!this.pending) {
            if (this._configJson && clave) {
                return of(this._configJson[clave]);
            }
            return of(null);
        } else {
            return this.cargarConfig()
                .pipe(
                    map(config => {
                        if (config != null && clave != null) {
                            return config[clave];
                        }
                        return null;
                    }));
        }
    }

    getConfig(clave: keyof AplicacionConfiguracionMo): any {
        if (this._configJson[clave]) {
            return this._configJson[clave];
        }
        return null
    }
}
