import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@fwk/components/button';
import { LabelModule } from '@fwk/components/label';
import { InicioAppComponent } from './inicio-app.component';

@NgModule({
    declarations: [
        InicioAppComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        RouterModule,
        LabelModule,
        ButtonModule
    ]
})
export class InicioModule { }
