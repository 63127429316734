import { DatosState } from '@fwk/data';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FWK_KEYSTORE_META } from '../states/api';
import { _MInjector } from '../states/_m-injector';
import { CSelectors, FCSelectors, LCSelectors } from './api';
import { MSelector } from './selector';

export function selectorFactory(store: Store, storeKey: string) {

    return {

        builderCSelectors() {
            const selector: CSelectors = {
                selectPermisos$: store.select(MSelector.selectPermisos(storeKey)),
                selectIsLectura$: store.select(MSelector.selectIsLectura(storeKey)),
                selectIsEscritura$: store.select(MSelector.selectIsEscritura(storeKey)),
                selectIsEliminar$: store.select(MSelector.selectIsEliminar(storeKey)),
                select$: (claveDato: string, defaultValue?: any) => store.select(MSelector.select(storeKey, claveDato, defaultValue)),
                textoTitulo$: store.select(MSelector.selectTextoTitulo(storeKey)),
                traducirTitulo$: store.select(MSelector.selectTraducirTitulo(storeKey)),
                argumentosParametrosTitulo$: store.select(MSelector.selectArgumentosParametrosTitulo(storeKey)),
                datos$: (dato: string | { dato: string, mapTo?: string, subDato?: string }): Observable<any> => store.select(DatosState.selectDatos(dato)),
                datosGlobal$: (dato: string): Observable<any> => store.select(DatosState.selectGlobalDatos({ dato })),
                isFormModificado$: store.select(MSelector.selectIsFormModificado(storeKey)),
                selectForm$: () => store.select(MSelector.selectForm(storeKey)),
                isFormValid$: store.select(MSelector.selectFormIsValid(storeKey)),
                isFormInvalid$: store.select(MSelector.selectFormIsInvalid(storeKey)),
                selectFormModel$: (property: string, defaultValue?: any) => store.select(MSelector.selectFormModel(storeKey, property, defaultValue)),
                selectParametrosModel$: (property: string, defaultValue?: any) => store.select(MSelector.selectParametrosModel(storeKey, property, defaultValue)),
            }
            return selector;
        },

        builderFCSelector() {
            const selector: FCSelectors = {
                ...this.builderCSelectors(store, storeKey),
                modo$: store.select(MSelector.selectModo(storeKey)),
                modoEditar$: store.select(MSelector.selectIsModoEditar(storeKey)),
                modoNuevo$: store.select(MSelector.selectIsModoNuevo(storeKey)),

                visible$: (claveVisible: string): Observable<boolean> =>
                    store.select(MSelector.selectVisible(storeKey, claveVisible)),
                disabled$: (claveDisabled: string): Observable<boolean> =>
                    store.select(MSelector.selectDisabled(storeKey, claveDisabled))
            };
            return selector;
        },

        builderLCSelector() {
            const selector: LCSelectors = {
                ...this.builderCSelectors(store, storeKey),
                busqueda$: store.select(MSelector.selectBusqueda(storeKey)),
                busquedaRealizada$: store.select(MSelector.selectBusquedaNoRealizada(storeKey)),
                busquedaNoRealizada$: store.select(MSelector.selectBusquedaNoRealizada(storeKey)),
                entidades$: store.select(MSelector.selectEntidades(storeKey)),
                todasSeleccionada$: store.select(MSelector.selectTodasSeleccionadas(storeKey)),
                unaSeleccionada$: store.select(MSelector.unaSeleccionada(storeKey)),
                algunaSeleccionada$: store.select(MSelector.selectAlgunaSeleccionada(storeKey)),
                orden$: store.select(MSelector.selectOrden(storeKey)),
                paginacion$: store.select(MSelector.selectPaginacion(storeKey)),
                parametrosModo$: store.select(MSelector.selectParametrosModo(storeKey)),
            };
            return selector;
        }
    };
}

/**
 * Decorador para injectar los selectores del mantenimiento base
 */
export function ComponentSelector() {

    return function _componentSelector(target: any, property: string | symbol) {

        function initVal() {
            const store = _MInjector.get<Store>(Store);
            const keyStore = target.constructor[FWK_KEYSTORE_META];
            const mNucleoService = _MInjector.getNucleoService(keyStore);

            if (!mNucleoService) {
                throw new Error('No existe ningun nucleo service en contexto');
            }

            const stateFactory = selectorFactory(store, mNucleoService.keyStore);

            let val: CSelectors = stateFactory.builderCSelectors();
            if (mNucleoService.typeState) {
                if (mNucleoService.typeState.includes('detail')) {
                    val = { ...val, ...stateFactory.builderFCSelector() };
                }
                if (mNucleoService.typeState.includes('list')) {
                    val = { ...val, ...stateFactory.builderLCSelector() };
                }
            }

            return val;
        };

        Reflect.defineProperty(target, property, {
            configurable: true,
            enumerable: true,
            get: () => initVal()
        });
    }
}
