import { StringUtils } from '@fwk/common';

export function getDynamicParams(param: string, params: { [key: string]: any }) {
    let res = param;
    const match = param.match(/{{.*}}/);
    if (match) {
        match.forEach(m => {
            const key = m.replace('{{', '').replace('}}', '');
            const value = StringUtils.kebabCaseToCamelCase(params[key]);
            res = res.replace(m, value);
        });
    }
    return res;

}
