import { Directive, ElementRef, Input, OnInit } from '@angular/core';

// solo se usa en curso... mirar si sirve

@Directive({
    selector: '[fActivoInactivoExceptionIfControlDisabled]',
    providers: [
        // forwardRef(() => MHtmlComponent),
        // forwardRef(() => ActivoInactivoService)
    ]
})
export class ActivoInactivoExceptionIfControlDisabledDirective implements OnInit {

    @Input() disableControl?: boolean;

    constructor(
        private elementRef: ElementRef,
        // private html: MHtmlComponent,
        // private activoInactivoService: ActivoInactivoService
    ) { }

    ngOnInit() {
        const attr = this.elementRef.nativeElement.getAttribute('formControlName');
        if (!attr) {
            throw new Error(`No se encuentra la propiedade formControlName`);
        }

        // console.debug(this.activoInactivoService.id)

        // this.activoInactivoService.addLogicActivoInactivoControlException(attr, () => this.disableControl);
    }
}
