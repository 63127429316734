import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ObjectUtils } from '@fwk/common';
import { ActivoInactivoException, DisableControlService } from '@fwk/forms';

const FORM_CTRL_NAME = 'formcontrolname';
const FORM_CTRL_ID = 'formcontrolid';
const FORM_CTRL_EXCEPTION = 'formcontrolexception';

@Directive({
    selector: '[fActivoInactivoException]'
})
export class ActivoInactivoExceptionDirective implements OnInit {

    private id: string;

    constructor(
        private elementRef: ElementRef,
        private ngControl: NgControl,
        private render: Renderer2,
        private disableControlService: DisableControlService,
    ) {
        this.id = this.elementRef.nativeElement.getAttribute(FORM_CTRL_ID) || ObjectUtils.fwk_generarObjectId();
        this.render.setAttribute(this.elementRef.nativeElement, FORM_CTRL_EXCEPTION, '');

    }

    ngOnInit() {
        const attr = this.elementRef.nativeElement.getAttribute(FORM_CTRL_NAME);
        if (!attr) {
            throw new Error(`No se encuentra la propiedade formControlName`);
        }

        const ctrlEx = new ActivoInactivoException(this.id, this.elementRef.nativeElement.getAttribute(FORM_CTRL_NAME),
            this.ngControl.control);
        this.disableControlService.addActivoInactivoException(ctrlEx);
    }
}
