import { Entorno } from '@fwk/aplicacion';

export const environment: Entorno = {
    nombreApp: 'competencies-digitals',
    entorno: 'des',
    produccion: false,
    archivoConfigUrl: 'assets/config/config.json',
    servicioArchivos: {
        tamanioArchivoMaximo: 10000000,
        urlDescarga: 'rest/app',
        urlSubida: 'file'
    },
    servidor: {
        appVersion: 'app-version',
        checkVersionTime: 600000,
        url: 'rest/app',
    },
    autenticacion: {
        tokenUrl: 'auth',
        refreshTokenUrl: 'auth',
        clientId: 'competencies-digitals',
        clientSecret: 'mmM1&p2v*y7q&IA#zHr9',
        granType: 'password',
        actulizarToken: 0 // TODO:
    },
    menuUrl: 'rest/app',
    rutasApp: {
        autenticacion: '/autenticacion',
        inicio: '/inicio',
        autenticacionCorrecta: '/inicio',
        paginaNoExiste: '/pagina-no-existe',
        paginaSesionExpirada: '/sesion-expirada',
        paginaNoAutorizada: '/pagina-no-autorizada',
        paginaEnConstruccion: '/pagina-en-construccion'
    },
    multilenguaje: {
        url: 'ml/public',
        urlFront: 'assets/ml',
        sinTraduccionMarcas: {
            delante: '¿¿',
            detras: '??'
        }
    }
};
