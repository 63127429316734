import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { ButtonDirective } from '@fwk/components/button';
import { TranslateService } from '@fwk/translate';

@Directive({
    selector: '[fButtonCancelar]',
})
export class ButtonCancelarDirective extends ButtonDirective {

    constructor(
        public elementRef: ElementRef<any>,
        public renderer: Renderer2,
        public multilenguajePiep: TranslateService) {
        super(elementRef, renderer, multilenguajePiep);
        this.icon = 'pi pi-times';
        this.label = 'cancelar';
        this.traducirLabel = true;
        this.personalizar = true;
    }
}
