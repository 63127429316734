import { MEntityServiceDefault, MEntityServiceDefaultBase, StringUtils } from '@fwk/common';
import { Observable } from 'rxjs';
import { SERVICE_METHOD_META, SERVICE_URL_META } from '../states/api';
import { _MInjector } from '../states/_m-injector';

export abstract class MStateService<S = any> {
    abstract iniciar(model: S): Observable<{ model: S, llenarDatosField?: string }>;
}

export declare type MService = new (...args: any[]) => {};

export function MService(args?: { ruta?: string }) {

    return function _service<T extends MService>(constructor: T) {

        const ruta = args && args.ruta
            ? args.ruta
            : StringUtils.pascalCaseToKebabCase(constructor.name.split('Service')[0]);

        Reflect.defineMetadata(SERVICE_URL_META, ruta, constructor.prototype);

        for (const property of Object.getOwnPropertyNames(MEntityServiceDefault.prototype)) {
            if (typeof MEntityServiceDefault.prototype[property] !== 'function') {
                continue;
            }
            const svMethod = Reflect.getMetadata(SERVICE_METHOD_META, constructor);
            if (svMethod && svMethod === property) {
                // override del metodo, no hacemos nada
            } else {
                Reflect.defineProperty(constructor.prototype, property, {
                    enumerable: true,
                    value: (...argsss: any) => {
                        //TODO
                        const http = _MInjector.get<MEntityServiceDefaultBase>(MEntityServiceDefaultBase);
                        return new MEntityServiceDefault(http, ruta)[property](...argsss)
                    }
                });
            }
        }

        return constructor;
    };
}


export function MantSv(target: any, propertyKey: string | symbol) {
    Object.defineProperty(target.constructor.prototype, propertyKey, {
        enumerable: true,
        get: () => {
            const ruta = Reflect.getMetadata(SERVICE_URL_META, target);
            const http = _MInjector.get<MEntityServiceDefaultBase>(MEntityServiceDefaultBase);
            return new MEntityServiceDefault(http, ruta);
        }
    });
}

export function MantSvRuta(target: any, propertyKey: string | symbol) {
    Object.defineProperty(target.constructor.prototype, propertyKey, {
        enumerable: true,
        get: () => {
            const ruta = Reflect.getMetadata(SERVICE_URL_META, target);
            return ruta;
        }
    });
}

export function SvOverride(target: any, propertyKey: string | symbol) {
    Reflect.defineMetadata(SERVICE_METHOD_META, propertyKey, target.constructor);
}
