import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { ButtonDirective } from '@fwk/components/button';
import { TranslateService } from '@fwk/translate';
import { Store } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { mActionFactory } from '../../actions/action-factory-builder';
import { MantenimientoFichaStoreModel, MantenimientoListaStoreModel } from '../../states/api';
import { MFichaHtmlComponent } from '../ficha-html.component';

@Directive({
    selector: '[fButtonEliminar]'
})
export class ButtonEliminarDirective extends ButtonDirective implements OnInit, AfterContentInit, OnDestroy {


    @Input() isWritePermissions?: boolean;

    constructor(
        @Optional() private html: MFichaHtmlComponent,
        private store: Store,
        public elementRef: ElementRef<any>,
        public renderer: Renderer2,
        public multilenguajePiep: TranslateService) {
        super(elementRef, renderer, multilenguajePiep);
        this.label = 'eliminar';
        this.icon = 'pi pi-trash';
        this.traducirLabel = true;
        this.renderer.addClass(this.elementRef.nativeElement, 'ui-button-danger');
        if (this.html) {
            this.html.eliminarButton = this.elementRef.nativeElement;
        }
    }

    ngOnInit(): void {
        if (!this.isWritePermissions || (this.html && !this.html?.mNucleoService.isWritePermissions())) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        }
    }

    fwkClick() {
        // solo tiene sentido si no es personalizable
        this.eliminar();
    }

    ngAfterContentInit() {
        super.ngAfterContentInit();
        if (!this.personalizar && this.html) {
            this.store.select(state => {
                const st = state[this.html.mNucleoService.keyStore];
                // TODO: hacerlo por tipo de mantenimiento
                if (st.hasOwnProperty('modo')) {
                    return (st as MantenimientoFichaStoreModel).modo === 'editar';
                }
                if (st.hasOwnProperty('busqueda')) {
                    return (st as MantenimientoListaStoreModel).busqueda === 'busquedaRealizada';
                }

                throw new Error('no se encuentra ni el modo ni la busqueda');
            })
                .pipe(
                    takeUntil(this.onDestroy),
                    tap(res => {
                        if (this.html.mNucleoService.isWritePermissions()) {
                            this.renderer.setStyle(this.el.nativeElement, 'display', res ? 'inline' : 'none');
                        }
                    }))
                .subscribe();
        }

        // check hide from activo/inactivo
        if (this.html && this.html.hideEliminarButton) {
            this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
        }
    }

    eliminar() {
        this.store.dispatch(mActionFactory(this.html.mNucleoService.keyStore).eliminarAccion());
    }
}
