import { Type } from '@angular/core';
import { MAcciones } from '@fwk/aplicacion';
import { MEntityServiceDefault, QP } from '@fwk/common';
import { LlenarDatosAccion } from '@fwk/data';
import { ParamOpt, __PARAMETROS__ } from '@fwk/forms';
import { TextM } from '@fwk/translate';
import { StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { getDynamicParams } from '../common/common';
import { MostrarProcesandoGlobalAccion, OcultarProcesandoGlobalAccion } from '../subestados/procesando-global.state';
import { inicialFormState, MantenimientoListaStoreModel } from './api';
import { MState } from './m-state';

export class MDynamicListaState<T extends MantenimientoListaStoreModel> extends MState {

    iniciar(context: StateContext<T>, { payload }: MAcciones.iniciar.Action) {
        this.resetBuilder();
        this.initState(payload);

        this.nS.urlParams = payload.urlParams;

        const state = context.getState();

        const inicialEstadoQP: any = {};
        const decode: { l: any, q: any, v: any } = { l: null, q: null, v: null };

        let buscar = false;
        let entidadBusqueda = false;

        let titulo: TextM;
        if (this.estadoInicial.titulo.text && payload.urlParams) {
            titulo = {
                ...this.estadoInicial.titulo,
                text: getDynamicParams(this.estadoInicial.titulo.text, payload.urlParams)
            }
        }

        // miramos el payload, este payload se carga con los parametros de la ruta
        if (payload.urlQueryParams) {
            // lista orden
            if (payload.urlQueryParams.hasOwnProperty('l')) {
                decode.l = QP.fromQueryParamsUrlB64(payload.urlQueryParams.l);
                Object.assign(inicialEstadoQP, { orden: decode.l.orden });
                buscar = true;
            }

            // entidad busqueda
            if (payload.urlQueryParams.hasOwnProperty('q')) {
                decode.q = QP.fromQueryParamsUrlB64(payload.urlQueryParams.q);
                const model = { ...new this.entityParse(), ...decode.q };
                Object.assign(inicialEstadoQP, { forms: { ...state.forms, form: { ...inicialFormState(), model } } });
                entidadBusqueda = true;
            }

            // vista config
            if (payload.urlQueryParams.hasOwnProperty('v')) {
                decode.v = QP.fromQueryParamsUrlB64(payload.urlQueryParams.v);
                if (decode.v.titulo) {
                    Object.assign(inicialEstadoQP, { titulo: decode.v.titulo });
                }
                if (decode.v.parametrosModo) {
                    Object.assign(inicialEstadoQP, { parametrosModo: decode.v.parametrosModo });
                }
            }
        }

        // llenar datos devemos usar el servicio para poder mapear
        return context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: decode }))
            .pipe(
                switchMap(_globalstate => {
                    if (buscar || entidadBusqueda) {

                        context.dispatch(new MostrarProcesandoGlobalAccion());

                        const buscar$ = this.nS.getServicio<MEntityServiceDefault>().buscar({ l: payload.urlQueryParams.l, q: payload.urlQueryParams.q },
                            { before: payload.urlParams.param })
                            .pipe(
                                tap(({ respuesta, numeroRegistros }) => {
                                    context.patchState({
                                        permisos: this.nS.permission,
                                        paginacion: {
                                            desplazamiento: decode.l && decode.l.desplazamiento
                                                ? decode.l.desplazamiento
                                                : 0,
                                            numeroRegistros: decode.l && decode.l.numeroRegistros
                                                ? decode.l.numeroRegistros
                                                : respuesta.length,
                                            numeroRegistrosTotal: numeroRegistros
                                        },
                                        // sobrescribimos el con el nuevo estado
                                        ...inicialEstadoQP,
                                        entidades: respuesta.map(m => this.fepS.backToFrontEntity(this.entityParse, m)),
                                        busqueda: 'busquedaRealizada',
                                        titulo,
                                    } as T);
                                }),
                                finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion())));

                        if (!entidadBusqueda) {
                            return buscar$;

                        } else {

                            const model = inicialEstadoQP.forms.form.model;
                            const copiaModel = { ...model };
                            for (const key of Object.keys(model)) {
                                const parametrosMeta: ParamOpt = Reflect.getMetadata(__PARAMETROS__, new this.entityParse(), key);
                                if (parametrosMeta) {
                                    copiaModel[key] = model[parametrosMeta.mapToPropiedad];
                                    copiaModel[parametrosMeta.mapToPropiedad] = null;
                                }
                            }

                            const modelEntity = this.fepS.backToFrontEntity(this.entityParse, copiaModel);

                            return this.dps.mapDatosEnEntidad(modelEntity)
                                .pipe(
                                    tap(nuevoEstadoDatos => {
                                        inicialEstadoQP.forms.form.model = nuevoEstadoDatos;
                                        context.patchState({ ...inicialEstadoQP });
                                    }),
                                    switchMap(() => buscar$),
                                );
                        }

                    } else {
                        // IMPORTANTE!! este setState esta para cuando clickamos al mismo punto de menu y queremos que se borren los datos
                        // sino se quedarian con en el estado anterior puesto que no se destruye el modulo
                        // no hay que buscar, si no hay que buscar no puede haver iniciaFormEstado
                        context.setState({ ...this.estadoInicial as T, titulo });
                    }

                    return of('');
                }));
    }


    buscar(context: StateContext<T>, { payload }: MAcciones.buscar.Action) {
        const state = context.getState();
        const param = this.urlParams.param;

        payload = this.mountListPayload(state, payload);

        // agrupar bajo estados de inicio (ejemplo: estado inicio antes de buscar, estado inicio despues de buscar, etc...)
        context.patchState({
            ...state,
            entidadesSeleccionadas: [],
            orden: payload.lista.orden,
            busqueda: 'buscando',
            indicadores: {
                todasSeleccionadas: false,
                unaSeleccionada: false,
                algunaSeleccionada: false
            }
        });

        const pay = this.getParameters(state, payload.lista, this.entityParse);

        if (state.forms.form.status === 'VALID') {
            context.dispatch(new MostrarProcesandoGlobalAccion());

            // remplazamos los queryparams de la ruta
            this.replaceQueryParamsRoute({ 'q': pay.q, 'l': pay.l, 'v': pay.v });

            return this.nS.getServicio<MEntityServiceDefault>().buscar({ l: pay.l, q: pay.q }, { before: param })
                .pipe(
                    tap(({ respuesta, numeroRegistros }) => {
                        context.patchState({
                            ...state,
                            paginacion: {
                                desplazamiento: payload.lista && payload.lista.desplazamiento
                                    ? payload.lista.desplazamiento
                                    : 0,
                                numeroRegistros: payload.lista && payload.lista.numeroRegistros
                                    ? payload.lista.numeroRegistros
                                    : respuesta.length,
                                numeroRegistrosTotal: numeroRegistros
                            },
                            busqueda: 'busquedaRealizada',
                            // esto debe devolverlo el servicio ya mapeado (version2)
                            entidades: respuesta.map(m => this.fepS.backToFrontEntity(this.entityParse, m))
                        } as T);
                    }),
                    finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion())));


        } else {
            context.dispatch(new OcultarProcesandoGlobalAccion());
            context.dispatch(this.noS.errorAlBuscarParametros());

            // esto no debe ir (version2)
            return throwError(state.forms.form.errors);
        }
    }

    private mountListPayload(state: any, payload: MAcciones.buscar.Payload) {
        if (!payload) {
            payload = {
                lista: {
                    orden: null,
                    numeroRegistros: null,
                    desplazamiento: null
                }
            }
        }

        if (!payload.lista) {
            payload.lista = {
                orden: null,
                numeroRegistros: null,
                desplazamiento: null
            };
        }

        if (!payload.lista.orden) {
            payload.lista.orden = state.orden;
        }

        if (payload.lista.numeroRegistros == null) {
            payload.lista.numeroRegistros = this.estadoInicial.paginacion.numeroRegistros
                ? this.estadoInicial.paginacion.numeroRegistros : this.nS.numeroFilasTabla;
        }

        if (payload.lista.desplazamiento == null) {
            payload.lista.desplazamiento = 0;
        }

        return payload;
    }

    protected getParameters(state: any, lista: any, entityParse: Type<any>) {
        const form = state.forms['form'];
        const pay: any = {};

        if (form) {
            const query: object = this.fepS.formControlToModeloParametrosBusqueda(entityParse, form.model);
            if (query) {
                const q = QP.toB64QueryParamUrl(query);
                if (q) {
                    Object.assign(pay, { q })
                }
            }
        }

        const l = QP.toB64QueryParamUrl(lista);
        if (l) {
            Object.assign(pay, { l })
        }

        const v = QP.toB64QueryParamUrl({
            titulo: state.titulo,
            parametrosModo: state.parametrosModo,
            vista: state.vista && Object.keys(state.vista).length ? state.vista : undefined
        });

        if (v) {
            Object.assign(pay, { v })
        }

        return pay;
    }

    alternarModoParametros(context: StateContext<T>, _accion: MAcciones.alternarModoParametros.Action) {
        const state = context.getState();
        context.patchState({
            ...state,
            parametrosModo: this.nS.parametroService.alternarModo(state.parametrosModo)
        });
    }

    actualizarTabla(context: StateContext<T>, { payload }: MAcciones.actualizarTabla.Action) {
        const state = context.getState();
        context.patchState({ ...state, ...payload });
    }

    eliminarSeleccionados(context: StateContext<T>, { payload }: MAcciones.eliminarSeleccionados.Action) {
        const state = context.getState();

        return this.nS.getServicio<MEntityServiceDefault>().eliminar(payload.ids)
            .pipe(
                map(err => err instanceof Array ? (err || []) : [err]),
                map(err => {
                    const entidadesSinError: any[] = [];
                    const entidadesConError: any[] = [];
                    for (const e of state.entidades.filter(_e => payload.ids.includes(_e.id))) {
                        if (err.filter(ee => ee.id === e.id).length) {
                            entidadesConError.push(e);
                        } else {
                            entidadesSinError.push(e);
                        }
                    }
                    return { entidadesSinError, entidadesConError };
                }),
                tap(({ entidadesSinError, entidadesConError }) => {

                    // TODO: un patch en un map??? areglar esto
                    context.patchState({
                        entidades: state.entidades.filter(e => !entidadesSinError.includes(e)),
                        indicadores: {
                            indicesSeleccionados: [],
                            todasSeleccionadas: false,
                            unaSeleccionada: entidadesConError.length === 1,
                            algunaSeleccionada: entidadesConError.length >= 1,
                        }
                    } as T);
                }),
                tap(() => context.dispatch(this.noS.eliminarRegistrosCorrectamente())));
    }
}
