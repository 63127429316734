import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
    selector: 'app-inicio-app',
    template: `
        <div class="contenedor-inicio-nemi">
        </div>
    `,
    styles: [`
        .contenedor-inicio-nemi {
            height: 40px;
            /* background-color: #f7f7f9; */
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InicioAppComponent { }
