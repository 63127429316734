import { AfterViewInit, Directive, Inject, Input, OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { HTTP_URL, MEntityServiceDefault } from '@fwk/common';
import { CargarListaEventoMo, TablaComponent } from '@fwk/components/tabla';
import { FilesService } from '@fwk/files';
import { NavigateAction } from '@fwk/navigation';
// import { ReportsService } from '@fwk/reports';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { mActionFactory } from '../../actions/action-factory-builder';
import { MFichaHtmlComponent } from '../ficha-html.component';
import { MListaHtmlComponent } from '../lista-html.component';

@Directive({
    selector: '[fTablaEventos]',
    providers: [FilesService]
})
export class TablaEventosDirective implements AfterViewInit, OnDestroy {

    @Input('fTablaEventos') tablaComponentTemplate?: TablaComponent;

    @Input() parametros: { [key: string]: string };

    private subscription: Subscription[] = [];

    private get html() {
        return this.htmlLista || this.htmlFicha;
    }

    private get serviceUrl() {
        return (this.html.mNucleoService.getServicio<any>()?.sv as MEntityServiceDefault)?.url;
    }

    constructor(
        private store: Store,
        private router: Router,
        @Inject(HTTP_URL) private urlServer: string,
        // private reportsService: ReportsService,
        @Optional() private htmlLista: MListaHtmlComponent,
        @Optional() private htmlFicha: MFichaHtmlComponent
    ) { }

    ngAfterViewInit() {
        if (this.tablaComponentTemplate) {
            this.tablaComponentTemplate.entidadesSeleccionadas = [];
            this.subscription.push(this.registerFilaSeleccionada());
            this.subscription.push(this.registerEditar());
            this.subscription.push(this.registerCargaLazy());
        } else {
            throw new Error('No se encuentra la referencia a la tabla')
        }

        if (this.tablaComponentTemplate.showPrintButton == true) {
            this.tablaComponentTemplate.onPrint.subscribe(() => {
                const qp = window.location.search.split('?')[1]?.split('&').filter(s => !s.startsWith('v')).join('&');
                const paramsRouter = this.html.mNucleoService.urlParams?.param;
                if (this.serviceUrl) {
                    const url = this.urlServer + '/' + this.serviceUrl + (paramsRouter ? `/${paramsRouter}` : '');
                    // this.reportsService.printReport(url, 'XLS', { queryParams: qp });
                } else {
                    console.error('Service not found');
                }
            });
        }
    }

    private registerFilaSeleccionada() {
        return this.tablaComponentTemplate.onFilaSeleccionada.subscribe(event => {
            this.tablaComponentTemplate.entidadesSeleccionadas = event.seleccion;
            this.store.dispatch(mActionFactory(this.html.mNucleoService.keyStore)
                .filaSeleccionadaAccion({ entidades: event.seleccion }));
        });
    }

    private registerEditar() {
        return this.tablaComponentTemplate.onFilaClick.subscribe(event => {
            this.store.dispatch(new NavigateAction({
                route: [`${this.router.url.split('?')[0]}/${event.entidad.id}`],
                extras: { queryParams: this.parametros }
            }));
        });
    }

    private registerCargaLazy() {
        return this.tablaComponentTemplate.onCargar.subscribe(event => {
            this.html.eventoTabla = event;
            if (event.entidades) {
                this.buscar(event);
            }
        });
    }

    private buscar(event?: CargarListaEventoMo): void {
        this.tablaComponentTemplate.entidadesSeleccionadas = [];
        const lista = this.getListaParams(event);
        this.store.dispatch(mActionFactory(this.html.mNucleoService.keyStore).buscarAccion({ lista }));
    }

    private getListaParams(evento: any) {
        const lista = { orden: null, desplazamiento: 0 };
        if (evento) {
            lista.orden = evento.orden;
            lista.desplazamiento = evento.paginacion.desplazamiento;
        } else if (this.html.eventoTabla && this.html.eventoTabla.orden) {
            lista.orden = this.html.eventoTabla.orden;
        } else {
            lista.orden = this.tablaComponentTemplate.orden;
        }
        return lista;
    }

    ngOnDestroy() {
        this.subscription.forEach(s => s.unsubscribe());
    }
}
