import { StringUtils } from '@fwk/common';
import { MAcciones } from './actions.namespace';
import { MActionsState } from './m-actions-state';

/**
 * Método para crear una accion dinamicamente;
 */
function createAction(tag: string, accion: { type: string }, pay?: any) {
    if (!tag) {
        throw new Error(`El tag de la accion no puede ser null`);
    }

    const DynamicCreateAction = class {
        static readonly type = `[${StringUtils.cammelCaseToSnakeCaseUpper(tag)}] ${accion.type}`;
        // type = `[${StringUtils.fwk_cammelCaseToSnakeCaseUpper(tag)}] ${accion.type}`;
        constructor(public payload: any) { }
    };

    return new DynamicCreateAction(pay);
}

export function mActionFactory(tag: string): MActionsState {

    return {

        // empty

        iniciarAccion: (payload?: MAcciones.iniciar.Payload) =>
            createAction(tag, MAcciones.iniciar.Action, payload),

        // ficha

        setTituloAccion: (payload: MAcciones.setTitulo.Payload) =>
            createAction(tag, MAcciones.setTitulo.Action, payload),

        eliminarEstadoAccion: () =>
            createAction(tag, MAcciones.eliminarEstado.Action),

        resetEstadoAccion: () =>
            createAction(tag, MAcciones.resetEstado.Action),

        resetFormAccion: (payload: MAcciones.resetForm.Payload) =>
            createAction(tag, MAcciones.resetForm.Action, payload),

        visibleAccion: (payload: MAcciones.visible.Payload) =>
            createAction(tag, MAcciones.visible.Action, payload),

        disabledAccion: (payload: MAcciones.disabled.Payload) =>
            createAction(tag, MAcciones.disabled.Action, payload),

        guardarAccion: (payload?: MAcciones.guardar.Payload) =>
            createAction(tag, MAcciones.guardar.Action, payload),

        eliminarAccion: () =>
            createAction(tag, MAcciones.eliminar.Action),

        nuevaFilaListaAccion: (payload: MAcciones.nuevaFilaLista.Payload) =>
            createAction(tag, MAcciones.nuevaFilaLista.Action, payload),

        eliminarFilaListaAccion: (payload: MAcciones.eliminarFilaLista.Payload) =>
            createAction(tag, MAcciones.eliminarFilaLista.Action, payload),

        // lista

        cambiarParametrosAccion: (payload: MAcciones.cambiarParametros.Payload) =>
            createAction(tag, MAcciones.cambiarParametros.Action, payload),

        buscarAccion: (payload?: MAcciones.buscar.Payload) =>
            createAction(tag, MAcciones.buscar.Action, payload),

        buscarPorIdAccion: (payload: MAcciones.buscarPorId.Payload) =>
            createAction(tag, MAcciones.buscarPorId.Action, payload),

        alternarModoParametrosAccion: () =>
            createAction(tag, MAcciones.alternarModoParametros.Action),

        filaSeleccionadaAccion: (payload: MAcciones.filaSeleccionada.Payload) =>
            createAction(tag, MAcciones.filaSeleccionada.Action, payload),

        eliminarSeleccionadosAccion: (payload: MAcciones.eliminarSeleccionados.Payload) =>
            createAction(tag, MAcciones.eliminarSeleccionados.Action, payload),
    }
}
