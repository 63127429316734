import { StringUtils } from '@fwk/common';

/**
 * Metodo para componer el nombre del tipo de accion a partir de un estado
 */
const getStateTag = (stateClassName: string) => {
    if (!stateClassName.endsWith('State')) {
        throw new Error('El stateClassName debe provenir de un State es decir debe acabar en State')
    }
    return StringUtils.cammelCaseToSnakeCaseUpper(StringUtils.snakeCaseToCammelCase(stateClassName.split('State')[0]));
}

export const StateUtils = {
    getStateTag,
};
