import { FormArray, FormGroup } from '@angular/forms';
import { MAcciones } from '@fwk/aplicacion';
import { BuildFormEntity, BuildFormObject, cloneAbstractControl } from '@fwk/forms';
import { StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MStoreModel } from '../states/api';


export function eliminarFilaListaFn<T extends MStoreModel>(context: StateContext<T>, { payload }: MAcciones.eliminarFilaLista.Action,
    formGen: BuildFormObject) {

    const task = new Observable(obs => {

        const arrEn = Object.entries<BuildFormEntity>(formGen.build.arrayEntities).
            filter(([prop, _ae]) => prop === payload.formArrayName).
            map(([prop, ae]) => ({ prop, formGroup: ae.formGroup }));

        if (arrEn.length === 0) {
            throw new Error(`No existe el formArray ${payload.formArrayName}`);
        }

        const formArray = formGen.build.formGroup.get(arrEn[0].prop) as FormArray;

        if (formArray.length > 0 && formArray.length === payload.options.index.length) {
            formArray.clear();
            obs.next(true);

        } else if (formArray.length > 0) {
            for (let i = formArray.length; i-- > 0;) {
                if (payload.options.index.includes(i)) {
                    formArray.removeAt(i);
                }
            }
            obs.next(true);

        } else {
            obs.next(false);
        }

        obs.complete();
    });

    return task
        .pipe(
            tap(patch => {
                if (patch) {
                    const state = context.getState();
                    context.patchState({
                        ...state,
                        forms: {
                            ...state.forms,
                            form: {
                                ...state.forms.form,
                                dirty: true
                            }
                        }
                    });
                }
            }));

}


export function nuevaFilaListaFn<T extends MStoreModel>(context: StateContext<T>, { payload }: MAcciones.nuevaFilaLista.Action,
    build: BuildFormEntity) {

    if (payload.beforeFormArrays && payload.beforeFormArrays.length) {

        let fbe: BuildFormEntity = build;
        let fg: FormGroup = build.formGroup;

        for (const bfa of payload.beforeFormArrays) {
            fbe = fbe.arrayEntities[bfa.formArrayName];

            if (!fbe) {
                throw new Error(`No existe el arrayEntity ${bfa.formArrayName}`);
            }

            const arr = (fg.get(bfa.formArrayName) as FormArray);
            if (!arr) {
                throw new Error(`No existe el formArray ${bfa.formArrayName}`);
            }

            if (arr.length < bfa.index) {
                throw new Error(`No existe el indice ${bfa.index} en el formArray ${bfa.formArrayName}`);
            }

            fg = arr.at(bfa.index) as FormGroup;
            if (!fbe) {
                throw new Error(`No existe el formArray ${bfa.formArrayName}`);
            }
        }

        fbe.formGroup = fg;
        build = fbe;
    }

    const formArray: FormArray = build.formGroup.get(payload.formArrayName) as FormArray;
    const arrEn: BuildFormEntity = build.arrayEntities[payload.formArrayName];
    if (!arrEn) {
        throw new Error(`No existe el formArray ${payload.formArrayName}`);
    }

    const addRowsFormArray = (value?: any) => {
        const cloneFormGroup = cloneAbstractControl(arrEn.formGroup);
        if (value) {
            cloneFormGroup.setValue(value, { emitEvent: false });
        }
        if (formArray.length > 0) {
            if (formArray.controls[formArray.controls.length - 1].valid) {
                formArray.push(cloneFormGroup);
            } else {
                console.warn(`el formGroup no es valido`);
            }
        } else {
            formArray.push(cloneFormGroup);
        }
    }

    if (payload.options && payload.options.formArrayValues) {
        for (const vf of payload.options.formArrayValues) {
            addRowsFormArray(vf);
        }
    } else {
        addRowsFormArray();
    }

    const state = context.getState();
    context.patchState({
        ...state,
        forms: {
            ...state.forms,
            form: {
                ...state.forms.form,
                dirty: true
            }
        }
    });
}
