import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonFlotanteModule } from '@fwk/components/button-flotante';
import { GridModule } from '@fwk/components/container';
import { DropdownModule } from '@fwk/components/dropdown';
import { FWKTranslateModule } from '@fwk/translate';
import { DirectivesModule } from './directives/directives.module';
import { ParametrosComponent } from './parametros/parametros.component';
import { ParametrosService } from './parametros/parametros.service';
import { ReiniciarHtmlDirective } from './reiniciar-html.directive';
import { TituloComponent, TituloFormComponent } from './titulo.component';

@NgModule({
    declarations: [
        TituloComponent,
        ReiniciarHtmlDirective,
        ParametrosComponent,
        TituloFormComponent
    ],
    imports: [
        CommonModule,
        ButtonFlotanteModule,
        GridModule,
        DropdownModule,
        FWKTranslateModule,
        DirectivesModule
    ],
    providers: [
        ParametrosService
    ],
    exports: [
        TituloComponent,
        ReiniciarHtmlDirective,
        ParametrosComponent,
        TituloFormComponent,
        DirectivesModule
    ]
})
export class ChildrenModule { }
