import { MAcciones } from '@fwk/aplicacion';
import { MEntityService, MEntityServiceDefault, QP } from '@fwk/common';
import { LlenarDatosAccion } from '@fwk/data';
import { ParamOpt, __PARAMETROS__ } from '@fwk/forms';
import { NavigateAction } from '@fwk/navigation';
import { StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { MostrarProcesandoGlobalAccion, OcultarProcesandoGlobalAccion } from '../subestados/procesando-global.state';
import { inicialFormState, MantenimientoListaFichaStoreModel } from './api';
import { MState } from './m-state';

export class MListaFichaState<T extends MantenimientoListaFichaStoreModel> extends MState {

    searchPayload?: any;

    estadoInicialOrigianl: any;

    /**
     * Iniciar lista
     */
    iniciar(context: StateContext<MantenimientoListaFichaStoreModel>, { payload }: MAcciones.iniciar.Action) {
        this.resetBuilder();
        this.initState(payload);
        this.nS.urlParams = payload.urlParams;

        const store = context.getState();
        const formGenParametros = this.nS.getBuildFormGroup('parametros');

        const decode: { l: any, q: any, v: any } = { l: null, q: null, v: null };
        let buscar = false;
        let entidadBusqueda = false;

        const inicialEstadoQP: any = {};
        // miramos el payload, este payload se carga con los parametros de la ruta
        if (payload && payload.urlQueryParams && Object.keys(payload.urlQueryParams).length) {
            // lista orden
            if (payload.urlQueryParams.hasOwnProperty('l') && Object.keys(payload.urlQueryParams.l).length) {
                decode.l = QP.fromQueryParamsUrlB64(payload.urlQueryParams.l);
                Object.assign(inicialEstadoQP, { orden: decode.l.orden });
                buscar = true;
            }
            // entidad busqueda
            if (payload.urlQueryParams.hasOwnProperty('q') && Object.keys(payload.urlQueryParams.q).length) {
                decode.q = QP.fromQueryParamsUrlB64(payload.urlQueryParams.q);
                const model = { ...new formGenParametros.tipoEntidad(), ...decode.q };
                Object.assign(inicialEstadoQP, {
                    forms: {
                        ...store.forms,
                        parametros: {
                            ...inicialFormState(),
                            model
                        }
                    }
                });
                entidadBusqueda = true;
            }
            // vista config
            if (payload.urlQueryParams.hasOwnProperty('v') && Object.keys(payload.urlQueryParams.v).length) {
                decode.v = QP.fromQueryParamsUrlB64(payload.urlQueryParams.v);
                if (decode.v.titulo) {
                    Object.assign(inicialEstadoQP, { titulo: decode.v.titulo });
                }
                if (decode.v.parametrosModo) {
                    Object.assign(inicialEstadoQP, { parametrosModo: decode.v.parametrosModo });
                }
            }
        }

        this.estadoInicialOrigianl = store.forms.form.model

        return context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: decode }))
            .pipe(
                switchMap(() => {
                    if (buscar || entidadBusqueda) {

                        context.dispatch(new MostrarProcesandoGlobalAccion());

                        this.searchPayload = payload.urlQueryParams;

                        const buscar$ = this.nS.getServicio<MEntityServiceDefault>().buscar({ l: payload.urlQueryParams.l, q: payload.urlQueryParams.q })
                            .pipe(
                                map(({ respuesta, numeroRegistros }) => ({
                                    numeroRegistros,
                                    model: this.fepS.backToFrontEntity(this.nS.getTipoEntidad(), { id: null, entidades: respuesta })
                                })),
                                map(({ model, numeroRegistros }) => ({
                                    numeroRegistros,
                                    model: this.fepS.mapModelToOneEntityArrayEntity(this.bfo, 'form', model, this.estadoInicial.forms.form.model)
                                })),
                                map(({ model, numeroRegistros }) => {
                                    return ({
                                        ...inicialEstadoQP,
                                        busqueda: 'busquedaRealizada',
                                        permisos: this.nS.permission,
                                        forms: {
                                            ...inicialEstadoQP.forms,
                                            parametros: {
                                                ...(inicialEstadoQP.forms && inicialEstadoQP.forms.parametros
                                                    ? inicialEstadoQP.forms.parametros
                                                    : inicialFormState),
                                            },
                                            form: {
                                                ...(inicialEstadoQP.forms.form
                                                    ? inicialEstadoQP.forms.form
                                                    : inicialFormState),
                                                model
                                            }
                                        },
                                        entidadesSeleccionadas: [],
                                        paginacion: {
                                            desplazamiento: decode && decode.l.desplazamiento ? decode.l.desplazamiento : 0,
                                            numeroRegistros
                                        },
                                        indicadores: {
                                            todasSeleccionadas: false,
                                            unaSeleccionada: false,
                                            algunaSeleccionada: false
                                        }
                                    } as T);
                                }),
                                finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion())),
                            );

                        if (!entidadBusqueda) {
                            return buscar$
                                .pipe(
                                    tap(patch => context.patchState(patch)));

                        } else {

                            const model = inicialEstadoQP.forms.parametros.model;

                            const copiaModel = { ...model };

                            for (const key of Object.keys(model)) {
                                const parametrosMeta: ParamOpt = Reflect.getMetadata(__PARAMETROS__, new formGenParametros.tipoEntidad(), key);
                                if (parametrosMeta) {
                                    copiaModel[key] = model[parametrosMeta.mapToPropiedad];
                                    copiaModel[parametrosMeta.mapToPropiedad] = null;
                                }
                            }

                            const modelEntity = this.fepS.backToFrontEntity(formGenParametros.tipoEntidad, copiaModel);

                            return this.dps.mapDatosEnEntidad(modelEntity)
                                .pipe(
                                    switchMap(nuevoEstadoDatos => buscar$
                                        .pipe(
                                            tap(patch => {
                                                context.patchState({
                                                    ...patch,
                                                    forms: {
                                                        ...patch.forms,
                                                        parametros: {
                                                            ...patch.forms.parametros,
                                                            model: nuevoEstadoDatos
                                                        }
                                                    }
                                                });
                                            })
                                        )));
                        }

                    } else {
                        // IMPORTANTE!! este setState esta para cuando clickamos al mismo punto de menu y queremos que se borren los datos
                        // sino se quedarian con en el estado anterior puesto que no se destruye el modulo
                        // no hay que buscar, si no hay que buscar no puede haver iniciaFormEstado
                        this.nS.navigationService.remplazarRuta(this.currentUrl.split('?')[0])
                        context.setState(this.estadoInicial as T);
                    }

                    return of('');
                }));
    }


    cambiarParametros(context: StateContext<MantenimientoListaFichaStoreModel>, { payload }: MAcciones.cambiarParametros.Action) {
        const state = context.getState();

        const pay = this.getParameters(state, {
            orden: state.orden,
            numeroRegistros: state.paginacion.numeroRegistros,
            desplazamiento: state.paginacion.desplazamiento
        }, payload.parametrosModel);

        this.searchPayload = pay;
        context.dispatch(new NavigateAction({ route: [`${this.currentUrl.split('?')[0]}`], extras: { queryParams: pay } }))
    }


    buscar(context: StateContext<MantenimientoListaFichaStoreModel>, { payload }: MAcciones.buscar.Action) {
        const state = context.getState();

        payload = this.mountPayload(state, payload);

        context.patchState({
            ...state,
            busqueda: 'buscando',
            forms: {
                ...state.forms,
                form: {
                    ...inicialFormState(),
                }
            },
            orden: payload.lista.orden,
            indicadores: {
                indicesSeleccionados: [],
                todasSeleccionadas: false,
                unaSeleccionada: false,
                algunaSeleccionada: false
            }
        });

        const mod = payload && payload.parametrosModel ? payload.parametrosModel : undefined;
        const pay = this.getParameters(state, payload.lista, mod);
        this.searchPayload = pay;

        const formGroup = this.nS.getBuildFormGroup().build.formGroup;
        const isValid = payload ? (formGroup.disabled || formGroup.valid)
            : (state.forms.form.status === 'VALID' || state.forms.form.status === 'DISABLED');


        if (isValid) {

            const service = this.nS.getServicio<MEntityServiceDefault>();
            return service.buscar({ l: pay.l, q: pay.q })
                .pipe(
                    map(({ respuesta, numeroRegistros }) => ({
                        respuesta,
                        numeroRegistros,
                        model: this.fepS.backToFrontEntity(this.nS.getTipoEntidad(), { id: null, entidades: respuesta })
                    })),
                    map(({ model, numeroRegistros, respuesta }) => ({
                        respuesta,
                        numeroRegistros,
                        model: this.fepS.mapModelToOneEntityArrayEntity(this.bfo, 'form', model, this.estadoInicial.forms.form.model)
                    })),
                    tap(({ model, numeroRegistros }) => {
                        context.patchState({
                            ...context.getState(),
                            busqueda: 'busquedaRealizada',
                            forms: {
                                ...context.getState().forms,
                                form: {
                                    ...inicialFormState(),
                                    model
                                }
                            },
                            paginacion: {
                                desplazamiento: payload.lista.desplazamiento || 0,
                                numeroRegistros
                            },
                        });
                    }),
                    tap(() => {
                        const url = this.currentUrl.split('?')[0];
                        const qp = new QP().addDesdeObjeto(pay).formatear();
                        this.nS.navigationService.remplazarRuta(`${url}?${qp}`);
                    }),
                    finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion()))
                );
        } else {
            context.dispatch(this.noS.errorAlGuardar());
            return throwError(state.forms.form.errors);
        }

    }

    private mountPayload(state: any, payload: MAcciones.buscar.Payload) {

        if (!payload || !payload.lista) {
            payload = { ...payload, lista: { orden: null, numeroRegistros: null, desplazamiento: null } }
        }

        if (!payload.lista.orden) {
            payload.lista.orden = state.orden;
        }

        // si no especificamos un numero para el desplazamiento, cojemos el de aplicacion
        if (payload.lista.numeroRegistros === undefined || payload.lista.numeroRegistros === null) {
            payload.lista.numeroRegistros = this.nS.numeroFilasTabla;
        }

        if (!payload.lista.desplazamiento) {
            payload.lista.desplazamiento = 0;
        }

        return payload;

    }

    protected getParameters(state: any, lista: any, model?: { [key: string]: any }) {
        const form = state.forms.parametros;

        const pay: any = {};

        if (form || model) {
            Object.assign(pay, this.getQParameter(model ? { ...form.model, ...model } : form.model));
        }

        const l = QP.toB64QueryParamUrl(lista);
        if (l) {
            Object.assign(pay, { l })
        }

        const v = QP.toB64QueryParamUrl({
            titulo: state.titulo,
            parametrosModo: state.parametrosModo,
            vista: state.vista && Object.keys(state.vista).length ? state.vista : undefined
        });

        if (v) {
            Object.assign(pay, { v })
        }

        return pay;
    }

    private getQParameter(model: { [key: string]: any }) {
        let paramQ: object;

        const query: object = this.fepS.formControlToModeloParametrosBusqueda(
            this.nS.getTipoEntidad('parametros'), model);

        if (query) {
            const q = QP.toB64QueryParamUrl(query);
            if (q) {
                paramQ = { q };
            }
        }
        return paramQ || {};
    }


    /**
     * Alternar parametros
     */
    alternarModoParametros(context: StateContext<any>, _accion: MAcciones.alternarModoParametros.Action) {
        const state = context.getState();
        const actual = state.parametrosModo;
        const nuevo = this.nS.parametroService.alternarModo(actual);
        context.patchState({ ...state, parametrosModo: nuevo });
    }

    /**
     * Actalizar atos de tabla
     */
    actualizarTabla(context: StateContext<T>, { payload }: MAcciones.actualizarTabla.Action) {
        const state = context.getState();
        context.patchState({ ...state, ...payload });
    }


    /**
     * Guardar
     */
    guardar(context: StateContext<any>, accion: MAcciones.guardar.Action) {
        const estado = context.getState();

        const entidad = accion.payload && accion.payload.form
            ? accion.payload.form
            : estado.forms.form.model;

        const formGroup = this.nS.getBuildFormGroup().build.formGroup;

        const isValid = accion.payload ? formGroup.valid : estado.forms.form.status === 'VALID';

        if (isValid) {

            const entidadAGuardad = this.fepS
                .frontToBackEntity<any>(this.nS.getTipoEntidad(), entidad);

            return this.nS.getServicio<MEntityServiceDefault>().actualizar(entidadAGuardad.entidades)
                .pipe(
                    tap(() => context.dispatch(this.noS.guardadoCorrectamente())),
                    switchMap(() => context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: null }))
                        .pipe(
                            switchMap(() => this.nS.getServicio<MEntityService>().buscar(this.searchPayload)
                                .pipe(
                                    map(res => this.fepS
                                        .backToFrontEntity(this.nS.getTipoEntidad(),
                                            { id: null, entidades: res.respuesta })),
                                    tap(model => {
                                        const newState = {
                                            ...context.getState(),
                                            forms: {
                                                ...estado.forms,
                                                form: {
                                                    status: 'VALID',
                                                    dirty: false,
                                                    errors: {},
                                                    model: {
                                                        ...estado.forms.form.model,
                                                        entidades: (model as any).entidades
                                                    }
                                                }
                                            }
                                        }
                                        context.patchState(newState);
                                    }))))));

        } else {
            context.dispatch(this.noS.errorAlGuardar());

            const errors = {
                ...formGroup.errors,
                ...Object.entries(formGroup.controls)
                    .map(([k, v]) => [k, v.errors])
                    .filter(([_k, v]) => v)
                    .reduce((acc, cur) => ({ ...acc, [cur[0] as string]: cur[1] }), {})
            };

            context.patchState({
                ...estado,
                forms: {
                    ...estado.forms,
                    form: {
                        ...inicialFormState(),
                        model: estado.forms.form.model,
                        errors
                    }
                }
            });
            return throwError(errors);
        }
    }
}
