import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@fwk/components/button';
import { FWKTranslateModule } from '@fwk/translate';
import { ButtonAceptarDirective } from './button-aceptar.directive';
import { ButtonBuscarDirective } from './button-buscar.directive';
import { ButtonCancelarDirective } from './button-cancelar.directive';
import { ButtonCopiarDirective } from './button-copiar.directive';
import { ButtonEliminarDirective } from './button-eliminar.directive';
import { ButtonGuardarDirective } from './button-guardar.directive';
import { ButtonImprimirDirective } from './button-imprimir.directive';
import { ButtonNuevoDirective } from './button-nuevo.directive';
import { ButtonVolverDirective } from './button-volver.directive';
import { TablaEventosDirective } from './tabla-eventos.directive ';

const components = [
    ButtonEliminarDirective,
    ButtonGuardarDirective,
    ButtonVolverDirective,
    ButtonBuscarDirective,
    ButtonNuevoDirective,
    ButtonCancelarDirective,
    ButtonAceptarDirective,
    ButtonCopiarDirective,
    ButtonImprimirDirective,
    TablaEventosDirective
];

@NgModule({
    declarations: [
        components
    ],
    imports: [
        CommonModule,
        ButtonModule,
        FWKTranslateModule
    ],
    exports: [
        components
    ]
})
export class DirectivesModule { }
