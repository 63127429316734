import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule as AngularForms } from '@angular/forms';
import { MFormNameDirective } from './m-form.directive';
import { PermissionsAvoidDirective } from './permissions-avoid.directive';

@NgModule({
    declarations: [
        MFormNameDirective,
        PermissionsAvoidDirective
    ],
    imports: [
        CommonModule,
        AngularForms
    ],
    exports: [
        MFormNameDirective,
        PermissionsAvoidDirective
    ]
})
export class MFormsModule { }
