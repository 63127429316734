import { FWK_KEYSTORE_META, FWK_STATE_ACTION } from '../states/api';
import { _MInjector } from '../states/_m-injector';
import { mActionFactory } from './action-factory-builder';
/**
 * Metadato para injectar las funciones de las acciones
 */
export function Acciones(target: any, propiedad: string | symbol) {
    Reflect.defineProperty(target.constructor.prototype, propiedad, {
        configurable: true,
        enumerable: true,
        get: () => {
            const keyStore = target.constructor[FWK_KEYSTORE_META];
            const mNucleoService = _MInjector.getNucleoService(keyStore);
            return mActionFactory(mNucleoService.keyStore);
        }
    });
};


/**
 * Decorador para definir una accion
 */
export function AccionSt(opc: { type: string }) {
    return function _accionSt(target: any, propiedad: string, descriptor: PropertyDescriptor) {

        const array: {
            propiedad: string, type: string, descriptor: PropertyDescriptor, target: any
        }[] = Reflect.getMetadata(FWK_STATE_ACTION, target.constructor) || [];

        array.push({ propiedad, type: opc.type, descriptor, target });

        Reflect.defineMetadata(FWK_STATE_ACTION, array, target.constructor);
    }
}
