// tslint:disable: no-string-literal
import { Router } from '@angular/router';
import { MAcciones } from '@fwk/aplicacion';
import { MEntityServiceDefault } from '@fwk/common';
import { LlenarDatosAccion } from '@fwk/data'; // TODO: ACOPLE (crear interfaz de mapeo a traves del modulo)
import { NavigateAction } from '@fwk/navigation'; // TODO: ACOPLE (crear interfaz de mapeo a traves del modulo)
import { UpdateFormDirty } from '@ngxs/form-plugin';
import { StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { eliminarFilaListaFn, nuevaFilaListaFn } from '../common/list';
import { MostrarProcesandoGlobalAccion, OcultarProcesandoGlobalAccion } from '../subestados/procesando-global.state';
import { inicialFormState, MantenimientoFichaStoreModel } from './api';
import { MState } from './m-state';
import { _MInjector } from './_m-injector';

export class MNewDetailState<T extends MantenimientoFichaStoreModel = MantenimientoFichaStoreModel> extends MState {

    /**
     * Iniciar ficha
     */
    iniciar(context: StateContext<T>, { payload }: MAcciones.iniciar.Action) {
        this.resetBuilder();
        this.initState(payload);
        this.nS.urlParams = payload.urlParams;

        // TODO: unificar con detail-state
        return context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: payload.urlParams }))
            .pipe(
                map(() => this.nS.getBuildFormGroup()),
                map(() => (this.fepS.backToFrontEntity(this.entityParse,
                    { ...this.estadoInicial.forms.form.model }, []))),
                mergeMap(res => this.dps.mapDatosEnEntidad(res)),
                tap(model => {
                    const state = context.getState();
                    context.patchState({
                        ...this.estadoInicial,
                        modo: 'nuevo',
                        permisos: this.nS.permission,
                        forms: {
                            ...state.forms,
                            form: {
                                ...inicialFormState(),
                                model
                            }
                        }
                    });
                }));
    }


    /**
     * Guardar
     */
    guardar(context: StateContext<T>, { payload }: MAcciones.guardar.Action) {
        const state = context.getState();

        const entidad = payload && payload.form ? payload.form : state.forms.form.model;

        const isValid = state.forms.form.status === 'VALID';

        if (isValid) {
            context.dispatch(new MostrarProcesandoGlobalAccion());
            const entidadAinsertar = this.fepS.frontToBackEntity(payload?.tipoEntitdad || this.nS.getTipoEntidad(), entidad);

            return (payload?.servicio || this.nS.getServicio<MEntityServiceDefault>()).insertar(entidadAinsertar,
                this.urlParams?.param ? { before: this.urlParams.param } : null)
                .pipe(
                    finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion())),
                    catchError(err => {
                        let sms = err.error.mensaje as string;
                        if (this.keyStore) {
                            context.dispatch(new UpdateFormDirty({ path: `${this.keyStore}.forms.form`, dirty: false }));
                        }
                        context.patchState({ ...state, forms: { ...state.forms, form: { ...state.forms.form, dirty: false } } });
                        return throwError({ ...err, error: { ...err.error, mensaje: sms } });
                    }),
                    tap(() => context.dispatch(this.noS.guardadoCorrectamente())),
                    tap(({ respuesta }) => {
                        if (!payload?.noNavegarNuevaId) {
                            const router = _MInjector.get<Router>(Router)
                            const url = router.url.substring(0, router.url.lastIndexOf('/'));
                            context.dispatch(new NavigateAction({ route: [`${url}/${respuesta.id}`] }));
                        }
                    }))

        } else {
            context.dispatch(this.noS.errorAlGuardar());
            return throwError(state.forms.form.errors);
        }
    }


    // TODO: poner en comun con las funcionalidades de tabla
    // TODO: poner en comun con las funcionalidades de tabla
    // TODO: poner en comun con las funcionalidades de tabla

    /**
     * Nueva fila tabla editable
     */
    nuevaFilaLista(context: StateContext<T>, action: MAcciones.nuevaFilaLista.Action) {
        return nuevaFilaListaFn(context, action, this.nS.getBuildFormGroup().build);
    }

    /**
     * Eliminar fila tabla editable
     */
    eliminarFilaLista(context: StateContext<T>, action: MAcciones.eliminarFilaLista.Action) {
        return eliminarFilaListaFn<T>(context, action, this.nS.getBuildFormGroup());
    }
}
