import { DisableControlService } from '@fwk/forms';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { mActionFactory } from '../actions/action-factory-builder';
import { MSelector } from '../selectors/selector';
import { MNucleoService } from '../states/m-nucleo.service';
import { _MInjector } from '../states/_m-injector';

export class MHtmlComponent {

    private _textoTitulo$?: Observable<string>;

    get textoTitulo$() {
        return this._textoTitulo$;
    }

    private _argumentosTitulo$?: Observable<string[]>;

    get argumentosTitulo$() {
        return this._argumentosTitulo$;
    }

    private _prefijoTituloTexto$?: Observable<string>;

    get prefijoTituloTexto$() {
        return this._prefijoTituloTexto$;
    }

    eventoTabla: any;

    isChangeState = false;

    onDestroy$ = new Subject<void>();

    constructor(
        protected store: Store,
        public mNucleoService: MNucleoService,
        protected disableControlService: DisableControlService) {
    }

    protected createCommonSelectors(keyStore: string) {
        this._textoTitulo$ = this.store.select(MSelector.selectTextoTitulo(keyStore));
        this._argumentosTitulo$ = this.store.select(MSelector.selectArgumentosParametrosTitulo(keyStore));
        this._prefijoTituloTexto$ = this.store.select(MSelector.selectModo(keyStore));
    }

    protected fwkOnDestroy(keyStore: string, nodel?: boolean) {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.store.dispatch(mActionFactory(keyStore).resetEstadoAccion());
        this.disableControlService.destroy();
        if (!nodel) {
            _MInjector.delNucleoService(keyStore);
        }
    }
}
