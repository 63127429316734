import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { ButtonDirective } from '@fwk/components/button';
import { NavigateBackAction } from '@fwk/navigation';
import { TranslateService } from '@fwk/translate';
import { Store } from '@ngxs/store';

@Directive({
    selector: '[fButtonVolver]',
})
export class ButtonVolverDirective extends ButtonDirective implements AfterViewInit {

    constructor(
        private store: Store,
        public elementRef: ElementRef<any>,
        public renderer: Renderer2,
        public multilenguajeService: TranslateService) {
        super(elementRef, renderer, multilenguajeService);
        this.label = 'volver';
        this.icon = 'pi pi-chevron-left';
        this.traducirLabel = true;
    }

    fwkClick() {
        this.store.dispatch(new NavigateBackAction());
    }
}
