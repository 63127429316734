import { Type } from '@angular/core';
import { BuildFormEntity } from '@fwk/forms';

/**
 * Namespace con las acciones
 */
export namespace MAcciones {

    //  es el general , deberan unir todos en este al final y quitar los demas
    export namespace iniciar {
        export type Payload = {
            url: string;
            urlParams: any;
            urlQueryParams: any;
            entity?: Type<any>;
        }
        export class Action {
            static readonly type = 'Iniciar estado';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace setTitulo {
        export type Payload = {
            titulo: {
                texto: string;
                traducir?: boolean;
                argumentosParametro?: string[];
            }
        }
        export class Action {
            static readonly type = 'Set titulo';
            constructor(public payload: Payload) { }
        }
    }

    export namespace resetEstado {
        export class Action {
            static readonly type = 'Reset estado';
        }
    }

    export namespace resetForm {
        export type Payload = {
            // TODO crear accion resetParametros y quitar
            nombreForm?: 'parametros';
            status?: 'VALID' | 'INVALID';
        };
        export class Action {
            static readonly type = 'Reset form';
            constructor(public payload: Payload) { }
        }
    }

    export namespace eliminarEstado {
        export class Action {
            static readonly type = 'Eliminar estado';
        }
    }

    export namespace disabled {
        export type Payload = {
            clave: { [key: string]: boolean };
        }
        export class Action {
            static readonly type = 'Disabled';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace visible {
        export type Payload = {
            clave: { [key: string]: boolean };
        }
        export class Action {
            static readonly type = 'Visible';
            constructor(public payload?: Payload) { }
        }
    }

    // lista

    export namespace buscar {
        export type Payload = {
            parametrosModel?: { [key: string]: any };
            resetBusqueda?: boolean;
            lista?: {
                orden: {
                    campo: string;
                    tipo: 'asc' | 'desc';
                }[];
                desplazamiento: number;
                numeroRegistros?: number;
            }
        }
        export class Action {
            static readonly type = 'Buscar';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace cambiarParametros {
        export type Payload = {
            parametrosModel: { [key: string]: any };
        }
        export class Action {
            static readonly type = 'Cambiar parametros';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace buscarPorId {
        export type Payload = {
            id: any;
        }
        export class Action {
            static readonly type = 'Buscar por id';
            constructor(public payload: Payload) { }
        }
    }

    export namespace alternarModoParametros {
        export class Action {
            static readonly type = 'Alternar modo parametros';
        }
    }

    export namespace filaSeleccionada {
        export type Payload = {
            entidades: any[];
        }
        export class Action {
            static readonly type = 'Fila seleccionada';
            constructor(public payload: Payload) { }
        }
    }

    export namespace eliminarSeleccionados {
        export type Payload = {
            ids: any[];
        }
        export class Action {
            static readonly type = 'Eliminar seleccionados';
            constructor(public payload: Payload) { }
        }
    }

    export namespace actualizarTabla {
        export type Payload = {
            orden: {
                campo: string;
                tipo: 'asc' | 'desc';
            }[];
            paginacion: {
                desplazamiento: number;
                numeroRegistros: number;
                numeroRegistrosTotal: number;
            };
        }
        export class Action {
            static readonly type = 'ActualizarTabla';
            constructor(public payload: Payload) { }
        }
    }


    // ficha

    export namespace guardar {
        export type Payload = {
            form?: object;
            volverAlGuardar?: boolean;
            noNavegarNuevaId?: boolean;
            tipoEntitdad?: Type<any>;
            servicio?: any;
            mapForm?: (value: any) => any;
        }
        export class Action {
            static readonly type = 'Guardar';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace insertar {
        export type Payload = {
            entidad: any;
            volverAlGuardar?: boolean;
        }
        export class Action {
            static readonly type = 'Insertar';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace actualizar {
        export type Payload = {
            entidad: any;
            volverAlGuardar?: boolean;
        }
        export class Action {
            static readonly type = 'Actualizar';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace eliminar {
        export type Payload = {
            tipoEntitdad?: Type<any>;
            servicio?: any;
            id?: any;
        }
        export class Action {
            static readonly type = 'Eliminar';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace nuevaFilaLista {
        export type Payload = {
            buildFormEntity?: BuildFormEntity;
            formArrayName: string;
            beforeFormArrays?: { formArrayName: string, index: number }[],
            options?: { formArrayValues?: any[]; }
        }
        export class Action {
            static readonly type = 'Nueva fila lista';
            constructor(public payload?: Payload) { }
        }
    }

    export namespace eliminarFilaLista {
        export type Payload = {
            buildFormEntity?: BuildFormEntity;
            formArrayName: string;
            options: { index: number[]; };
        }
        export class Action {
            static readonly type = 'Eliminar fila lista';
            constructor(public payload?: Payload) { }
        }
    }
}
