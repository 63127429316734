import { Inject, Injectable, Type } from '@angular/core';
import { MFormEntityParseService } from '@fwk/forms';
import { ControlType, DynamicEntityTypes, DynamicTypes, DYNAMIC_ENTITIES } from './api';


@Injectable()
export class DynamicEntityService {

    constructor(
        @Inject(DYNAMIC_ENTITIES) private dynamicEntities: DynamicTypes[],
        private mFormEntityParseService: MFormEntityParseService
    ) { }

    getControlsEntity(entityName: string, filterfields: string[] = []) {
        const en = this.getEntity(entityName);
        const controls = this.getControls(en.entity).filter(c => !filterfields.includes(c.key));
        return { controls, extras: en.extras };
    }

    getEntity(entityName: string) {
        const entity = this.dynamicEntities
            .filter(e => typeof e === 'function' ? (e as any).name === entityName : e.entity.name === entityName)
            .map(e => typeof e === 'function' ? { entity: (e as any), extras: null } as DynamicEntityTypes : e);

        if (entity.length > 0) {
            return entity[0];
        } else {
            throw new Error(`Entity ${entityName} not found`);
        }
    }

    private getControls<T>(entity: Type<T>): ControlType[] {
        const types = this.mFormEntityParseService.getTypes(entity);
        return types.map(t => {
            let type;
            switch (t.fc.type) {
                case 'booleano':
                    type = 'checkbox';
                    break;
                case 'numero':
                    type = 'spinner';
                    break;
                case 'texto':
                    type = 'input';
                    break;
                case 'json':
                    type = 'ml';
                    break;
                case 'arrayObjetoId':
                    type = 'array';
                    break;
                default:
                    type = null;
            }
            return { key: t.key, type }
        });
    }
}
