import { Inject, Injectable, Injector, Type } from '@angular/core';
import { Router } from '@angular/router';
import { BuildFormObject, crearFormBuildObject } from '@fwk/forms';
import { NavigationService } from '@fwk/navigation';
import { AplicacionService } from '../../aplicacion.service';
import { ParametrosService } from '../components/parametros/parametros.service';
import { SelectPermissions } from '../selectors/selector';
import { FormBuildArguments, M_CLAVE, M_ESTADO_INICIAL, M_FORM_BUILD_ARGUMENTS, M_SERVICE, M_STATE_OPC, M_TYPE_STATE, StateType } from './api';
import { getEstadoInicial } from './state-decorators';

@Injectable()
export class MNucleoService {

    build: { [key: string]: BuildFormObject };

    buildArgs: any;

    urlParams?: { [key: string]: string };

    permission: string;

    constructor(
        @Inject(M_CLAVE) public keyStore: string,
        @Inject(M_ESTADO_INICIAL) public estadoInicial: any,
        @Inject(M_FORM_BUILD_ARGUMENTS) private mFormBuildArguments: FormBuildArguments,
        @Inject(M_STATE_OPC) public opc: any,
        @Inject(M_TYPE_STATE) public typeState: any,
        @Inject(M_SERVICE) public service: any,
        private aplicacionService: AplicacionService,
        public parametroService: ParametrosService,
        public navigationService: NavigationService,
        public router: Router,
        private injector: Injector,
    ) { }

    isGetPermissions() {
        const permisos = this.getPermissions();
        return SelectPermissions.selectIsGet({ permisos });
    }

    isWritePermissions() {
        return this.isPostPermissions() || this.isPutPermissions();
    }

    isPostPermissions() {
        const permisos = this.getPermissions();
        return SelectPermissions.selectIsPost({ permisos });
    }

    isPutPermissions() {
        const permisos = this.getPermissions();
        return SelectPermissions.selectIsPut({ permisos });
    }

    isDeletePermissions() {
        const permisos = this.getPermissions();
        return SelectPermissions.selectIsDelete({ permisos });
    }

    private getPermissions(): string {
        return this.permission != null ? this.permission : '0000';
    }

    createBuildFormGroup(entity: Type<any>, type: StateType, initialSate: any, disableAllRequired?: boolean) {
        const estadoInicial = getEstadoInicial(this.opc, initialSate)
        if (this.build == null) {
            this.buildArgs = entity != null ? [{
                tipoEntidad: entity,
                desactivarRequerido: disableAllRequired,
            }] : this.mFormBuildArguments.serviciosEntidad;
            this.build = crearFormBuildObject(this.buildArgs, estadoInicial.forms);

        } else if (type !== 'listdetail') {
            this.build = crearFormBuildObject(this.buildArgs, estadoInicial.forms);
        }
    }

    getFormGroup(nombreForm?: 'parametros') {
        const build = this.build[nombreForm || 'form'];
        if (build == null) {
            throw new Error(`No se ecuentra el formGroup ${nombreForm || 'form'}`);
        }
        return build.build.formGroup;
    }

    getBuildFormGroup(nombreForm?: 'parametros') {
        const fb = this.build[nombreForm || 'form'];
        if (fb == null) {
            throw new Error(`No se encuentra el BuildFormGroup con nombre ${nombreForm || 'form'}`);
        }
        return fb;
    }

    getTipoEntidad<E>(nombreForm?: 'parametros'): Type<E> {
        const form = this.build[nombreForm || 'form'];
        if (form == null) {
            throw new Error(`No se encuentra el form con nombre ${nombreForm || 'form'}`);
        }
        return form.tipoEntidad;
    }

    // get currentUrl() {
    //     return this.router.url;
    // }

    get numeroFilasTabla(): number {
        return this.aplicacionService.getConfig('numeroFilasTabla');
    }

    get volverAlGuardar(): boolean {
        return this.aplicacionService.getConfig('volverAlGuardar');
    }

    get iconoFormModificado(): string {
        return this.aplicacionService.getConfig('iconoFormModificado');
    }

    get iconoRegistroEnUso(): string {
        return this.aplicacionService.getConfig('iconoRegistroEnUso');
    }

    getServicio<T>() {
        if (!this.service) {
            throw new Error('Service is null');
        }
        return this.injector.get<T>(this.service);
    }
}
