import { Injectable } from '@angular/core';
import { MantSv, MService, Orden } from '@fwk/aplicacion';
import { MEntityServiceDefault, QP } from '@fwk/common';
import { LlenarDatos, LLenarDatosArgs } from '@fwk/data';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
@MService()
export class MantenimientoDinamicoService implements LlenarDatos {

    @MantSv
    sv!: MEntityServiceDefault

    llenarDatos(args: LLenarDatosArgs) {
        return this.datoDinamico(args.extras.endpoint, args.extras.orden, args.extras.ruta)
            .pipe(map(res => res.respuesta));
    }

    private datoDinamico<T>(endpoint: string, orden?: { campo: keyof T, tipo: Orden }[], ruta?: string) {
        const parametros: { l?: string } = QP.toQueryParamParUrl([{
            clave: 'l',
            objeto: {
                orden: orden || [
                    {
                        campo: 'codigo',
                        tipo: 'asc'
                    }
                ]
            }
        }]);
        return this.sv.buscar<T>(orden ? parametros : null, {
            before: endpoint,
            ruta: ruta ? "/" : null,
            rutaOverride: ruta
        });
    }
}
