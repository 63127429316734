import { NgModule } from '@angular/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { environment } from 'environment';

@NgModule({
    imports: [
        NgxsDispatchPluginModule.forRoot(),
        NgxsSelectSnapshotModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.produccion,
            maxAge: 20
        }),
        NgxsStoragePluginModule.forRoot({
            key: ['autenticacion'],
            storage: StorageOption.SessionStorage
        }),
        NgxsFormPluginModule.forRoot(),
    ]
})
export class AppNgxsModule { }
