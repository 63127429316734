import { registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule, Provider, Type } from '@angular/core';
import {
    DefaultErrorClienteHandler, DefaultErrorResponseHandler, DefaultErrorServidorHandler,
    ErrorResponseHandler
} from '@fwk/common';
import { Paginas, URL_PAGINAS } from '@fwk/components/static-page';
import { Store } from '@ngxs/store';
import { AppConfigInit, ARCHIVO_CONFIG_URL, ARCHIVO_PACKAGE_JSON, IniciarAplicacionAccion } from './api';
import { ErrorHttpInterceptor } from './core/error/error-http.interceptor';
import { _MInjector } from './core/states/_m-injector';
registerLocaleData(localeEs);

@NgModule()
export class AplicacionModule {
    static forRoot(opc: {
        menuUrl: string;
        nombreApp: string;
        iconoFormModificado?: { dep: any, func: (dep: any) => string } | string;
        paginas: Paginas;
        archivoPackageJson?: any,
        archivoConfigUrl: string,
        entorno: string;
        produccion: boolean;
        errorResponseHandler?: Type<ErrorResponseHandler>;
    }) {
        return {
            ngModule: AplicacionModule,
            providers: [
                // pasar dinamicamente despues de seleccionar el idioma
                { provide: LOCALE_ID, useValue: 'es-ES' },
                ...AplicacionModule.erroresProviders(),
                AplicacionModule.iniciarAplicacionProvider({ nombreApp: opc.nombreApp, menuUrl: opc.menuUrl },
                    opc.entorno),
                {
                    provide: URL_PAGINAS,
                    useValue: opc.paginas
                },
                {
                    provide: ARCHIVO_CONFIG_URL,
                    useValue: opc.archivoConfigUrl,
                    multi: false
                },
                {
                    provide: ARCHIVO_PACKAGE_JSON,
                    useValue: opc.archivoPackageJson
                },
                opc.errorResponseHandler ? {
                    provide: ErrorResponseHandler,
                    useClass: opc.errorResponseHandler
                } : {
                    provide: ErrorResponseHandler,
                    useClass: DefaultErrorResponseHandler
                },
                HttpClient
            ]
        };
    }

    private static iniciarAplicacionProvider(appConfigInit: AppConfigInit, entorno: string): Provider {
        const factory = (store: Store) => () => {
            store.dispatch(new IniciarAplicacionAccion({ appConfigInit, entorno, }));
        }
        return {
            provide: APP_INITIALIZER,
            useFactory: factory,
            deps: [Store],
            multi: true
        };
    }

    private static erroresProviders(defaultErrorResponseHandler?: Type<ErrorResponseHandler>): Provider[] {
        return [
            DefaultErrorServidorHandler,
            DefaultErrorClienteHandler,
            defaultErrorResponseHandler ? {
                provide: ErrorResponseHandler,
                useClass: defaultErrorResponseHandler
            } : {
                provide: ErrorResponseHandler,
                useClass: DefaultErrorResponseHandler
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: ErrorHttpInterceptor,
                multi: true
            }
        ]
    }

    constructor(injector: Injector) {
        _MInjector.set(injector);
    }
}
