import { ChangeDetectorRef, Directive, forwardRef, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { UpdateForm, UpdateFormDirty, UpdateFormErrors, UpdateFormStatus, UpdateFormValue } from '@ngxs/form-plugin';
import { getValue, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { getAllErrors } from '../common/errors';
import { MNucleoService } from '../states/m-nucleo.service';

@Directive({
    selector: '[fFormName]',
    providers: [
        {
            provide: ControlContainer,
            useExisting: forwardRef(() => FormGroupDirective)
        }
    ]
})
export class MFormNameDirective implements OnInit, OnDestroy {


    @Input('fFormName') formName: string;

    @Input() form = 'form';

    @Input() forms = 'forms';

    private keyForm: string;

    private debounce = 100;

    private clearDestroy = false;

    private readonly _destroy$ = new Subject<void>();

    private _updating = false;

    private inicilized = false;

    constructor(
        @Optional() private mNucleoService: MNucleoService,
        private store: Store,
        private formGroupDirective: FormGroupDirective,
        private cd: ChangeDetectorRef,
    ) { }

    ngOnInit() {

        this.keyForm = this.formName ? this.formName : this.mNucleoService.keyStore;
        if (this.keyForm) {
            this.keyForm = this.keyForm + '.' + this.forms + '.' + this.form;
        } else {
            this.keyForm = this.forms + '.' + this.form;
        }

        this.getStateStream(`${this.keyForm}.model`)
            .subscribe(model => {
                if (this._updating || !model) {
                    return;
                }
                this.formGroupDirective.form.patchValue(model);
                this.cd.markForCheck();
            });

        this.getStateStream(`${this.keyForm}.dirty`)
            .subscribe(dirty => {
                if (this.formGroupDirective.form.dirty === dirty || typeof dirty !== 'boolean') {
                    return;
                }
                if (dirty) {
                    this.formGroupDirective.form.markAsDirty();
                } else {
                    this.formGroupDirective.form.markAsPristine();
                }
                this.cd.markForCheck();
            });

        this.store.selectOnce(state => getValue(state, this.keyForm))
            .subscribe(() => {
                if (this.inicilized) {
                    this.store.dispatch([
                        new UpdateFormValue({
                            path: this.keyForm,
                            value: this.formGroupDirective.form.getRawValue()
                        }),
                        new UpdateFormStatus({
                            path: this.keyForm,
                            status: this.formGroupDirective.form.status
                        }),
                        new UpdateFormDirty({
                            path: this.keyForm,
                            dirty: this.formGroupDirective.form.dirty
                        })
                    ]);
                }
            });

        this.getStateStream(`${this.keyForm}.disabled`)
            .subscribe(disabled => {
                if (this.formGroupDirective.form.disabled === disabled || typeof disabled !== 'boolean') {
                    return;
                }
                if (disabled) {
                    this.formGroupDirective.form.disable();
                } else {
                    this.formGroupDirective.form.enable();
                }
                this.cd.markForCheck();
            });

        this.formGroupDirective.valueChanges
            .pipe(this.debounceChange())
            .subscribe(() => {

                if (this.inicilized) {
                    const value = this.formGroupDirective.control.getRawValue();
                    this._updating = true;

                    const errors = getAllErrors(this.formGroupDirective.form.controls);

                    this.store.dispatch([
                        new UpdateFormValue({
                            path: this.keyForm,
                            value
                        }),
                        new UpdateFormDirty({
                            path: this.keyForm,
                            dirty: this.formGroupDirective.dirty
                        }),
                        new UpdateFormErrors({
                            path: this.keyForm,
                            errors
                        })
                    ]).subscribe({
                        error: () => (this._updating = false),
                        complete: () => (this._updating = false)
                    });
                }
            });

        this.formGroupDirective.statusChanges
            .pipe(
                distinctUntilChanged(),
                this.debounceChange())
            .subscribe((status: string) => {
                if (this.inicilized) {
                    this.store.dispatch(
                        new UpdateFormStatus({
                            status,
                            path: this.keyForm
                        }));
                }
            });

        this.inicilized = true;
    }


    ngOnDestroy() {

        this._destroy$.next();
        this._destroy$.complete();

        if (this.clearDestroy) {
            this.store.dispatch(
                new UpdateForm({ path: this.keyForm, value: null, dirty: null, status: null, errors: null }));
        }
    }

    private debounceChange() {
        const skipDebounceTime =
            this.formGroupDirective.control.updateOn !== 'change' || this.debounce < 0;

        return skipDebounceTime
            ? (change: Observable<any>) => change.pipe(takeUntil(this._destroy$))
            : (change: Observable<any>) =>
                change.pipe(
                    debounceTime(this.debounce),
                    takeUntil(this._destroy$)
                );
    }

    private getStateStream(path: string) {
        return this.store.select(state => getValue(state, path)).pipe(takeUntil(this._destroy$));
    }
}
