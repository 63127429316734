// tslint:disable: no-string-literal
import { MAcciones } from '@fwk/aplicacion';
import { LlenarDatosAccion } from '@fwk/data'; // TODO: ACOPLE (crear interfaz de mapeo a traves del modulo)
import { NavigateBackAction } from '@fwk/navigation';
import { StateContext } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { MStateService } from '../services/service-decorators';
import { MostrarProcesandoGlobalAccion, OcultarProcesandoGlobalAccion } from '../subestados/procesando-global.state';
import { inicialFormState, MStoreModel } from './api';
import { MState } from './m-state';

export class MEmptyState<T extends MStoreModel> extends MState {

    /**
     * Iniciar ficha
     */
    iniciar(context: StateContext<T>, { payload }: MAcciones.iniciar.Action) {
        this.initState(payload);
        this.nS.urlParams = payload.urlParams;

        return context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: payload.urlParams }))
            .pipe(
                map(() => new this.entityParse),
                switchMap(model => this.nS.getServicio<MStateService>().iniciar(model)),
                // TODO: TEST DATOS OBSERVABLE
                mergeMap(({ model, llenarDatosField }) => this.dps.mapDatosEnEntidad(model, llenarDatosField)),
                map(model => ({
                    ...this.estadoInicial,
                    permisos: this.nS.permission,
                    forms: {
                        ...this.estadoInicial.forms,
                        ['form']: {
                            ...inicialFormState(),
                            model
                        }
                    }
                })),
                tap(state => {
                    context.patchState({
                        ...state,
                        modo: 'empty'
                    });
                }));
    }


    /**
     * Guardar
     */
    guardar(context: StateContext<T>, accion: MAcciones.guardar.Action) {

        const estado = context.getState();


        const entidad = accion.payload && accion.payload.form
            ? accion.payload.form
            : estado.forms.form.model;

        const volverAlGuardar = accion.payload && accion.payload.volverAlGuardar !== undefined
            ? accion.payload.volverAlGuardar
            : this.nS.volverAlGuardar;

        const formGroup = this.nS.getBuildFormGroup().build.formGroup;

        const isValid = accion.payload ? formGroup.valid : estado.forms.form.status === 'VALID';
        if (isValid) {
            context.dispatch(new MostrarProcesandoGlobalAccion());
            return of(entidad)
                .pipe(
                    tap(() => this.noS.guardadoCorrectamente()),
                    tap(() => {
                        if (volverAlGuardar) {
                            context.dispatch(new NavigateBackAction());
                        }
                    }),
                    tap(() => {
                        context.dispatch(new OcultarProcesandoGlobalAccion());
                    }));
        } else {
            context.dispatch(this.noS.errorAlGuardar());

            const errors = {
                ...formGroup.errors,
                ...Object.entries(formGroup.controls)
                    .map(([k, v]) => [k, v.errors])
                    .filter(([_k, v]) => v)
                    .reduce((acc, cur) => ({ ...acc, [cur[0] as string]: cur[1] }), {})
            };

            context.patchState({
                ...estado,
                forms: {
                    ...estado.forms,
                    form: {
                        ...inicialFormState(),
                        model: estado.forms.form.model,
                        errors
                    }
                }
            });

            return throwError(errors);
        }
    }
}
