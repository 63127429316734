import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ButtonDirective } from '@fwk/components/button';
import { TranslateService } from '@fwk/translate';

@Directive({
    selector: '[fButtonCopiar]',
})
export class ButtonCopiarDirective extends ButtonDirective implements OnInit {

    @Input() isWritePermissions?: boolean = true;

    constructor(
        public elementRef: ElementRef<any>,
        public renderer: Renderer2,
        public multilenguajePiep: TranslateService) {
        super(elementRef, renderer, multilenguajePiep);
        this.icon = 'pi pi-copy';
        this.label = 'copiar';
        this.traducirLabel = true;
        this.personalizar = true;
    }

    ngOnInit(): void {
        if (!this.isWritePermissions) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        }
    }

}
