import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule as FormsModuleAngular, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BotoneraModule } from '@fwk/components/botonera';
import { ButtonFlotanteModule } from '@fwk/components/button-flotante';
import { ContainerModule } from '@fwk/components/container';
import { TabViewModule } from '@fwk/components/tab-view';
import { FWKTranslateModule } from '@fwk/translate';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { StateClass } from '@ngxs/store/internals/src/symbols';
import { ChildrenModule } from '../components/children.module';
import { MFichaHtmlComponent } from '../components/ficha-html.component';
import { MListaHtmlComponent } from '../components/lista-html.component';
import { MFormsModule } from '../components/m-forms.module';
import { MResolve, mResolveFactory } from '../resolver/resolvers';
import {
    FWK_FORM_BUILD_ARGUMENTS_META,
    FWK_KEYSTORE_META, FWK_STORE_OPC, FWK_STORE_TYPE_META, MComponent, M_CLAVE,
    M_COMPONENT, M_ESTADO_INICIAL,
    M_FORM_BUILD_ARGUMENTS,
    M_RESOLVER, M_SERVICE, M_STATE, M_STATE_OPC, M_TYPE_STATE, NGXS_OPTIONS_META, StateType
} from './api';
import { MNucleoService } from './m-nucleo.service';
import { _MInjector } from './_m-injector';

@NgModule({
    declarations: [
        MListaHtmlComponent,
        MFichaHtmlComponent
    ],
    imports: [
        CommonModule,
        FormsModuleAngular,
        ReactiveFormsModule,
        NgxsFormPluginModule,
        FWKTranslateModule,
        ButtonFlotanteModule,
        BotoneraModule,
        ContainerModule,
        TabViewModule,
        ChildrenModule,
        MFormsModule,
        NgxsModule
    ],
    exports: [
        MListaHtmlComponent,
        MFichaHtmlComponent,
        MFormsModule
    ]
})
export class MModule {
    static forFeature(opc: {
        estado: StateClass<any>,
        estadosHijos?: StateClass<any>[],
        component: MComponent,
        resolver: MResolve,
        service: any,
    }): ModuleWithProviders<MModule>[] {
        return [
            {
                ngModule: MModule,
                providers: [
                    {
                        provide: M_STATE,
                        useValue: opc.estado
                    },

                    {
                        provide: M_COMPONENT,
                        useValue: opc.component
                    },
                    {
                        provide: M_SERVICE,
                        useValue: opc.service ? opc.service : null
                    },
                    {
                        provide: M_RESOLVER,
                        useValue: opc.resolver
                    },
                    {
                        provide: M_STATE_OPC,
                        useFactory: MModule.estadoOpcFactory,
                        deps: [M_STATE]
                    },
                    {
                        provide: M_CLAVE,
                        useFactory: MModule.claveFactory,
                        deps: [opc.estado, M_COMPONENT, M_RESOLVER]
                    },
                    {
                        provide: M_FORM_BUILD_ARGUMENTS,
                        useFactory: MModule.formBuildArguments,
                        deps: [M_STATE]
                    },
                    {
                        provide: M_ESTADO_INICIAL,
                        useFactory: MModule.estadoInicialFactory,
                        deps: [M_STATE]
                    },
                    {
                        provide: M_TYPE_STATE,
                        useFactory: MModule.stateTypeFactory,
                        deps: [M_STATE]
                    },
                    MNucleoService,
                    {
                        provide: opc.resolver,
                        useFactory: MModule.resolveFactory,
                        deps: [M_RESOLVER, M_CLAVE, M_TYPE_STATE, M_ESTADO_INICIAL, MNucleoService]
                    }
                ]
            },
            NgxsModule.forFeature([opc.estado].concat(opc.estadosHijos || [])),
            RouterModule.forChild([{
                path: '',
                component: opc.component,
                resolve: { datosEstado: opc.resolver },
                runGuardsAndResolvers: 'always'
            }])
        ];
    }


    static estadoOpcFactory(state: StateClass) {
        const stateOpc = state[FWK_STORE_OPC];
        if (!stateOpc) {
            throw new Error('M_STATE_OPC is null')
        }
        return stateOpc;
    }

    static estadoInicialFactory(state: StateClass) {
        const ngxState = state[NGXS_OPTIONS_META];
        if (!ngxState) {
            throw new Error('NGXS_OPTIONS_META is null')
        }
        return ngxState.defaults;
    }

    static formBuildArguments(state: StateClass) {
        const buildFormObject = state[FWK_FORM_BUILD_ARGUMENTS_META];
        if (!buildFormObject) {
            throw new Error(`${FWK_FORM_BUILD_ARGUMENTS_META} is null`);
        }
        return buildFormObject;
    }

    static claveFactory(state: StateClass, component: MComponent, resolve: MResolve) {
        const keyStore = state.constructor[NGXS_OPTIONS_META].name;
        if (!keyStore) {
            throw new Error(`Las clave es nula`);
        }
        Reflect.defineProperty(resolve, FWK_KEYSTORE_META, {
            configurable: false,
            enumerable: true,
            value: keyStore
        });
        Reflect.defineProperty(component, FWK_KEYSTORE_META, {
            configurable: false,
            enumerable: true,
            value: keyStore
        });
        return keyStore;
    }

    static stateTypeFactory(state: StateClass) {
        const type = state[FWK_STORE_TYPE_META];
        if (!type) {
            throw new Error('FWK_STORE_TYPE_META is null')
        }
        return type;
    }

    static resolveFactory(resolve: MResolve, keyStore: string, stateType: StateType, initialSate: any, nucleoService: MNucleoService) {
        mResolveFactory(resolve, keyStore, stateType, initialSate, nucleoService);
        return new resolve();
    }

    constructor(injector: Injector) {
        _MInjector.set(injector);
    }
}
