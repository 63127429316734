import { Type } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MEntityServiceDefault, StringUtils } from '@fwk/common';
import { Store } from '@ngxs/store';
import { finalize, map, switchMap } from 'rxjs/operators';
import { DynamicEntityTypes } from 'vistas/mantenimiento-dinamico/api';
import { DynamicEntityService } from 'vistas/mantenimiento-dinamico/dynamic-entity.service';
import { mActionFactory } from '../actions/action-factory-builder';
import { StateType } from '../public-api';
import { MNucleoService } from '../states/m-nucleo.service';
import { _MInjector } from '../states/_m-injector';
import { MostrarProcesandoGlobalAccion, OcultarProcesandoGlobalAccion } from '../subestados/procesando-global.state';

export declare type MResolve<T = any> = new (...args: any[]) => T;

// export function searchUrl(menu: MenuItemMo[], url: string): MenuItemMo {
//     for (const m of menu) {
//         if (m.routerLink?.includes(url)) {
//             return m;
//         }
//         if (m.items && m.items.length > 0) {
//             const search = searchUrl(m.items, url);
//             if (search) {
//                 return search;
//             }
//         }
//     }
//     return null;

// }

// estos parametros se pasan directamente en el momento de declarar el modulo
export function mResolveFactory<T extends new (...args: any[]) => {}>(constructor: T, keyStore: string, stateType: StateType,
    initialSate: any, nucleoService: MNucleoService) {

    // dentro del  resolver tendre acceso a los servicios injectados mediante constructor...

    if (stateType === 'list') {
        const id = _MInjector.setNucleoService(nucleoService, keyStore);
        nucleoService.createBuildFormGroup(null, stateType, null);//es el unico que falta por bajar
    }

    Reflect.defineProperty(constructor.prototype, 'resolve', {
        configurable: true,
        enumerable: true,
        value: (route: ActivatedRouteSnapshot, snapshoot: RouterStateSnapshot) => {

            //TODO demomento para los dinamicos, pero para unificar metodos de inicio que todos pasen la entidad
            let entity: Type<any>;
            let dynamic: DynamicEntityTypes;

            const entityName = StringUtils.kebabCaseToPascalCase(route.params.param);
            if (entityName) {
                dynamic = _MInjector.get<DynamicEntityService>(DynamicEntityService).getEntity(entityName);
                if (dynamic) {
                    entity = dynamic.entity;
                }
            }

            if (stateType !== 'list') {
                const _id = _MInjector.setNucleoService(nucleoService, keyStore);
                let dAR = false;
                if (stateType === 'detail' || stateType === 'newdetail') {
                    if (dynamic?.extras?.detail?.form?.disableAllRquired) {
                        dAR = dynamic?.extras?.detail?.form?.disableAllRquired;
                    }
                }
                if (stateType === 'dynamic-list') {
                    if (dynamic?.extras?.list?.params?.disableAllRquired) {
                        dAR = dynamic?.extras?.list?.params?.disableAllRquired;
                    }
                }

                nucleoService.createBuildFormGroup(dynamic?.entity || null, stateType, initialSate, dAR);
            }

            const store = _MInjector.get<Store>(Store);

            let urlWithId = snapshoot.url.split('?')[0];
            if (route.params && route.params.id != null) {
                urlWithId = urlWithId.replace(`/${route.params.id}`, '');
            }
            if (urlWithId.endsWith('/nuevo')) {
                urlWithId = urlWithId.replace(`/nuevo`, '');
            }

            const service: MEntityServiceDefault = _MInjector.get<any>(nucleoService.service);

            let dinamicParam;
            if (stateType === 'dynamic-list') {
                dinamicParam = route.params.param;
            }
            if (stateType === 'dynamic-detail') {
                dinamicParam = route.params.param;
            }
            if (stateType === 'detail' && route.params.param != null) {
                dinamicParam = route.params.param;
            }

            store.dispatch(new MostrarProcesandoGlobalAccion());

            return service.buscar({}, dinamicParam ? { behind: `${dinamicParam}/permisos` } : { behind: 'permisos' })
                .pipe(
                    finalize(() => store.dispatch(new OcultarProcesandoGlobalAccion())),
                    map(res => res.respuesta as any),
                    map(res => res?.permisos),
                    switchMap(permissions => {

                        nucleoService.permission = permissions ? `${permissions}` : '0000';

                        switch (stateType) {
                            case 'detail':
                            case 'newdetail':
                            case 'empty':
                        }

                        switch (stateType) {
                            case 'detail':
                            case 'newdetail':
                            case 'empty':
                            case 'list':
                            case 'listdetail':
                            case 'dynamic-list':
                                return store.dispatch(mActionFactory(keyStore)
                                    .iniciarAccion({
                                        urlParams: route.params,
                                        urlQueryParams: route.queryParams,
                                        url: snapshoot.url,
                                        // demomento para los dinamicos, pero para unificar metodos de inicio que todos pasen la entidad
                                        entity,
                                    }))
                                    .pipe(
                                        finalize(() => store.dispatch(new OcultarProcesandoGlobalAccion())),
                                        // TODO: mirar de quitar y probar que no afecte, pero no debe ir aqui
                                        map(res => res[keyStore]),
                                    );
                        }

                    }));
        }
    });

    return constructor;
}
