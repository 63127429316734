import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationError, AuthenticationRoutes, AUTH_RUTAS } from '@fwk/authentication';
import {
    BackErrorResponse, DefaultErrorClienteHandler, DefaultErrorServidorHandler,
    ErrorResponseHandler, MensajeOpc, MErrorResponse, MultipleBackErrorResponse
} from '@fwk/common';
import { MostrarNotificacionAccion } from '@fwk/components/notification';
import { Paginas, URL_PAGINAS } from '@fwk/components/static-page';
import { NavigateAction } from '@fwk/navigation';
import { Store } from '@ngxs/store';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AbrirDialogoInformacionAccion } from '../subestados/dialogo.state';


@Injectable({
    providedIn: 'root'
})
export class ErrorHttpInterceptor implements HttpInterceptor {

    constructor(
        private store: Store,
        private errorResponseHandler: ErrorResponseHandler,
        @Inject(URL_PAGINAS) private url: Paginas,
        @Inject(AUTH_RUTAS) private authRutas: AuthenticationRoutes,
        private errorClienteHandler: DefaultErrorClienteHandler,
        private errorServerHandler: DefaultErrorServidorHandler) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any | never> {
        return next.handle(req).pipe(
            switchMap(res => {
                if (res instanceof HttpResponse) {
                    if (res.status === 207) {
                        const multi = res.body;
                        const errores = [];
                        for (const m of multi) {
                            if (m.status > 400) {
                                errores.push(m)
                            }
                        }
                        if (errores.length) {
                            return throwError(errores.map(e =>
                                new MultipleBackErrorResponse(e.status, e.error.mensaje, e.error, e.orden)));
                        }
                    }
                }
                return of(res);
            }),
            catchError(err => {

                if (err instanceof AuthenticationError) {
                    this.store.dispatch(new NavigateAction({ route: [this.url.sesionExpirada.url] }));
                    return EMPTY;
                }

                // cambiamos el orden en los interceptores
                // if (+err.status == 401) {
                // return throwError(err);
                // }

                err = this.errorResponseHandler.errorResponse(err);

                if (err instanceof MErrorResponse) {
                    this.produceError(err);

                } else if (err instanceof HttpErrorResponse) {

                    // excluimos la comprobacion de la version de las excepciones de dialogos
                    // el error sale en la consola
                    if (err.url.endsWith(this.authRutas.appVersion)) {
                        return EMPTY;
                    }

                    const e = this.httpResponseHandler(+err.status, err.message,
                        new BackErrorResponse(
                            err.error?.codigo || err.statusText,
                            err.error?.causa || err.statusText,
                            err.error?.mensaje || err.statusText)
                    );

                    this.produceError(e);

                    return throwError(e);

                } else if (err instanceof Array) {

                    const error: any[] = [];
                    const notifications: { order: number, message: string }[] = [];
                    const dialogs: any[] = [];

                    for (const e of err) {
                        if (e instanceof MultipleBackErrorResponse) {
                            const handRes = this.httpResponseHandler(+e.status, e.message,
                                new BackErrorResponse(e.error.codigo, e.error.causa, e.error.mensaje), e.orden);
                            switch (handRes.type) {
                                case 'dialog':
                                    dialogs.push({ order: handRes.order, message: handRes.message });
                                    error.push(handRes);
                                    break;
                                case 'notification':
                                    notifications.push({ order: handRes.order, message: handRes.message });
                                    error.push(handRes);
                                    break;
                                default:
                                    error.push(handRes);
                            }
                        }
                    }

                    const messageNotifactions = this.getMultipleNotificationMessages(notifications);
                    if (messageNotifactions) {
                        this.store.dispatch(new MostrarNotificacionAccion({
                            titulo: 'errorMultiStatus',
                            mensaje: messageNotifactions.length === 1 ? messageNotifactions[0] : messageNotifactions,
                            severidad: 'error'
                        }));
                    }

                    return throwError(error);
                }

                return EMPTY;
            })
        );
    }

    private getMultipleNotificationMessages(notifications: { order: number, message: string }[]): MensajeOpc[] | null {
        const notificationsMessages = Object.entries(notifications
            .reduce<{ [key: string]: number[] }>((acc, curr) => acc[curr.message]
                ? { [curr.message]: acc[curr.message].concat([curr.order]) }
                : { [curr.message]: [curr.order] }, {}))
            .map(([key, value]) => value.length > 1
                ? [{ text: 'errorEnLasFilas', params: [`${value.join(',')}`] }, { text: key, traducirConValue: true }]
                : [{ text: key, traducirConValue: key.includes('{0}') }])[0];

        return notificationsMessages.length > 0 ? notificationsMessages : null;
    }

    private httpResponseHandler(status: number, message: string, error: BackErrorResponse, orden?: number) {
        let errorResponse: MErrorResponse;

        switch (true) {

            case status >= 400 && status < 500:
                errorResponse = this.errorClienteHandler.error({ status, message, error, orden });
                break;

            case status >= 500:
                errorResponse = this.errorServerHandler.error({ status, message, error, orden });
                break;
        }

        return errorResponse;
    }

    private produceError(e: MErrorResponse) {
        let action: any;

        if (!e) {
            return;
        }

        switch (e.type) {

            // case 'dialog':
            //     action = new AbrirDialogoInformacionAccion({
            //         id: e.name,
            //         mensaje: e.error.mensaje ? { text: e.error.mensaje } : e.message,
            //         severidad: 'error'
            //     });
            //     break;

            case 'notification':
                action = new MostrarNotificacionAccion({
                    titulo: e.name,
                    mensaje: e.error.mensaje
                        ? { text: e.error.mensaje, traducirConValue: !!e.error.mensaje.match(/{\d+}/) }
                        : e.message,
                    severidad: 'error',

                });
                break;

            case 'dialog':
            default:
                action = new AbrirDialogoInformacionAccion({
                    id: e.name,
                    mensaje: e.error.mensaje ? { text: e.error.mensaje } : e.message,
                    severidad: 'error'
                });
        }

        this.store.dispatch(action);
    }
}
