import { InjectionToken } from '@angular/core';

export const ARCHIVO_CONFIG_URL = new InjectionToken<string>('archivo_config_url');

export const ARCHIVO_PACKAGE_JSON = new InjectionToken<string>('archivo_package_json');

export interface AppConfigInit {
    nombreApp: string;
    menuUrl: string;
}

export class IniciarAplicacionAccion {
    static readonly type = `[APLICACION] Iniciar aplicacion`;
    constructor(public payload: { appConfigInit: AppConfigInit, entorno: string }) { }
}

export interface AplicacionStateMo {
    entorno: string;
    nombre: string;
    version: string;
}


export interface AplicacionConfiguracionMo {
    formatoFecha: string;
    numeroFilasTabla: number;
    volverAlGuardar: boolean;
    iconoFormModificado: string;
    iconoRegistroEnUso: string;
    layout: {
        tema: {
            config: {
                logo: {
                    topbar: {
                        imgId: string,
                        src: {
                            light: string,
                            dark: string
                        }
                    },
                    menu: {
                        imgId: string,
                        src: {
                            light: string,
                            dark: string
                        }
                    }
                },
                css: {
                    layout: {
                        linkId: string
                    },
                    tema: {
                        linkId: string
                    }
                }
            },
            defecto: {
                nombre: string,
                modo: string
            }
        }
    };
}
