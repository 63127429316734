import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@fwk/translate';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { AplicacionStateMo, IniciarAplicacionAccion } from './api';
import { AplicacionService } from './aplicacion.service';


const inicialAplicacionState: AplicacionStateMo = {
    entorno: '',
    nombre: '',
    version: ''
};

@State<AplicacionStateMo>({
    name: 'aplicacion',
    defaults: inicialAplicacionState,
})
@Injectable()
export class AplicacionState {

    constructor(
        private aplicacionService: AplicacionService,
        private multilenguajeService: TranslateService,
        private ngZone: NgZone
    ) { }

    @Selector()
    static selectState(store: AplicacionStateMo) {
        return store;
    }

    @Selector()
    static selectEntorno(store: AplicacionStateMo) {
        return store.entorno;
    }

    @Selector()
    static selectNombre(store: AplicacionStateMo) {
        return store.nombre;
    }

    @Selector()
    static selectVersion(state: AplicacionStateMo) {
        return state.version;
    }


    @Action(IniciarAplicacionAccion)
    iniciarAplicacion(context: StateContext<AplicacionStateMo>, { payload }: IniciarAplicacionAccion) {
        return this.aplicacionService.cargarConfig()
            .pipe(
                tap(_config => {
                    this.multilenguajeService.get(payload.appConfigInit.nombreApp)
                        .pipe(take(1))
                        .subscribe(nombreAppTraducido => {
                            this.ngZone.runOutsideAngular(() => {
                                const tituloIndexIf = document.getElementById('f-app-titulo-pestanya');
                                if (tituloIndexIf) {
                                    tituloIndexIf.textContent = nombreAppTraducido;
                                }
                            });
                        });

                    const appVersion = this.aplicacionService.version;
                    const libraries = this.aplicacionService.fwkLibraries;
                    const fwkLibraries = Object.entries(libraries).filter(([k, _v]) => k.startsWith('@fwk/'))
                        .map(([k, v]) => ({ lib: k.replace('@fwk/', ''), version: v }))

                    if (payload.entorno !== 'prod') {
                        console.log(`[${payload.entorno}] ${payload.appConfigInit.nombreApp} ${appVersion}`);
                        fwkLibraries.forEach(fLib => {
                            console.log(`[${payload.entorno}] ${fLib.lib} ${fLib.version}`);
                            // TODO: Faltaria comprobar la version del package con la version del node_modules
                            // if (!this.aplicacionService.checkDependencies(fLib.lib, fLib.version)) {
                            // console.warn(`la instalacion de la dependecia ${fLib.lib} no coincide con la version esperada ${fLib.version}`);
                            // }
                        })
                    }

                    context.patchState({
                        nombre: payload.appConfigInit.nombreApp,
                        version: appVersion,
                        entorno: payload.entorno
                    });

                }), catchError(err => {

                    return throwError(err);
                }));
    }
}
