import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AplicacionService, ProcesandoGlobalState } from '@fwk/aplicacion';
import { AppInitService, AuthenticationService, LogoutAction } from '@fwk/authentication';
import { VaciarDatosAccion } from '@fwk/data';
import { LoadTranslateModule } from '@fwk/translate';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofAction, Select } from '@ngxs/store';
import { MantenimientoDinamicoService } from 'core/servicios/mantenimiento-dinamico.service';
import { environment } from 'environment';
import { Idioma } from 'mantenimiento-dinamico/idioma';
import { Observable, Subject } from 'rxjs';
import { delay, map, takeUntil, tap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class VersionService {

    checkVersion: any;

    topbarMessage: string;

    constructor(
        private authService: AuthenticationService,
        private http: HttpClient,
        private aplicacionService: AplicacionService,
    ) { }

    check(onVersion: (error: boolean) => void) {
        this.startCheck(onVersion);
        this.checkVersion = setInterval(() => {
            this.startCheck(onVersion);
        }, environment.servidor.checkVersionTime);
    }

    private startCheck(onVersion: (error: boolean) => void) {
        this.http.get(this.authService.rutas.appVersion, { responseType: 'text' }).subscribe(appVersion => {
            let appVersionM: string;
            let indexM: string = this.aplicacionService.version;
            const matchVersion = appVersion.match(/<meta versionapp=(.*)>/);
            if (matchVersion?.length > 0) {
                appVersionM = matchVersion[1].replace(/"/g, '');
            }
            onVersion(appVersionM != indexM)
        });
    }
}

@Component({
    selector: 'app-root',
    template: `
        <span fInactivity (onExpiredSession)="expiredSession($event)">
            <f-procesando-global [show]="showProcensadoGlobal$ | async"></f-procesando-global>
            <f-notificacion  claveNotificacion="rootApp"></f-notificacion>
            <router-outlet></router-outlet>
        </span>
    `
})
export class AppComponent implements OnInit, OnDestroy {

    @Select(ProcesandoGlobalState.selectShow)
    showProcensadoGlobal$!: Observable<boolean>;

    destroy = new Subject();

    @Dispatch()
    cargarIdiomas = (idiomasDisponibles: string[]) => new LoadTranslateModule({ languages: idiomasDisponibles })

    @Dispatch()
    borrarDatos = () => new VaciarDatosAccion({ keyStore: '' });

    constructor(
        private actions$: Actions,
        private versionService: VersionService,
        private appInitService: AppInitService,
        private ml: MantenimientoDinamicoService) { }

    ngOnInit() {

        this.appInitService.init();

        this.ml.llenarDatos({ extras: { endpoint: 'idioma', ruta: environment.multilenguaje.url } })
            .pipe(map((idiomas: Idioma[]) => idiomas.map(i => i.codigo)))
            .subscribe((idiomas: string[]) => {
                this.cargarIdiomas(idiomas);
            });
        this.actions$
            .pipe(
                takeUntil(this.destroy),
                ofAction(LogoutAction),
                delay(100),
                tap(() => {
                    location.reload();
                    this.borrarDatos();
                }))
            .subscribe();

        this.versionService.check(err => {
            if (err) {
                this.versionService.topbarMessage = 'cambioVersionFrontendApp';
            } else {
                this.versionService.topbarMessage = undefined;
            }
        });
    }


    expiredSession(time: number) {
        // sesion expirada solo tiene sentido si no estamos loginados
    }

    ngOnDestroy() {
        clearInterval(this.versionService.checkVersion);
        this.destroy.next();
        this.destroy.complete();
    }
}
