// tslint:disable: no-string-literal
import { Type } from '@angular/core';
import { MAcciones } from '@fwk/aplicacion';
import { MEntityService, MEntityServiceDefault, QP } from '@fwk/common';
import { LlenarDatosAccion } from '@fwk/data';
import { NavigateBackAction } from '@fwk/navigation';
import { TextM } from '@fwk/translate';
import { UpdateFormDirty } from '@ngxs/form-plugin';
import { StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { getDynamicParams } from '../common/common';
import { eliminarFilaListaFn, nuevaFilaListaFn } from '../common/list';
import { MostrarProcesandoGlobalAccion, OcultarProcesandoGlobalAccion } from '../subestados/procesando-global.state';
import { inicialFormState, MantenimientoFichaStoreModel } from './api';
import { MState } from './m-state';
import { AuthenticationService } from '@fwk/authentication';
import { _MInjector } from './_m-injector';

export class MFichaState<T extends MantenimientoFichaStoreModel> extends MState {

    /**
     * Iniciar ficha
     */
    iniciar(context: StateContext<T>, { payload }: MAcciones.iniciar.Action) {
        this.resetBuilder();
        this.initState(payload);
        this.nS.urlParams = payload.urlParams;

        const token = payload.urlParams.token;
        let encuesta = null;
        if (token) {
            const decode = _MInjector.get<AuthenticationService>(AuthenticationService).getDecode(token);
            encuesta = (decode as any).encuesta;
        }

        let state = context.getState();

        const paramsTokenToId = encuesta != null ? { id: encuesta } : payload.urlParams;


        let task$LlenarDatos: Observable<any> =
            context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: payload.urlParams }));

        const id = encuesta != null ? encuesta : (payload && payload.urlParams.id !== null ? payload.urlParams.id : null);
        // const id = payload && payload.urlParams.id !== null ? payload.urlParams.id : null;

        let titulo: TextM;
        if (this.estadoInicial.titulo.text && payload.urlParams) {
            titulo = {
                ...this.estadoInicial.titulo,
                text: getDynamicParams(this.estadoInicial.titulo.text, payload.urlParams)
            }
        }

        let modelFromQueryParams = null;
        if (encuesta == null) {
            if (payload.urlQueryParams && payload.urlQueryParams.q) {
                modelFromQueryParams = QP.fromQueryParamsUrlB64(payload.urlQueryParams.q);
            }
        }

        if (id == null) { // nuevo
            // TODO: unificar con new-detail-state
            task$LlenarDatos = task$LlenarDatos
                .pipe(
                    map(() => {

                        const model = (this.fepS.backToFrontEntity(this.entityParse, {
                            ...this.nS.estadoInicial.forms['form'].model
                        }, []))

                        if (modelFromQueryParams) {
                            return {
                                ...model,
                                ...modelFromQueryParams
                            }
                        }

                        return model;
                    }),

                    mergeMap(res => this.dps.mapDatosEnEntidad(res)),
                    tap(model => {
                        context.patchState({
                            ...this.estadoInicial,
                            titulo,
                            permisos: this.nS.permission,
                            forms: {
                                ...this.estadoInicial.forms,
                                ['form']: {
                                    ...inicialFormState(),
                                    model
                                }
                            },
                        });
                    }));

        } else { // editar
            task$LlenarDatos = task$LlenarDatos
                .pipe(
                    mergeMap(() => this.nS.getServicio<MEntityService>()
                        .buscarPorId(id, payload.urlParams && payload.urlParams.param ? { before: payload.urlParams.param } : null)
                        .pipe(
                            map((res: any) => ({
                                entidadEnUsoEnBackend: res.registroEnUso,
                                model: this.fepS.backToFrontEntity(this.entityParse, res.respuesta, [])
                            })),
                            mergeMap(({ model, entidadEnUsoEnBackend }) => this.dps.mapDatosEnEntidad(model)
                                .pipe(
                                    map(mod => ({
                                        entidadEnUsoEnBackend,
                                        model: mod
                                    })))),
                            map(({ model, entidadEnUsoEnBackend }) => ({
                                entidadEnUsoEnBackend,
                                model: this.fepS.mapModelToOneEntityArrayEntity(this.nS.build, 'form', model, this.estadoInicial.forms['form'].model)
                            })),
                            tap(({ model, entidadEnUsoEnBackend }) => {
                                state = context.getState();
                                context.patchState({
                                    ...this.estadoInicial,
                                    modo: 'editar',
                                    entidadEnUsoEnBackend,
                                    titulo,
                                    permisos: this.nS.permission,
                                    id,
                                    forms: {
                                        ...state.forms,
                                        ['form']: {
                                            ...inicialFormState(),
                                            model
                                        }
                                    }
                                });
                            }),

                            // TODO:
                            map(({ model }) => model)
                        )));
        }

        return task$LlenarDatos;
    }

    /**
     * Guardar
     */
    guardar(context: StateContext<T>, accion: MAcciones.guardar.Action) {
        const state = context.getState();

        const entidad = accion.payload && accion.payload.form
            ? accion.payload.form
            : state.forms.form.model;

        const volverAlGuardar = accion.payload && accion.payload.volverAlGuardar != null
            ? accion.payload.volverAlGuardar
            : this.nS.volverAlGuardar;

        const isValid = state.forms.form.status === 'VALID';

        if (isValid) {
            switch (state.modo) {

                case 'nuevo':
                    context.dispatch(new MostrarProcesandoGlobalAccion());
                    return this.insertar(context, {
                        entidad, volverAlGuardar,
                        tipoEntidad: accion?.payload?.tipoEntitdad || this.entityParse,
                        noNavegarNuevaId: accion.payload?.noNavegarNuevaId,
                    });

                case 'editar':
                    if (state.entidadEnUsoEnBackend) {
                        return this.noS.confirmacionAlGuardarRegistroEnUso()
                            .pipe(
                                filter((res: any) => res.action),
                                tap(() => context.dispatch(new MostrarProcesandoGlobalAccion())),
                                switchMap(() => this.actualizar(context, {
                                    entidad, volverAlGuardar,
                                    servicio: accion?.payload?.servicio,
                                    mapForm: accion?.payload?.mapForm,
                                    tipoEntidad: accion?.payload?.tipoEntitdad || this.entityParse, noNavegarNuevaId: accion.payload?.noNavegarNuevaId
                                })))
                    } else {
                        context.dispatch(new MostrarProcesandoGlobalAccion());
                        return this.actualizar(context, {
                            entidad,
                            servicio: accion?.payload?.servicio,
                            mapForm: accion?.payload?.mapForm,
                            volverAlGuardar, tipoEntidad: accion?.payload?.tipoEntitdad || this.entityParse, noNavegarNuevaId: accion.payload?.noNavegarNuevaId
                        });
                    }
            }

        } else {
            context.dispatch(new OcultarProcesandoGlobalAccion());
            context.dispatch(this.noS.errorAlGuardar());
            return throwError(state.forms.form.errors);
        }
    }


    //  TODO: Poner en comun y parametrizar el editar y el nuevo

    private insertar(context: StateContext<T>, payload: { entidad: any, tipoEntidad: Type<any>, volverAlGuardar: boolean, noNavegarNuevaId: boolean }) {
        let state = context.getState();
        const formGen = this.nS.getBuildFormGroup();
        const entidadAGuardad = this.fepS.frontToBackEntity(payload.tipoEntidad, payload.entidad);

        // TODO: Solo cambia el metodo y que hay que cambiar el modo de nuevo a editar
        return this.nS.getServicio<MEntityService>().insertar(entidadAGuardad,
            this.urlParams?.param ? { before: this.urlParams.param } : null)
            .pipe(
                finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion())),
                catchError(err => {
                    let sms = err.error.mensaje as string;
                    formGen.cambiarNombrePropiedad.forEach(nm => sms = sms.replace(nm.campo as string, nm.nombre));
                    if (this.keyStore) {
                        context.dispatch(new UpdateFormDirty({ path: `${this.keyStore}.forms.form`, dirty: false }));
                    }
                    context.patchState({
                        ...state,
                        forms: {
                            ...state.forms,
                            form: {
                                ...state.forms.form,
                                dirty: false
                            }
                        }
                    });
                    return throwError({
                        ...err,
                        error: {
                            ...err.error,
                            mensaje: sms
                        }
                    });
                }),
                tap(() => context.dispatch(this.noS.guardadoCorrectamente())),
                filter(() => {
                    if (payload && payload.volverAlGuardar) {
                        context.dispatch([new NavigateBackAction()]);
                    }
                    return !payload || !payload.volverAlGuardar;
                }),
                tap(({ respuesta }) => {
                    const url = this.currentUrl.substring(0, this.currentUrl.lastIndexOf('/'));
                    this.nS.navigationService.pasarDeNuevoAEditar(`${url}/${respuesta.id}`);
                }),
                switchMap(({ respuesta }) =>
                    context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: null }))
                        .pipe(
                            switchMap(() =>
                                this.nS.getServicio<MEntityService>().buscarPorId(respuesta.id || undefined,
                                    this.urlParams?.param ? { before: this.urlParams.param } : null)
                                    .pipe(
                                        // TODO: TEST DATOS OBSERVABLE
                                        mergeMap((res: any) => this.dps.mapDatosEnEntidad(res.respuesta)),
                                        map(model => this.fepS.backToFrontEntity(this.entityParse, model)),
                                        tap(model => {
                                            state = context.getState();
                                            context.patchState({
                                                ...state,
                                                modo: 'editar',
                                                id: respuesta.id,
                                                forms: {
                                                    ...state.forms,
                                                    form: {
                                                        ...state.forms.form,
                                                        model,
                                                        dirty: false
                                                    }
                                                }
                                            });
                                        }),
                                        tap(() => {
                                            context.dispatch(new OcultarProcesandoGlobalAccion());
                                        }))
                            ))
                ))
    }


    private actualizar(context: StateContext<T>, payload: { servicio: any, entidad: any, tipoEntidad: Type<any>, mapForm?: any, volverAlGuardar: boolean, noNavegarNuevaId: boolean }) {
        let state = context.getState();
        const entidadAGuardad = this.fepS.frontToBackEntity(payload.tipoEntidad, payload.entidad);
        return (payload?.servicio || this.nS.getServicio<MEntityServiceDefault>()).actualizar({ entidad: entidadAGuardad, id: (payload?.entidad?.id || state.id) },
            this.urlParams?.param ? { before: this.urlParams.param } : null)
            .pipe(
                finalize(() => context.dispatch(new OcultarProcesandoGlobalAccion())),
                catchError(err => {
                    let sms = err.error.mensaje as string;
                    if (this.keyStore) {
                        context.dispatch(new UpdateFormDirty({ path: `${this.keyStore}.forms.form`, dirty: false }));
                    }
                    context.patchState({ ...state, forms: { ...state.forms, form: { ...state.forms.form, dirty: false } } });
                    return throwError({ ...err, error: { ...err.error, mensaje: sms } });
                }),
                tap(() => context.dispatch([this.noS.guardadoCorrectamente()])),
                filter(() => {
                    if (payload && payload.volverAlGuardar) {
                        context.dispatch([new NavigateBackAction()]);
                    }
                    return !payload || !payload.volverAlGuardar;
                }),
                switchMap(() => {
                    return (this.keyStore ? context.dispatch(new LlenarDatosAccion({ keyStore: this.keyStore, routeParams: null })) : of())
                        .pipe(
                            switchMap(() => {
                                return (payload?.servicio || this.nS.getServicio<MEntityService>()).buscarPorId(payload?.entidad?.id || state.id,
                                    this.urlParams?.param ? { before: this.urlParams.param } : null)
                                    .pipe(
                                        map((res: any) => ({
                                            entidadEnUsoEnBackend: res.regsitroEnUso,
                                            model: this.fepS.backToFrontEntity((payload?.tipoEntidad || this.entityParse), res.respuesta, [])
                                        })),
                                        mergeMap(({ model, entidadEnUsoEnBackend }) => this.dps.mapDatosEnEntidad(model)
                                            .pipe(
                                                map(mod => ({ entidadEnUsoEnBackend, model: mod })))),
                                        // mergeMap(({ model, entidadEnUsoEnBackend }) => (!!payload.mapForm ? payload.mapForm(model) : of(model))
                                        //     .pipe(
                                        //         map(mod => ({ entidadEnUsoEnBackend, model: mod })))),

                                        tap(({ model, entidadEnUsoEnBackend }) => {
                                            console.debug(model);
                                            state = context.getState();
                                            context.patchState({
                                                ...state,
                                                entidadEnUsoEnBackend,
                                                forms: {
                                                    ...state.forms,
                                                    form: {
                                                        ...state.forms.form,
                                                        model,
                                                        dirty: false
                                                    }
                                                }
                                            });
                                        }),
                                        map(({ model }) => model),
                                        tap(() => {
                                            context.dispatch(new OcultarProcesandoGlobalAccion());
                                        }))
                            }))
                }));
    }

    eliminar(context: StateContext<T>, _accion: MAcciones.eliminar.Action) {
        return this.noS.confirmacionEliminar()
            .pipe(
                filter((resd: { id: string, action: boolean }) => resd && resd.action === true),
                switchMap(() => (_accion?.payload?.servicio ? _accion.payload.servicio : this.nS.getServicio<MEntityService>())
                    .eliminar(_accion?.payload?.id != null ? _accion.payload.id : context.getState().id,
                        this.urlParams?.param ? { before: this.urlParams.param } : null)
                    .pipe(
                        map((res: any) => res && res.respuesta && res.respuesta.err ? res.respuesta.err[0] : null),
                        tap(errores => {
                            context.dispatch(errores
                                ? [this.noS.errorAlEliminar(errores)]
                                : [this.noS.eliminarRegistrosCorrectamente(), new NavigateBackAction()]);
                        }))));
    }


    // TODO: poner en comun con las funcionalidades de tabla
    // TODO: poner en comun con las funcionalidades de tabla
    // TODO: poner en comun con las funcionalidades de tabla

    /**
     * Nueva fila tabla editable
     */
    nuevaFilaLista(context: StateContext<T>, action: MAcciones.nuevaFilaLista.Action) {
        return nuevaFilaListaFn(context, action,
            action.payload && action.payload.buildFormEntity
                ? action.payload.buildFormEntity
                : this.nS.getBuildFormGroup().build);
    }

    /**
     * Eliminar fila tabla editable
     */
    eliminarFilaLista(context: StateContext<T>, action: MAcciones.eliminarFilaLista.Action) {
        return eliminarFilaListaFn<T>(context, action,
            this.nS.getBuildFormGroup());
    }

}
