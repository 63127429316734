import { AfterContentInit, AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ButtonDirective } from '@fwk/components/button';
import { TranslateService } from '@fwk/translate';
import { Store } from '@ngxs/store';
import { mActionFactory } from '../../actions/action-factory-builder';
import { MFichaHtmlComponent } from '../ficha-html.component';

@Directive({
    selector: '[fButtonGuardar]'
})
export class ButtonGuardarDirective extends ButtonDirective implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

    @Input('fButtonGuardar') formGroup?: FormGroup;

    @Input() onlyDirty?: boolean;

    @Input() formName = 'form';

    @Input() isWritePermissions?: boolean = true;

    constructor(
        @Optional() private mHtmlComponent: MFichaHtmlComponent,
        private store: Store,
        public elementRef: ElementRef<any>,
        public renderer: Renderer2,
        public multilenguajeService: TranslateService) {
        super(elementRef, renderer, multilenguajeService);
        this.label = 'guardar';
        this.icon = 'pi pi-save';
        this.traducirLabel = true;
    }

    ngOnInit(): void {
        if (!this.isWritePermissions || (this.mHtmlComponent && !this.mHtmlComponent?.mNucleoService.isWritePermissions())) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        }
    }

    fwkClick() {
        if (!this.personalizar && this.mHtmlComponent) {
            this.store.dispatch(mActionFactory(this.mHtmlComponent.mNucleoService.keyStore).guardarAccion());
        }
    }
}
