import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { ControlContainer, ControlValueAccessor } from '@angular/forms';
import { ObjectUtils } from '@fwk/common';
import { ActivoInactivo, DisableControlService, valueProvider } from '@fwk/forms';
import { Actions, ActionType, ofActionErrored, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { mActionFactory } from '../../actions/action-factory-builder';
import { MFichaHtmlComponent } from '../ficha-html.component';
import { ERROR_ACTIONS_EVENTS_FEATURE, SUCCESS_ACTIONS_EVENTS_FEATURE } from './api';

const FORM_CTRL_NAME = 'formcontrolname';
const FORM_CTRL_ID = 'formcontrolid';
const FORM_CTRL_EXCEPTION = 'formcontrolexception';

@Component({
    selector: 'f-activo-inactivo-ficha',
    template: `
        <f-radioButton [(ngModel)]="value" (onSelect)="change($event)" [label]="''" [nolabel]="nolabel" [disabled]="disableModel"
            [textoTrue]="'activo'" [textoFalse]="'inactivo'"
            [orientacion]="'horizontal'">
        </f-radioButton>
    `,
    styles: [`
        :host() {
            padding-left: 0.5em;
        }
    `],
    providers: [
        valueProvider(ActivoInactivoComponent)
    ]
})
export class ActivoInactivoComponent implements ControlValueAccessor, OnDestroy, OnInit, AfterViewInit {

    @Input() nolabel?: boolean;

    @Input() onErrorRollback = true;

    @Input() ocultarEliminarButton = true;

    @Input() disableModel = false;

    value?: any;

    isDisabled?: boolean;

    onChanged: (value: any) => {};

    onTouched: () => {};

    onDestroy = new Subject();

    iChange = false;

    private id: string;

    private initCtrl = false;

    private init = false;

    constructor(
        @Optional() @Inject(SUCCESS_ACTIONS_EVENTS_FEATURE) private successActionsFeature: ActionType[],
        @Optional() @Inject(ERROR_ACTIONS_EVENTS_FEATURE) private errorActionsFeature: ActionType[],
        private controlContainer: ControlContainer,
        private cd: ChangeDetectorRef,
        private actions$: Actions,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private disableControlService: DisableControlService,
        private mHtmlComponent: MFichaHtmlComponent
    ) {
        this.id = this.elementRef.nativeElement.getAttribute(FORM_CTRL_ID) || ObjectUtils.fwk_generarObjectId();
    }

    ngOnInit() {
        this.initCtrl = this.controlContainer.control != null;

        if (this.initCtrl) {
            let cn = this.elementRef.nativeElement.getAttribute(FORM_CTRL_NAME);
            // si es null miramos que no este por reflect
            if (!cn) {
                cn = this.elementRef.nativeElement.getAttribute('ng-reflect-name');
            }
            const ctrl = this.controlContainer.control.get(cn);
            this.disableControlService.setPropertyDisableControl(new ActivoInactivo(this.id, ctrl));
            this.registerActionsEvents();

            if (this.ocultarEliminarButton && this.mHtmlComponent.eliminarButton) {
                this.renderer.setStyle(this.mHtmlComponent.eliminarButton, 'display', this.value ? 'inline' : 'none');
            }
        }
    }

    ngAfterViewInit() {
        if (!this.init) {
            this.mHtmlComponent.hideEliminarButton = this.ocultarEliminarButton && !this.value;
            this.init = true;
            this.checkNativeAttribute();
            if (!this.mHtmlComponent.mNucleoService.isWritePermissions()) {
                this.disableModel = true;
                this.cd.detectChanges();
            }
        }
    }


    // hay que pasar este componte al modulo de componentes y pasar las acciones de los evento por un modulo de configuracion
    private registerActionsEvents() {

        const actions = this.successActionsFeature || [
            mActionFactory(this.mHtmlComponent.mNucleoService.keyStore).guardarAccion() as ActionType
        ];

        // error
        this.actions$
            .pipe(
                takeUntil(this.onDestroy),
                ofActionErrored(...actions)
            )
            .subscribe(() => {
                if (this.iChange && this.onErrorRollback) {
                    this.change(!this.value);
                    this.cd.markForCheck();
                }
            });

        // success
        this.actions$
            .pipe(
                takeUntil(this.onDestroy),
                ofActionSuccessful(...actions)
            )
            .subscribe(() => {
                this.checkNativeAttribute();
                this.disableControlService.checkIsDisabledOnSave();
                this.checkEliminarButton();
                this.cd.markForCheck();
            });
    }

    private checkEliminarButton() {
        if (this.ocultarEliminarButton && this.mHtmlComponent.eliminarButton) {
            this.renderer.setStyle(this.mHtmlComponent.eliminarButton, 'display', this.value ? 'inline' : 'none');
        }
    }

    private checkNativeAttribute() {
        if (this.value === false) {
            this.elementRef.nativeElement.setAttribute('disableFormFicha', '');
        } else {
            this.elementRef.nativeElement.removeAttribute('disableFormFicha');
        }
    }


    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    change(event: boolean): void {
        this.iChange = true;
        this.writeValue(event);
        this.onChanged(event);
        this.onTouched();
    }
}
