import { Directive, ElementRef, Inject, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { HTTP_URL, MEntityServiceDefault, QP } from '@fwk/common';
import { ButtonDirective } from '@fwk/components/button';
// import { ReportsService, ReportType } from '@fwk/reports';
import { TranslateService } from '@fwk/translate';
import { MNucleoService } from '../../states/m-nucleo.service';
import { MFichaHtmlComponent } from '../ficha-html.component';
import { MListaHtmlComponent } from '../lista-html.component';


@Directive({
    selector: '[fButtonImprimir]'
})
export class ButtonImprimirDirective extends ButtonDirective implements OnInit {

    @Input() url?: string;

    @Input() queryParams: string | { [key: string]: any };

    @Input() queryParamsFromUrl?: boolean;

    @Input() queryParamsEncode: boolean = true;

    // @Input() type: ReportType = 'XLS';

    private get html() {
        return this.htmlLista || this.htmlFicha;
    }

    private get serviceUrl() {
        return (this.html.mNucleoService.getServicio<any>()?.sv as MEntityServiceDefault)?.url;
    }

    private get urlIdParam() {
        return (this.html.mNucleoService).urlParams?.id;
    }

    constructor(
        @Optional() private htmlLista: MListaHtmlComponent,
        @Optional() private htmlFicha: MFichaHtmlComponent,
        private ns: MNucleoService,
        @Inject(HTTP_URL) private urlServer: string,
        public elementRef: ElementRef<any>,
        // private reportsService: ReportsService,
        public renderer: Renderer2,
        public multilenguajeService: TranslateService) {
        super(elementRef, renderer, multilenguajeService);
        this.label = 'imprimir';
        this.icon = 'pi pi-print';
        this.traducirLabel = true;
    }

    ngOnInit(): void {
        this.disable(!this.ns.isGetPermissions());
    }

    fwkClick() {
        if (!this.personalizar) {
            let qp = {};

            if (this.queryParamsFromUrl) {
                qp = window.location.search.split('?')[1]?.split('&').filter(s => !s.startsWith('v')).join('&');
            }
            else if (this.queryParams != null) {

                if (typeof this.queryParams === 'string') {
                    qp = this.queryParams;
                    if (this.queryParamsEncode) {
                        console.warn('QueryParamsEncode only works with queryparams type object');
                    }
                }

                else if (typeof this.queryParams === 'object') {
                    if (this.queryParamsEncode) {

                        const qpRemoved = this.removeNullQp(this.queryParams);
                        if (qpRemoved) {
                            qp = QP.toQueryParamParUrl2({ q: qpRemoved });
                        }
                    }
                }

                else {
                    console.warn('QueryParams are invalid');
                }
            }

            let url: string;

            if (this.url) {
                url = this.url;
            }
            else if (this.serviceUrl) {
                url = this.serviceUrl + '/' + this.urlIdParam;
            }
            else {
                throw new Error('Service not found');
            }

            // this.reportsService.printReport(QP.removeLastSlash(this.urlServer) + '/' + QP.removeFistSlash(url), this.type, qp ? { queryParams: qp } : null);
        }
    }

    private removeNullQp(queryParams: { [key: string]: any }) {
        if (queryParams != null) {
            const res = Object.entries(queryParams).reduce((a, c) => {
                if (c[1] == null) {
                    return a;
                }
                if (typeof c[1] === 'object' && Object.keys(c[1]).length > 0) {
                    const rec = this.removeNullQp(c[1]);
                    if (rec == null) {
                        return a;
                    }
                    return { ...a, [c[0]]: rec };
                }
                return { ...a, [c[0]]: c[1] };
            }, {});

            if (Object.keys(res).length > 0) {
                return res;
            }
        }
        return null;
    }
}
